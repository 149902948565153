<script>
    import { P, Textarea } from "flowbite-svelte";
    import { onMount } from "svelte";
    import { getNestedDict } from "../../service/sse";
    import { global_state } from "../../service/store";
    import {
        review_advance,
        review_baseline,
        review_resolution,
        review_uncertainty,
    } from "../Review/store";
    import BottomToolbar from "../components/BottomToolbar.svelte";
    import CustomAccordion from "../components/CustomAccordion.svelte";
    import GenericAccordionItem from "../components/GenericAccordionItem.svelte";
    import {
        questions_core_activity,
        user_core_activity_instructions,
    } from "./store";
    import TopToolbar from "../components/TopToolbar.svelte";

    let component_address = ["draft_au"];
    let accordion_items_refs = {};
    let files_table_ref = null;

    onMount(async () => {});
</script>

<div class="w-full flex flex-grow flex-col justify-center mb-5">
    <div class="justify-center mt-5">
        <div class="mb-10">
            <P class="my-5 text-gray-700 dark:text-gray-300 font-semibold"
                >Which core activity would you like to write about?</P
            >
            <Textarea rows="5" bind:value={$user_core_activity_instructions}
            ></Textarea>
            <div class="mb-8 -mr-4">
                <TopToolbar
                    component_address={[...component_address, "toolbar"]}
                    {accordion_items_refs}
                    {files_table_ref}
                    hide_model_selector={false}
                ></TopToolbar>
            </div>
            <CustomAccordion>
                {#each Object.entries(questions_core_activity) as [question_key, question_val]}
                    <GenericAccordionItem
                        module_name="draft_au"
                        suppress_notification={true}
                        hide_model_selector={true}
                        override_selected_model={question_val.selected_model}
                        bind:this={accordion_items_refs[question_key]}
                        title={question_val.title}
                        extra_context={user_core_activity_instructions}
                        component_address={[
                            ...component_address,
                            "questions_core_activity",
                            question_key,
                        ]}
                        dependent_components={question_val.dependencies?.map(
                            (dep) => {
                                return {
                                    content_address: [
                                        ...component_address,
                                        "questions_core_activity",
                                        dep,
                                        "data",
                                        "content",
                                    ],
                                    completed_address: [
                                        ...component_address,
                                        "questions_core_activity",
                                        dep,
                                        "data",
                                        "completed",
                                    ],
                                };
                            },
                        )}
                    ></GenericAccordionItem>
                {/each}
            </CustomAccordion>
        </div>

        <BottomToolbar
            download_file_name="Technical Narrative"
            template_type="draft_au"
            {accordion_items_refs}
            {files_table_ref}
            use_files={true}
            component_address={[...component_address, "bottom_toolbar"]}
        ></BottomToolbar>
        <div class="flex justify-center my-10">
            <P size="xs" class="text-gray-800"
                >AI may make mistakes. Please review the output carefully.</P
            >
        </div>
    </div>
</div>
