<!-- src/routes/Pricing.svelte -->
<script>
  import { Spinner } from "flowbite-svelte";
  import { onMount } from "svelte";
  import { current_user } from "../../service/store";
  const BASE_URL = import.meta.env.VITE_BASE_URL;
  let loading = true;
  onMount(async () => {
    console.log("stripe customer is " + $current_user.stripe_customer);
    if ($current_user.stripe_customer != null) {
      console.log("There is a stripe customer", $current_user.stripe_customer);
      window.location.href = BASE_URL + "/payments/billing_portal";
    } else {
      console.log("There is no stripe customer");
      loading = false;
    }
  });
</script>

<svelte:head>
  <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
</svelte:head>



<div class="pricing-page">
  {#if loading}
    <Spinner></Spinner>
  {:else}
    <h1>Our Pricing</h1>
    <stripe-pricing-table pricing-table-id="prctbl_1QCfaGAnHQme9GY61y9EgGJP"
      publishable-key="pk_live_51PyTlIAnHQme9GY6dje7mRSTC1tXhUSjX8lfDBc3XpBQ26AKUw6lezjc4P7VOJJMkE0SP4wnRGSu08TEmycdPEms002yaz9jbz"
    >
    </stripe-pricing-table>
  {/if}
</div>

<style>
  .pricing-page {
    text-align: center;
    padding: 50px;
  }

  .pricing-page h1 {
    margin-bottom: 30px;
  }

  .pricing-page p {
    font-size: 1.2em;
    color: #666;
  }
</style>
