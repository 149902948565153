<script>
    import { Button, Select } from "flowbite-svelte";
    import { Spinner } from "flowbite-svelte";
    import GenericAddInstructionsModal from "./GenericAddInstructionsModal.svelte";
    import { writable } from "svelte/store";
    import { global_state } from "../../service/store";
    import { getNestedDict, updateNestedDict } from "../../service/sse";
    import {
        ChevronDoubleDownOutline,
        ChevronDoubleUpOutline,
        StopOutline,
        TrashBinOutline,
    } from "flowbite-svelte-icons";
    import GenericConfirmModal from "./GenericConfirmModal.svelte";
    import { review_advance, review_baseline, review_resolution, review_uncertainty } from "../Review/store";

    export let component_address;
    export let accordion_items_refs;
    export let files_table_ref;
    export let use_files = true;
    export let has_delete_narratives = false;
    export let override_submit_loading = null;
    export let override_submit_disabled = null;
    export let hide_model_selector = true;

    let models = [
        { name: "gpt-4o", value: "gpt-4o" },
        { name: "DeepSeek-R1", value: "deepseek-ai/DeepSeek-R1" },

        // { name: "gpt-4o-mini", value: "gpt-4o-mini" },
        // { name: "o1-mini", value: "o1-mini" },
        // { name: "DeepSeek-V3", value: "deepseek-ai/DeepSeek-V3" },
        // { name: "gemini-1.5-pro", value: "gemini-1.5-pro" },
        // { name: "gemini-2.0-flash-exp", value: "gemini-2.0-flash-exp" },
        // { name: "Preplexity-Sonar-Pro", value: "sonar-pro" },
        // { name: "Preplexity-Sonar", value: "sonar" },
        // { name: "Preplexity-Sonar-Reasoning", value: "sonar-reasoning" },
    ];

    let selected_model = writable("gpt-4o");

    let modal_open = writable(false);
    let confirm_modal_open = writable(false);
    let component_state = writable();

    let submit_disabled = writable(false);
    let submit_loading = writable(false);

    let is_collapsed = true;


    $:{
        submit_disabled.set(override_submit_disabled);
    }
    global_state.subscribe((state) => {
        component_state.set(getNestedDict(state, component_address));
    });
    global_state.subscribe((s) => {
        if (use_files) {
            const current_files =
                getNestedDict(s, [
                    ...component_address.slice(0, -1),
                    "files",
                ]) ?? {};
            let should_disable = false;

            // Disable if no files
            if (
                Object.keys(current_files).filter((k) => k !== "data")
                    .length === 0
            ) {
                should_disable = true;
            } else {
                // Check if any files are not ready
                Object.entries(current_files).forEach(([file_id, file]) => {
                    if (file_id === "data") return;
                    if (file?.upload?.data?.status !== "Ready") {
                        should_disable = true;
                    }
                });
            }

            submit_disabled.set(should_disable);
        }
        if (override_submit_disabled !== null && override_submit_disabled !== undefined) {
            submit_disabled.set(override_submit_disabled);
        } else {
        }
        let should_loading = false;
        Object.entries(accordion_items_refs).forEach(
            ([key, accordion_item_ref]) => {
                if (!accordion_item_ref) {
                    return;
                }
                if (accordion_item_ref.isLoading()) {
                    should_loading = true;
                    return;
                }
            },
        );
        submit_loading.set(should_loading);
        if (override_submit_loading) {
            override_submit_loading.subscribe((v) => {
                submit_loading.set(v);
            });
        }
    });

    function handleSubmitAll() {
        console.log("submit all");
        for (let [key, accordion_item_ref] of Object.entries(
            accordion_items_refs,
        )) {
            accordion_item_ref.handleRegenerate();
        }
    }
    function handleStopAll() {
        for (let [key, accordion_item_ref] of Object.entries(
            accordion_items_refs,
        )) {
            accordion_item_ref.stopTask();
        }
    }

    function handleClearAll(delete_instructions, delete_files, delete_narratives) {
        console.log("clear all");
        global_state.update((state) => {
            if (delete_instructions) {
                updateNestedDict(
                    state,
                    [...component_address, "instructions", "data"],
                    {
                        prompt: "",
                    },
                );
            }
            return state;
        });

        if (use_files && delete_files) {
            // TODO: Delete files
            const files = getNestedDict($global_state, [
                ...component_address.slice(0, -1),
                "files",
            ]);
            console.log("to delete", files);
            for (let [key, file] of Object.entries(files ?? {})) {
                if (key === "data") continue;
                files_table_ref.handleDeleteFile(file.data);
            }
        }
        if (delete_narratives) {
            // TODO: Delete narratives
            review_baseline.set("");
            review_advance.set("");
            review_uncertainty.set("");
            review_resolution.set("");
        }

        for (let [key, accordion_item_ref] of Object.entries(
            accordion_items_refs,
        )) {
            accordion_item_ref.stopTask();
            accordion_item_ref.handleClear(delete_instructions);
        }
        confirm_modal_open.set(false);
    }
</script>

<div class="mt-5 flex justify-end mr-2">
    <Button
        size="sm"
        color="alternative"
        class="hover:bg-purple-200 mr-2"
        title={is_collapsed
            ? "Collapse all accordions"
            : "Expand all accordions"}
        on:click={() => {
            is_collapsed = !is_collapsed;
            for (let [key, accordion_item_ref] of Object.entries(
                accordion_items_refs,
            )) {
                if (is_collapsed) {
                    accordion_item_ref.handleExpand();
                } else {
                    accordion_item_ref.handleCollapse();
                }
            }
        }}
    >
        {#if is_collapsed}
            <ChevronDoubleUpOutline></ChevronDoubleUpOutline>
        {:else}
            <ChevronDoubleDownOutline></ChevronDoubleDownOutline>
        {/if}
    </Button>

    <Button
        size="sm"
        color="alternative"
        class="hover:bg-purple-200 mr-2"
        title="Clear all instructions and generated content"
        on:click={() => {
            confirm_modal_open.set(true);
        }}
    >
        <TrashBinOutline></TrashBinOutline>
    </Button>
        {#if !hide_model_selector}
            <div class="relative inline-block mr-2">
                <Select
                    size="sm"
                    items={models}
                    bind:value={$selected_model}
                    on:change={(e) => {
                        console.log("selected model changed", e.target.value);
                        Object.entries(accordion_items_refs).forEach(
                            ([key, accordion_item_ref]) => {
                                accordion_item_ref.changeSelectedModel(
                                    $selected_model,
                                );
                            },
                        );
                    }}
                    title="Select the model to use for all accordions"
                />
            </div>
        {/if}
    <div class="relative inline-block mr-2">
        <Button
            size="sm"
            color="alternative"
            title="Add instructions"
            on:click={() => {
                console.log("clicked");
                modal_open.set(true);
            }}>Add Instructions</Button
        >
        {#if $component_state?.instructions?.data?.prompt}
            <span
                class="absolute top-0 right-0 transform translate-x-1/4 -translate-y-1/4 bg-purple-500 text-white rounded-full w-4 h-4 flex items-center justify-center text-xs"
            >
            </span>
        {/if}
    </div>
    {#if $submit_loading}
        <Button
            size="xs"
            on:click={() => {
                handleStopAll();
            }}
            color="alternative"
            class="hover:bg-purple-200  mr-3"
            title="Stop Processing"
        >
            <StopOutline color="red"></StopOutline>
        </Button>

        <div class="mt-1">
            <Spinner size={5}></Spinner>
        </div>
    {:else if use_files}
        <div class="relative inline-block mr-2">
            <Button
                title={$submit_disabled
                    ? "You need to upload a file first"
                    : "Submit All"}
                bind:disabled={$submit_disabled}
                color="purple"
                size="sm"
                on:click={handleSubmitAll}>Submit</Button
            >
        </div>
    {:else}
        <div class="relative inline-block mr-2">
            <Button
                title="Submit All"
                color="purple"
                size="sm"
                on:click={handleSubmitAll}>Submit</Button
            >
        </div>
    {/if}
</div>
<GenericAddInstructionsModal
    component_address={[...component_address, "instructions"]}
    bind:modal_open={$modal_open}
></GenericAddInstructionsModal>

<GenericConfirmModal
    {use_files}
    bind:modal_open={$confirm_modal_open}
    title="Clear All"
    message="Are you sure you want to clear all outputs? This action cannot be undone."
    confirm_text="Clear"
    cancel_text="Cancel"
    on_confirm={handleClearAll}
    {has_delete_narratives}
></GenericConfirmModal>
