<script>
    import { Button, Modal, Checkbox } from "flowbite-svelte";
    export let modal_open = false;
    export let title = "Confirm";
    export let message = "Are you sure?";
    export let confirm_text = "Confirm";
    export let cancel_text = "Cancel";
    export let on_confirm;
    export let use_files = true;
    export let has_delete_narratives = false;
    export let has_delete_instructions = false;

    let delete_instructions = false;
    let delete_files = false;
    let delete_narratives = false;
</script>

<Modal bind:open={modal_open} {title}>
    <div>
        <p class="mb-5 text-gray-500 dark:text-gray-400">
            {message}
        </p>
        {#if has_delete_instructions}
            <Checkbox class="mb-5" bind:checked={delete_instructions}>Delete Instructions</Checkbox>
        {/if}
        {#if use_files}
            <Checkbox class="mb-5" bind:checked={delete_files}>Delete Files</Checkbox>
        {/if}
        {#if has_delete_narratives}
            <Checkbox class="mb-5" bind:checked={delete_narratives}>Delete Narratives</Checkbox>
        {/if}

        <div class="flex justify-center gap-4">
            <Button color="red" on:click={() => {
                on_confirm(delete_instructions, use_files && delete_files, delete_narratives);
            }}>
                {confirm_text}
            </Button>
            <Button color="alternative" on:click={() => (modal_open = false)}>
                {cancel_text}
            </Button>
        </div>
    </div>
</Modal>
