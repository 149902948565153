<script>
  import { ArrowRightOutline, ArrowLeftOutline } from "flowbite-svelte-icons";
  import businesssupportexpertsLogo from "./assets/testimonials/businesssupportexperts.jpeg";
  import ciaran from "./assets/testimonials/Ciaran Downs.png";
  import jay from "./assets/testimonials/Jay Avraj Bhatti.png";
  import karen from "./assets/testimonials/Karen Evans FCCA.png";
  import matthew from "./assets/testimonials/Matthew Tottey.png";
  import mick from "./assets/testimonials/Mick Merrick.png";
  import sahil from "./assets/testimonials/Sahil Shethia.png";
  import seduloLogo from "./assets/testimonials/sedulo.jpeg";
  import thisisswoopLogo from "./assets/testimonials/thisisswoop.jpeg";
  import Testimonial from "./components/Testimonial.svelte";

  let current = 0;

  function navigate(direction) {
    current = (current + direction + 6) % 6;
  }
</script>

<section class="mx-0 md:mx-0">
  <h3 class="font-bold text-4xl text-center mb-10 mx-10">
    What users say about SmartClaim
  </h3>
  <div class="flex md:mx-56">
    <button
      class="rounded-3xl p-1 border h-8 self-center"
      on:click={() => navigate(-1)}
    >
      <ArrowLeftOutline />
    </button>

    {#if current === 0}
      <Testimonial
        name="Ciarán Downs"
        company="Sedulo"
        position="Accounting & Tax Technical Lead"
        linkedin="https://www.linkedin.com/in/6f974h6d2v5n85ct4x7922pg960/"
        image={ciaran}
        company_image={seduloLogo}
      >
        At Sedulo we have been <span class="highlight">highly impressed</span>
        with the SmartClaim product. We believe that while AI cannot and should not
        replace the R&D adviser, SmartClaim leverages the power of AI in
        <span class="highlight">the right way</span>
        to augment the capabilities of R&D tax professionals – improving compliance,
        gaining efficiencies, and enhancing the delivery of quality R&D tax advice
        to clients.<br /><br />SmartClaim technology supports multiple aspects
        of R&D claim preparation,
        <span class="highlight">saving us considerable time</span>
        at multiple stages throughout each engagement. One example is the ‘review’
        function which is an excellent complementary tool from a quality control
        perspective, helping to identify any potential deficiencies in the draft
        R&D project description prior to Partner review.<br /><br />Shilin and
        Amin at SmartClaim are
        <span class="highlight">attentive to user feedback</span>
        and
        <span class="highlight">committed to constant evolution</span>
        of the product, with a
        <span class="highlight">rapid development cycle</span>. Over just a
        couple of months we’ve seen significant improvements in the quality and
        consistency of the output, as well as the introduction of a variety of
        helpful new features.<br /><br />As an ‘Enterprise’ tier customer we are
        <span class="highlight">delighted to recommend</span> SmartClaim, and excited
        to see what comes next from this innovative start-up!
      </Testimonial>
    {/if}
    {#if current === 1}
      <Testimonial
        name="Mick Merrick"
        company=""
        position="R&D Advisor"
        linkedin="https://www.linkedin.com/in/mickmerrick/"
        image={mick}
      >
        I’ve had the <span class="highlight">pleasure</span> of working with
        Shilin and the SmartClaim team over the last three months, and it’s been
        a really positive experience for my business. They are not only
        <span class="highlight">highly responsive</span>
        and <span class="highlight">diligent</span> in addressing questions and
        queries but also willing to roll up their sleeves to help and to learn.
        - <span class="highlight">Highly recommended.</span></Testimonial
      >
    {/if}
    {#if current === 2}
      <Testimonial
        name="Matthew Tottey"
        company="BSE"
        position="Head of Quality & Compliance"
        image={matthew}
        linkedin="https://www.linkedin.com/in/matthew-tottey-45b74188/"
        company_image={businesssupportexpertsLogo}
        >We've just completed a successful 1 month pilot and have just rolled it
        out across our team - would <span class="highlight"
          >thoroughly recommend</span
        >!</Testimonial
      >
    {/if}
    {#if current === 3}
      <Testimonial
        name="Sahil Shethia"
        company="Swoop Funding"
        position="R&D Tax Case Manager"
        image={sahil}
        linkedin="https://www.linkedin.com/in/sahil-shethia/"
        company_image={thisisswoopLogo}
        >I can vouch that the software they have made is <span class="highlight"
          >absolutely incredible</span
        >✨ ... You really have to try - will ease up your time.</Testimonial
      >
    {/if}
    {#if current === 4}
      <Testimonial
        name="Karen Evans FCCA"
        company="TICTAX Solutions"
        position="Director"
        linkedin="https://www.linkedin.com/in/karen-evans-tictax/"
        image={karen}
        >[This] ai product is helping me audit the information given to me by
        the clients, highlight potential gaps in their baseline assessment
        compared to what the internet searches suggest might be the technically
        know how freely available, suggests questions to ask the client to
        qualify the work more precisely and output for AIF and more - it doesn't
        replace the need for us, just let's us do a <span class="highlight"
          >thorough job in a fraction of the time</span
        >. I had an 8 project claim and SmartClaim (Shilin) helped me audit,
        clarify and prepare it so I could turn it around within a week. It was
        submitted towards the end of October and the tax credit is already
        showing on the client's inline CT account. Definitely worth using and
        helping Shilin develop it further.</Testimonial
      >
    {/if}
    {#if current === 5}
      <Testimonial
        name="Jay Avraj Bhatti"
        company=""
        position="R&D advisor"
        linkedin="https://www.linkedin.com/in/jayavrajbhatti/"
        image={jay}
        >As Shilin mentioned in the Webinar, it is not a be-all and end-all
        solution - it is still highly dependent on good information capture by
        the consultant (as with every R&D claim!)<br /><br /> But the wonderful
        thing about SmartClaim, at least in my experience, is that I liken it to
        Rifle - it is a highly engineered and accurate machine that is dedicated
        towards researching / qualifying / testing / drafting for R&D Claims.<br
        /><br />ChatGPT and Claude on the other hand, whilst powerful, are like
        a shotgun - they are general tools and the outputs tend to be very
        scattered - unless you impose a crap load of prompts to force the AI to
        behave a certain way.<br /><br />So - for full transparency - am
        independent from Smart Claim - but they have their
        <span class="highlight"
          >Ethics + Powerful AI model dediated to R&D claims + really easy to
          use interface = really powerful tool</span
        >.<br /><br />Very useful for small claims, especially given economics
        have changed given AIF form etc.<br /><br />Medium - Large claims as
        well - enables much faster response time, and flags areas that client
        needs to address, that would otherwise present a risk point with HMRC</Testimonial
      >
    {/if}
    <button
      class="rounded-3xl p-1 border h-8 self-center"
      on:click={() => navigate(1)}
    >
      <ArrowRightOutline />
    </button>
  </div>
</section>

<style>
  div {
    text-align: center;
    padding: 20px;
  }
  button {
    margin: 5px;
  }
  .highlight {
    font-weight: bold;
    color: dodgerblue;
  }
</style>
