<script>
    import { Card, Hr, P, Span } from "flowbite-svelte";
    import NewFilesTable from "../components/Files/NewFilesTable.svelte";
    import CustomAccordion from "../components/CustomAccordion.svelte";
    import GenericAccordionItem from "../components/GenericAccordionItem.svelte";
    import TopToolbar from "../components/TopToolbar.svelte";
    import BottomToolbar from "../components/BottomToolbar.svelte";
    let component_address = ["script"];
    let files_table_ref = null;
    let accordion_items_refs = {};
</script>

<div class="w-full flex flex-grow flex-col justify-center mb-40">
    <div class="justify-center flex mt-5">
        <Card size="xl">
            <h3 class="mb-1 font-bold text-gray-900">
                Upload past reports (Optional)
            </h3>

            <NewFilesTable
                bind:this={files_table_ref}
                component_address={[...component_address, "files"]}
                module_name="script"
            ></NewFilesTable>

            <Hr classHr="my-8" />

            <TopToolbar
                component_address={[...component_address, "toolbar"]}
                {accordion_items_refs}
                {files_table_ref}
                hide_model_selector={true}
                override_submit_disabled={false}
            ></TopToolbar>

            <P size="lg" class="my-5 font-bold"
                ><Span gradient>Interview questions</Span></P
            >

            <CustomAccordion>
                <!-- Accordion 1 takes files from module script_1 file uploads. Prompts will be taken from ["script", "accordion_1"] -->
                <GenericAccordionItem
                    bind:this={accordion_items_refs["summarise_past"]}
                    title="Summary of previous projects"
                    component_address={[...component_address, "summarise_past"]}
                    hide_model_selector={true}
                    include_source_attributions={false}
                    include_fact_check={false}
                    override_selected_model="gpt-4o"
                    module_name="script"
                    must_use_files={true}
                ></GenericAccordionItem>
                <GenericAccordionItem
                    bind:this={accordion_items_refs["identify_project"]}
                    title="Questions to identify projects"
                    component_address={[
                        ...component_address,
                        "identify_project",
                    ]}
                    hide_model_selector={true}
                    include_source_attributions={false}
                    include_fact_check={false}
                    override_selected_model="gpt-4o"
                    module_name="script"
                    must_use_files={true}
                ></GenericAccordionItem>
                <GenericAccordionItem
                    bind:this={accordion_items_refs["new_project"]}
                    title="Questions for new projects"
                    component_address={[...component_address, "new_project"]}
                    hide_model_selector={true}
                    include_source_attributions={false}
                    include_fact_check={false}
                    override_selected_model="gpt-4o"
                    module_name="script"
                    must_use_files={true}
                ></GenericAccordionItem>
                <GenericAccordionItem
                    bind:this={accordion_items_refs["ongoing_project"]}
                    title="Questions for ongoing projects"
                    component_address={[
                        ...component_address,
                        "ongoing_project",
                    ]}
                    hide_model_selector={true}
                    include_source_attributions={false}
                    include_fact_check={false}
                    override_selected_model="gpt-4o"
                    module_name="script"
                    must_use_files={true}
                ></GenericAccordionItem>
            </CustomAccordion>

            <div class="mt-10">
                <BottomToolbar
                    download_file_name="Script"
                    template_type="script"
                    {accordion_items_refs}
                    {files_table_ref}
                    component_address={[...component_address, "bottom_toolbar"]}
                ></BottomToolbar>
            </div>
            <div class="flex justify-center my-10">
                <P size="xs" class="text-gray-800"
                    >AI may make mistakes. Please review the output carefully.</P
                >
            </div>
        </Card>
    </div>
</div>
