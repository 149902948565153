<script lang="ts"> 
    import { AccordionItem, Card, P, Span } from "flowbite-svelte";
    import RealtimeAnalysis from "./RealtimeAnalysis.svelte";
    import RealtimeTranscription from "./RealtimeTranscription.svelte";
    import { global_state } from "../../service/store";
    import { getNestedDict, updateNestedDict } from "../../service/sse";
    import CustomAccordion from "../components/CustomAccordion.svelte";
    import { make_request } from "../../service/http_calls";
    import { onDestroy } from "svelte";
    import { summaries } from "./store";
    let leftPanelVisible = true;
    const togglePanel = () => (leftPanelVisible = !leftPanelVisible);

    let projects = [];
    let transcript = "";

    const unsubscribe = global_state.subscribe(async (state) => {
        try {
            const raw = getNestedDict(state, [
                "realtime",
                "router",
                "projects",
            ]);
            const newProjects = JSON.parse(raw || "{}")?.projects || [];

            if (JSON.stringify(projects) !== JSON.stringify(newProjects)) {
                projects = newProjects;
            }
        } catch (e) {
            console.error("State update error:", e);
        }

        const summaries_data = getNestedDict(state, [
            "realtime",
            "summaries",
            "batch"
        ]);

        // "realtime", "summaries", project_name, key
        if (summaries_data) {
            for (const project_name of Object.keys(summaries_data)) {
                for (const [item, item_content] of Object.entries(JSON.parse(summaries_data[project_name]["data"]))) {
                    updateNestedDict(
                        state,
                        ["realtime", "summaries", project_name, item, "data"],
                        {
                            content: item_content,
                        },
                    );
                }
            }
        }
    });

    export async function handleCallAnalysis(event) {
        const key = event.detail.project_name.trim();
        console.log("Analysis request for:", key);
        await get_analysis_all_at_once(key);
        await get_evaluations_1_batch(key);
        await get_evaluations_2_batch(key);
        await get_next_steps(key);
    }

    async function get_next_steps(project_name){
        console.log("Waiting for next steps for:", project_name);

        while (getNestedDict($global_state, ["realtime", "evaluations_1", "batch", project_name, "completed"]) !== "true") {
            await new Promise(resolve => setTimeout(resolve, 1000));
        }

        const evaluations_1_response = getNestedDict($global_state, ["realtime", "evaluations_1", "batch", project_name, "data"]);
        console.log("Evaluation 1 complete, waiting for evaluation 2");

        while (getNestedDict($global_state, ["realtime", "evaluations_2", "batch", project_name, "completed"]) !== "true") {
            await new Promise(resolve => setTimeout(resolve, 1000));
        }

        const evaluations_2_response = getNestedDict($global_state, ["realtime", "evaluations_2", "batch", project_name, "data"]);

        console.log("Evaluation 2 complete!");

        const request_id = crypto.randomUUID();
        await make_request(
            {
                type: "ask_llm",
                sub_type: "regenerate",
                request_id: request_id,
                payload: {
                    system_prompt_key: [
                        "realtime",
                        "next_steps",
                        "system_prompt",
                    ],
                    assistant_prompt_key: [
                        "realtime",
                        "next_steps",
                        "assistant_prompt",
                    ],
                    function_definition_key: [
                        "realtime",
                        "next_steps",
                        "function",
                    ],
                    selected_model: "gpt-4o",
                    module: "realtime",
                    temperature: 0.3,
                    extra_context: `Project to analyze: ${project_name}\nEvaluations 1: ${JSON.stringify(evaluations_1_response)}\n\Evaluations 2: ${JSON.stringify(evaluations_2_response)}`,
                    must_use_files: false,
                    include_source_attributions: false,
                    include_fact_check: false,
                },
            },
            ["realtime", "next_steps"],
            {
                data: ["realtime", "next_steps",  project_name, "data"],
                completed: ["realtime", "next_steps",  project_name, "completed"],
            },
        );
    }

    export async function get_evaluations_1_batch(project_name) {
        console.log("Evaluation request for:", project_name, " Waiting for summaries to be completed");

        // wait until summary analysis is complete
        while (getNestedDict($global_state, ["realtime", "summaries", "batch", project_name, "completed"]) !== "true") {
            await new Promise(resolve => setTimeout(resolve, 1000));
        }

        console.log("Summary analysis complete, asking evaluation 1");


        const summaries_response = getNestedDict($global_state, ["realtime", "summaries", "batch", project_name, "data"]);

        const request_id = crypto.randomUUID();
        await make_request(
            {
                type: "ask_llm",
                sub_type: "regenerate",
                request_id: request_id,
                payload: {
                    system_prompt_key: [
                        "realtime",
                        "evaluations_1",
                        "system_prompt",
                    ],
                    assistant_prompt_key: [
                        "realtime",
                        "evaluations_1",
                        "assistant_prompt",
                    ],
                    function_definition_key: [
                        "realtime",
                        "evaluations_1",
                        "function",
                    ],
                    selected_model: "gpt-4o",
                    module: "realtime",
                    temperature: 0.3,
                    extra_context: `Project to analyze: ${project_name}\n\Summaries: ${JSON.stringify(summaries_response)}`,
                    must_use_files: false,
                    include_source_attributions: false,
                    include_fact_check: false,
                },
            },
            ["realtime", "evaluations_1"],
            {
                data: ["realtime", "evaluations_1", "batch", project_name, "data"],
                completed: ["realtime", "evaluations_1", "batch", project_name, "completed"],
            },
        );
    }

    export async function get_evaluations_2_batch(project_name) {
        console.log("Evaluation request for:", project_name, " Waiting for summaries to be completed");

        // wait until summary analysis is complete
        while (getNestedDict($global_state, ["realtime", "summaries", "batch", project_name, "completed"]) !== "true") {
            await new Promise(resolve => setTimeout(resolve, 1000));
        }

        console.log("Summary analysis complete, asking evaluation 1");


        const summaries_response = getNestedDict($global_state, ["realtime", "summaries", "batch", project_name, "data"]);

        const request_id = crypto.randomUUID();
        await make_request(
            {
                type: "ask_llm",
                sub_type: "regenerate",
                request_id: request_id,
                payload: {
                    system_prompt_key: [
                        "realtime",
                        "evaluations_2",
                        "system_prompt",
                    ],
                    assistant_prompt_key: [
                        "realtime",
                        "evaluations_2",
                        "assistant_prompt",
                    ],
                    function_definition_key: [
                        "realtime",
                        "evaluations_2",
                        "function",
                    ],
                    selected_model: "gpt-4o",
                    module: "realtime",
                    temperature: 0.3,
                    extra_context: `Project to analyze: ${project_name}\n\Summaries: ${JSON.stringify(summaries_response)}`,
                    must_use_files: false,
                    include_source_attributions: false,
                    include_fact_check: false,
                },
            },
            ["realtime", "evaluations_2"],
            {
                data: ["realtime", "evaluations_2", "batch", project_name, "data"],
                completed: ["realtime", "evaluations_2", "batch", project_name, "completed"],
            },
        );
    }
    export async function get_analysis_all_at_once(project_name) {
        let all_previous_summaries = "";
        for (const item of Object.keys(summaries)) {
            all_previous_summaries +=
                getNestedDict($global_state, [
                    "realtime",
                    project_name,
                    item,
                    "data",
                    "content",
                ]) ?? "";
        }

        const transcript =
            getNestedDict($global_state, ["realtime", "transcription"]) ??
            "No transcript yet";
        const request_id = crypto.randomUUID();


        global_state.update((state) => {
            updateNestedDict(state, ["realtime", "summaries", "batch", project_name, "completed"], "false");
            return state;
        });

        await make_request(
            {
                type: "ask_llm",
                sub_type: "regenerate",
                request_id: request_id,
                payload: {
                    system_prompt_key: [
                        "realtime",
                        "summaries",
                        "system_prompt",
                    ],
                    assistant_prompt_key: [
                        "realtime",
                        "summaries",
                        "assistant_prompt",
                    ],
                    function_definition_key: [
                        "realtime",
                        "summaries",
                        "function",
                    ],
                    selected_model: "gpt-4o",
                    module: "realtime",
                    temperature: 0.1,
                    extra_context: `Project to analyze: ${project_name}\n\nTranscript: ${transcript}\n\nPrevious Summaries: ${all_previous_summaries}`,
                    must_use_files: false,
                    include_source_attributions: false,
                    include_fact_check: false,
                },
            },
            ["realtime", "summaries"],
            {
                data: ["realtime", "summaries", "batch", project_name, "data"],
                completed: ["realtime", "summaries", "batch", project_name, "completed"],
            },
        );
    }
    onDestroy(() => {
        unsubscribe();
    });
</script>

<div class="w-full flex flex-grow flex-col justify-center mb-40">
    <div class="justify-center flex mt-5">
        <Card size="xl">
            <div class="flex h-[80vh]">
                {#if leftPanelVisible}
                    <div class="w-1/2 border-r border-gray-200">
                        <RealtimeTranscription
                            on:callAnalysis={handleCallAnalysis}
                        />
                    </div>
                {/if}

                <!-- Toggle button -->
                <button
                    on:click={togglePanel}
                    class="p-2 bg-gray-200 hover:bg-purple-500 active:bg-purple-700"
                >
                    {#if leftPanelVisible}
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                clip-rule="evenodd"
                            />
                        </svg>
                    {:else}
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                clip-rule="evenodd"
                            />
                        </svg>
                    {/if}
                </button>

                <!-- Right Panel -->
                <div class={leftPanelVisible ? "w-1/2" : "w-full"}>
                    <div class="h-full p-4 overflow-y-auto">
                        <P size="lg" class="font-bold mb-10 mt-2">
                            <Span gradient>List of detected projects</Span>
                        </P>

                        {#if projects.length > 0}
                            <CustomAccordion>
                                {#each projects as project (project.project_name)}
                                    <AccordionItem>
                                        <span slot="header">
                                            {project.project_name}
                                        </span>
                                            <RealtimeAnalysis
                                                project_name={project.project_name}
                                            />
                                    </AccordionItem>
                                {/each}
                            </CustomAccordion>
                        {:else}
                            <p>No projects detected</p>
                        {/if}
                    </div>
                </div>
            </div>
        </Card>
    </div>
</div>
