<script>
    import { Indicator, Tooltip } from "flowbite-svelte";
    import { writable } from "svelte/store";
    import { global_state } from "../../service/store";
    import { getNestedDict } from "../../service/sse";
    export let title = "";
    export let component_address;
    
    let id = `indicator-${crypto.randomUUID()}`;
    let component_state = writable();


    global_state.subscribe((state) => {
        component_state.set(
            getNestedDict(state, component_address)
        );
    });

    export function regenerate() {
        
    }

</script>

<div class="relative">
    <Indicator {id} rounded size="xl" color={$component_state?.color ?? "gray"} class="mx-3" />
    <Tooltip trigger="hover" placement="top" triggeredBy="#{id}">{title}</Tooltip>
</div>
