<script>
    import { Hr, P, Span } from "flowbite-svelte";
    import { defend_wmbc_items } from "./store";
    import CustomAccordion from "../components/CustomAccordion.svelte";
    import GenericAccordionItem from "../components/GenericAccordionItem.svelte";
    import TopToolbar from "../components/TopToolbar.svelte";
    import NewFilesTable from "../components/Files/NewFilesTable.svelte";
    import BottomToolbar from "../components/BottomToolbar.svelte";
    let component_address = ["defend", "wmbc"];
    let accordion_items_refs = {};
    let files_table_ref = null;
</script>

<div class="mb-10">
    <NewFilesTable
        bind:this={files_table_ref}
        component_address={[...component_address, "files"]}
        module_name="defend_wmbc"
    ></NewFilesTable>

    <Hr classHr="my-8" />
    <TopToolbar
        component_address={[...component_address, "toolbar"]}
        {accordion_items_refs}
        {files_table_ref}
    ></TopToolbar>

    {#each Object.entries(defend_wmbc_items) as [section_key, section_value]}
        <P size="lg" class="my-5 font-bold"
            ><Span gradient>{section_value.title}</Span></P
        >
        {@html section_value.description}
        {#each Object.entries(section_value).filter(([question_key, question_value]) => question_key !== "title" && question_key !== "description") as [question_key, question_value], index}
            <P class="font-semibold   mt-20 mb-5">
                {index + 1}. {question_value.title}
            </P>
            <CustomAccordion>
                <GenericAccordionItem
                    hide_model_selector={true}
                    disable_paragraph_regenerate={true}
                    bind:this={accordion_items_refs[`${question_key}_outline`]}
                    title="Outline"
                    suppress_notification={true}
                    component_address={[
                        ...component_address,
                        question_key,
                        "outline",
                    ]}
                    module_name="defend_wmbc"
                    override_selected_model={question_value.outline.model}
                ></GenericAccordionItem>
                <GenericAccordionItem
                    hide_model_selector={true}
                    disable_paragraph_regenerate={true}
                    bind:this={accordion_items_refs[`${question_key}_draft`]}
                    title="Draft"
                    suppress_notification={true}
                    component_address={[
                        ...component_address,
                        question_key,
                        "draft",
                    ]}
                    module_name="defend_wmbc"
                    dependent_components={[
                        {
                            completed_address: [
                                ...component_address,
                                question_key,
                                "outline",
                                "data",
                                "completed",
                            ],
                            content_address: [
                                ...component_address,
                                question_key,
                                "outline",
                                "data",
                                "content",
                            ],
                        },
                    ]}
                    override_selected_model={question_value.draft.model}
                ></GenericAccordionItem>
            </CustomAccordion>
            <div class="my-10"></div>
        {/each}
    {/each}
    <div class="mt-10">
        <BottomToolbar
            template_type="defend_wmbc"
            download_file_name="Defend WMBC"
            {accordion_items_refs}
            {files_table_ref}
            use_files={true}
            component_address={[...component_address, "bottom_toolbar"]}
        ></BottomToolbar>
    </div>
</div>
