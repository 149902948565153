<!-- CustomAccordion.svelte -->
<script>
    import { Accordion } from 'flowbite-svelte';
    
    // Define props with default values for customization
    export let activeClass = "bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800";
    export let inactiveClass = "text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800";
  </script>
  
  <Accordion
    multiple
    activeClass={activeClass}
    inactiveClass={inactiveClass}
    {...$$restProps}
    >  
    <slot /> 
  </Accordion>