<script>
  import { SvelteToast } from "@zerodevx/svelte-toast";
  import { Card, P, Spinner } from "flowbite-svelte";
  import { onMount } from "svelte";
  import Chat from "./lib/Chat/Chat.svelte";
  import CookieConsent from "./lib/CookieConsenter.svelte";
  import NewDefend from "./lib/Defend/NewDefend.svelte";
  import DraftReasonerMain from "./lib/DraftReasoner/DraftReasonerMain.svelte";
  import DraftReasonerSA from "./lib/DraftReasonerSA/DraftReasonerMain.svelte";
  import DraftReasonerAU from "./lib/DraftReasonerAU/DraftReasonerMain.svelte";
  import InnovateUK from "./lib/InnovateUK/InnovateMain.svelte";
  import EarlyAccess from "./lib/Landing/EarlyAccess.svelte";
  import Footer from "./lib/Landing/Footer.svelte";
  import LandingPage from "./lib/Landing/LandingPage.svelte";
  import Nav from "./lib/Landing/Nav.svelte";
  import Terms from "./lib/Landing/Terms.svelte";
  import LoginComponent from "./lib/Login/LoginComponent.svelte";
  import SignUpComponent from "./lib/Login/SignUpComponent.svelte";
  import Notify from "./lib/Notify/Notify.svelte";
  import Profile from "./lib/Profile/Profile.svelte";
  import Qualify from "./lib/Qualify/NewQualify.svelte";
  import Realtime from "./lib/Realtime/Realtime.svelte";
  import NewResearch from "./lib/Research/NewResearch.svelte";
  import NewReview from "./lib/Review/NewReview.svelte";
  import Script from "./lib/Script/Script.svelte";
  import Subscriptions from "./lib/subscriptions/Subscriptions.svelte";
  import Success from "./lib/subscriptions/Success.svelte";
  import SupportChat from "./lib/SupportChat.svelte";
  import { getUser } from "./service/http_calls";
  import { connected, connectSSE } from "./service/sse.js";
  import { authenticated_user, current_user } from "./service/store";
  let slug = "";

  let page_loading = false;
  const sections = ["top", "features", "testimonials", "pricing", "contact"];
  let currentIndex = 0;

  function scrollToSection(index) {
    document
      .getElementById(sections[index])
      .scrollIntoView({ behavior: "smooth" });
    currentIndex = index; // Update the index to prevent double scrolling
  }

  onMount(async () => {
    if (!localStorage.getItem("isCleared")) {
      localStorage.clear();
      localStorage.setItem("isCleared", "true");
    }

    // Extracting the slug from the URL
    const pathParts = window.location.pathname.split("/").filter(Boolean);
    if (pathParts.length > 0) {
      slug = pathParts[pathParts.length - 1];
    }

    // if ($current_user) {
    //   userId = $current_user.id;
    // } else {
    if (
      slug != "login" &&
      slug != "signup" &&
      slug != "early-access" &&
      slug != "terms" &&
      slug != ""
    ) {
      console.log("will try to authenticate...");
      page_loading = true;
      await authenticate();
    }
    // }
    if ($current_user) {
      if ($connected == "false") {
        connectSSE();
      }
    } else {
      console.log("no user, not connecting to sse");
      await authenticate();
    }

    // window.addEventListener("wheel", handleScroll, { passive: true });

    // return () => {
    //   window.removeEventListener("wheel", handleScroll);
    // };
  });

  async function authenticate() {
    current_user.set(null);
    try {
      let auth_resp = await getUser();
      page_loading = false;
      if (typeof auth_resp?.id === "undefined") {
        console.log("should redirect right?");
        if (
          slug != "login" &&
          slug != "signup" &&
          slug != "terms" &&
          slug != ""
        ) {
          window.location.href = "/login";
        } else {
          current_user.set(null);
          authenticated_user.set(null);
        }
      } else {
        current_user.set(auth_resp);
        authenticated_user.set(auth_resp);
      }
    } catch (e) {
      console.log("error authenticating", e);

      current_user.set(null);
      authenticated_user.set(null);
      if (
        slug != "login" &&
        slug != "signup" &&
        slug != "terms" &&
        slug != ""
      ) {
        window.location.href = "/login";
      }
    }
  }
</script>

<svelte:head>
  <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
</svelte:head>

<Nav />

{#if page_loading}
  <div class="flex justify-center items-center mt-10">
    <div class="flex flex-col items-center">
      <Spinner class="my-10"></Spinner>
      <P weight="bold">Loading, please wait...</P>
    </div>
  </div>
{:else if slug === ""}
  <LandingPage />
{:else if slug == "early-access"}
  <EarlyAccess></EarlyAccess>
{:else if slug == "profile"}
  <Profile></Profile>
{:else if slug == "research"}
  <NewResearch></NewResearch>
{:else if slug == "notify"}
  <Notify></Notify>
{:else if slug == "qualify"}
  <Qualify></Qualify>
{:else if slug == "review"}
  <NewReview></NewReview>
{:else if slug == "defend"}
  <NewDefend></NewDefend>
{:else if slug == "subscriptions"}
  <Subscriptions></Subscriptions>
{:else if slug == "success"}
  <Success></Success>
{:else if slug == "login"}
  <LoginComponent></LoginComponent>
{:else if slug == "signup"}
  <SignUpComponent></SignUpComponent>
{:else if slug == "realtime"}
  <Realtime></Realtime>
{:else if slug == "script"}
  <Script></Script>
{:else if slug == "draft"}
  <DraftReasonerMain />
{:else if slug == "draft_sa"}
  <DraftReasonerSA />
{:else if slug == "draft_au"}
  <DraftReasonerAU />
{:else if slug == "innovate_uk"}
  <InnovateUK />
{:else if slug == "terms"}
  <div class="flex justify-center items-center mt-10">
    <Card size="xl" class="m-40 p-10">
      <Terms />
    </Card>
  </div>
{:else if slug == "chat"}
  <Chat />
{/if}

<SvelteToast options={{ classes: ["log"], duration: 8000 }} />

{#if $current_user && slug != "" && typeof $authenticated_user?.id !== "undefined"}
  <SupportChat />
{/if}

<CookieConsent
  allowButtonText={"Accept"}
  descriptionText={"We are using cookies to improve your experience. For more information, please check  "}
  cookiePolicyText={"SmartClaim Terms & Conditions"}
  cookiePolicyUrl={"https://smartclaim.uk/terms"}
  bgColor={"bg-gray-200"}
  buttonTextColor={"text-white"}
  buttonBgColor={"bg-purple-500"}
  descriptionTextColor={"text-gray-600"}
/>

<Footer></Footer>

<style>
  :global(.log.info) {
    --toastBackground: green;
  }
  :global(.log.warn) {
    --toastBackground: red;
  }
</style>
