<script>
    import ProgressBar from "@okrad/svelte-progressbar";
    import {
        Button,
        P,
        Spinner,
        Table,
        TableBody,
        TableBodyCell,
        TableBodyRow,
        TableHead,
        TableHeadCell,
    } from "flowbite-svelte";
    import {
        CheckOutline,
        DotsHorizontalOutline,
        EyeSolid,
        TrashBinSolid,
    } from "flowbite-svelte-icons";

    import { onMount } from "svelte";
    import { writable } from "svelte/store";
    import { v4 as uuidv4 } from "uuid";
    import { make_request } from "../../../service/http_calls";
    import {
        deleteNestedDict,
        getNestedDict,
        updateNestedDict,
    } from "../../../service/sse";
    import { global_state } from "../../../service/store";
    import GenericContentViewModal from "../GenericContentViewModal.svelte";
    import NewFileUploader from "./NewFileUploader.svelte";
    export let component_address;
    export let module_name;

    let summary_modal_open = writable(false);
    let content_modal_open = writable(false);

    let selected_file = null;
    let files_dict = writable({});

    global_state.subscribe((s) => {
        files_dict.set(getNestedDict(s, component_address) ?? {});
    });

    $: files_list = Object.fromEntries(
        Object.entries($files_dict).filter(([key, value]) => key !== "data"),
    );

    onMount(async () => {
        const request_id = uuidv4();
        global_state.update((s) => {
            updateNestedDict(s, [...component_address, "data"], {
                loading: "true",
            });
            return s;
        });
        await make_request(
            {
                type: "files",
                sub_type: "list",
                request_id: request_id,
                payload: {
                    module: module_name,
                },
            },
            component_address,
            {
                data: component_address,
                status: [],
                loading: [...component_address, "data", "loading"],
                completed: [...component_address, "data", "completed"],
            },
        );

        // remove files that doesnt have id in them
        global_state.update((s) => {
            const files_dict = getNestedDict(s, component_address);
            Object.entries(files_dict).forEach(([key, value]) => {
                if (key === "data") {
                    return;
                }
                if (value.id === undefined) {
                    console.log("deleting file with request_id", key);
                    deleteNestedDict(s, [...component_address, key]);
                }
            });
            return s;
        });
    });


    export function allFilesReady() {
        return Object.keys(files_list).length > 0 &&
            Object.values(files_list).every((file) => file.upload?.data?.status === "Ready")
    }

    export const handleDeleteFile = async (file) => {
        const file_request_id = file.request_id;
        const file_id = file.id;

        if (file_id === undefined) {
            global_state.update((s) => {
                deleteNestedDict(s, [...component_address, file_request_id]);
                return s;
            });
            return;
        }

        const request_id = uuidv4();

        global_state.update((s) => {
            updateNestedDict(
                s,
                [
                    ...component_address,
                    file_request_id,
                    "delete",
                    "data",
                    "loading",
                ],
                "true",
            );
            return s;
        });

        await make_request(
            {
                type: "files",
                sub_type: "delete",
                request_id: request_id,
                payload: {
                    module: module_name,
                    file_request_id: file_request_id,
                },
            },
            component_address,
            {
                delete: [...component_address, file_request_id],
            },
        );
    };
</script>

<div>
    <NewFileUploader
        component_address={[...component_address.slice(0, -1), "uploader"]}
        {module_name}
    ></NewFileUploader>
    {#if $files_dict.data?.loading == "true"}
        <div class="flex flex-col items-center text-center justify-center">
            <Spinner class="mb-5"></Spinner>
            <P size="sm">Loading files...</P>
        </div>
    {:else}
        <Table striped={true} class="w-full table-fixed mt-5">
            <TableHead
                class="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400"
            >
                <TableHeadCell class="w-2/6">File name</TableHeadCell>
                <TableHeadCell class="w-1/6 text-right">Status</TableHeadCell>
                <!-- <TableHeadCell class="w-1/6 text-right"
                    >Embeddings</TableHeadCell
                > -->
                <TableHeadCell class="w-1/6 text-right">Content</TableHeadCell>
                <TableHeadCell class="w-1/6 text-right">Summary</TableHeadCell>
                <TableHeadCell class="w-1/6 text-right">Delete</TableHeadCell>
            </TableHead>
            <TableBody tableBodyClass="divide-y">
                {#each Object.entries(files_list) as [file_request_id, file]}
                    <TableBodyRow>
                        <TableBodyCell
                            class="truncate overflow-hidden text-ellipsis"
                            >{file.data.original_file_name ??
                                file.data.name}</TableBodyCell
                        >
                        <TableBodyCell
                            class="w-2/6 text-right justify-end items-end"
                        >
                            {#if file.upload?.data?.upload_percentage}
                                <ProgressBar
                                    valueLabel="Uploading"
                                    series={[
                                        {
                                            perc: file.upload.data
                                                .upload_percentage,
                                            color: "#abeda4",
                                        },
                                    ]}
                                ></ProgressBar>
                            {:else}
                                <P class="text-right"
                                    >{file.upload?.data?.status}</P
                                >
                            {/if}
                        </TableBodyCell>
                        <!-- <TableBodyCell
                            class="w-1/6 "
                        >
                            <div class="flex justify-center pl-10">
                                {#if !file.data.has_embedding}
                                    <DotsHorizontalOutline />
                                {:else}
                                    <CheckOutline color="green" />
                                {/if}
                            </div>
                        </TableBodyCell> -->

                        <TableBodyCell class="w-1/6 text-right">
                            {#if file.upload?.data?.status === "Ready"}
                                <Button
                                    outline={true}
                                    class="!p-2"
                                    size="xs"
                                    color="purple"
                                    on:click={() => {
                                        selected_file = file.data;
                                        content_modal_open.set(true);
                                    }}
                                >
                                    <EyeSolid class="" />
                                </Button>
                            {/if}
                        </TableBodyCell>

                        <TableBodyCell class="w-1/6 text-right">
                            {#if file.upload?.data?.status === "Ready"}
                                <Button
                                    outline={true}
                                    class="!p-2"
                                    size="xs"
                                    color="purple"
                                    on:click={() => {
                                        selected_file = file.data;
                                        summary_modal_open.set(true);
                                    }}
                                >
                                    <EyeSolid class="" />
                                </Button>
                            {:else}
                                <P class="text-right">Loading...</P>
                            {/if}
                        </TableBodyCell>
                        <TableBodyCell class="w-1/6 text-right">
                            {#if file.delete?.data?.loading}
                                <Spinner></Spinner>
                            {:else}
                                <Button
                                    outline={true}
                                    class="!p-2"
                                    size="xs"
                                    color="red"
                                    on:click={() => {
                                        handleDeleteFile(file.data);
                                    }}
                                >
                                    <TrashBinSolid class="" />
                                </Button>
                            {/if}
                        </TableBodyCell>
                    </TableBodyRow>
                {/each}
            </TableBody>
        </Table>
    {/if}
</div>

<GenericContentViewModal
    title={selected_file?.original_file_name}
    content={selected_file?.summary}
    bind:modal_open={$summary_modal_open}
></GenericContentViewModal>

<GenericContentViewModal
    title={selected_file?.original_file_name}
    content={selected_file?.content}
    bind:modal_open={$content_modal_open}
></GenericContentViewModal>
