<script>
    import News from "./News.svelte";
    import Features from "./Features.svelte";
    import Testimonials from "./Testimonials.svelte";

    import Top from "./Top.svelte";
    import ContactForm from "./ContactForm.svelte";
    import Characteristics from "./Characteristics.svelte";
    import About from "./About.svelte";
    import FandQ from "./FandQ.svelte";
    import CorePrinciples from "./CorePrinciples.svelte";
</script>

<div class="overflow-hidden">
    <section id="top">
        <Top />
    </section>

    <section id="characteristics">
        <Characteristics />
    </section>

    <section id="features">
        <Features />
    </section>

    <section id="testimonials">
        <Testimonials />
    </section>

    <section id="news">
        <News />
    </section>

    <section id="CorePrinciples">
        <CorePrinciples />
    </section>

    <section id="F&Q">
        <FandQ />
    </section>

    <section id="pricing" class="w-full flex items-center justify-center">
        <div class="w-full">
            <stripe-pricing-table
                pricing-table-id="prctbl_1QCfaGAnHQme9GY61y9EgGJP"
                publishable-key="pk_live_51PyTlIAnHQme9GY6dje7mRSTC1tXhUSjX8lfDBc3XpBQ26AKUw6lezjc4P7VOJJMkE0SP4wnRGSu08TEmycdPEms002yaz9jbz"
            />
        </div>
    </section>

    <section id="about-us">
        <About />
    </section>

    <section id="contact" class="mb-10">
        <ContactForm />
    </section>
</div>

<style>
    section {
        padding-top: 100px;
        padding-bottom: 100px;
    }
    .bg-grad {
        background: linear-gradient(to bottom, white, #f8fafc);
    }
</style>
