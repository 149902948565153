<script>
    import { Button, P, Span } from "flowbite-svelte";
    import { ChevronDoubleDownOutline, ChevronDoubleUpOutline, TrashBinOutline } from "flowbite-svelte-icons";
    import { writable } from "svelte/store";
    import CustomAccordion from "../components/CustomAccordion.svelte";
    import CustomIndicatorItem from "../components/CustomIndicatorItem.svelte";
    import GenericAccordionItem from "../components/GenericAccordionItem.svelte";
    import { evaluations, summaries } from "./store";
    import { global_state } from "../../service/store";
    import { getNestedDict } from "../../service/sse";
    import { make_request } from "../../service/http_calls";
    import { onDestroy } from "svelte";


    export let project_name;
    export let accordion_items_refs = {};
    let indicators_refs = {};
    let is_collapsed = false;

    let transcript = "";
    const unsubscribe = global_state.subscribe((state) => {
        transcript = getNestedDict(state, ["realtime", "transcription"]);

    });

    onDestroy(() => {
        unsubscribe();
    });

    async function get_analysis_one_by_one() {
        Object.keys(summaries).forEach((key) => {
            accordion_items_refs[key]?.handleRegenerate();
        });
    }
    export function get_current_content(){
        return Object.keys(summaries)
            .map(
                (key) =>
                    `${summaries[key].title}: ${accordion_items_refs[key]?.getContent() ?? ""}`,
            )
            .join("\n\n");
    }


</script>

<!-- <P size="lg" class="my-5 font-bold">
    <Span gradient>Project: {title}</Span>
</P> -->
<div class="flex justify-center">
    <div class="flex gap-2 mb-10">
        {#each Object.entries(evaluations) as [key, evaluation]}
            <CustomIndicatorItem
                title={evaluation.title}
                component_address={["realtime", key]}
                bind:this={indicators_refs[key]}
            />
        {/each}
    </div>
</div>
<div class="justify-center flex">
    <Button
        class="mr-3 mb-5 hover:bg-purple-200 rounded-full p-2 bg-transparent"
    title={is_collapsed
        ? "Collapse all accordions"
        : "Expand all accordions"}
    on:click={() => {
        is_collapsed = !is_collapsed;
        for (let [key, accordion_item_ref] of Object.entries(
            accordion_items_refs,
        )) {
            if (is_collapsed) {
                accordion_item_ref.handleExpand();
            } else {
                accordion_item_ref.handleCollapse();
            }
        }
    }}
>
    {#if is_collapsed}
        <ChevronDoubleUpOutline color="grey"></ChevronDoubleUpOutline>
    {:else}
        <ChevronDoubleDownOutline color="grey"></ChevronDoubleDownOutline>
    {/if}
</Button>
    <Button
        class="mr-3 mb-5 hover:bg-purple-200 rounded-full p-2 bg-transparent"
        title="Clear content and instructions"
        on:click={() => {
            Object.keys(summaries).forEach((key) => {
                accordion_items_refs[key]?.handleClear();
            });
        }}
    >
        <TrashBinOutline color="grey"></TrashBinOutline>
    </Button>
</div>
<CustomAccordion>
    {#each Object.keys(summaries) as key}
        <GenericAccordionItem
            bind:this={accordion_items_refs[key]}
            title={summaries[key].title}
            component_address={["realtime", "summaries", project_name, key]}
            module_name="realtime"
            must_use_files={false}
            hide_model_selector={true}
            disable_paragraph_regenerate={true}
            suppress_notification={true}
            collapsed={false}
            extra_context={writable(
                `Transcript: ${transcript}\n\nPrevious Value: ${accordion_items_refs[key]?.getContent() ?? ""}`,
            )}
            include_fact_check={false}
            include_source_attributions={false}
        ></GenericAccordionItem>
    {/each}
</CustomAccordion>
