<script>
    import { Card, Hr, TabItem, Tabs, P, Span } from "flowbite-svelte";
    import { onMount } from "svelte";
    import NewFilesTable from "../components/Files/NewFilesTable.svelte";

    import TopToolbar from "../components/TopToolbar.svelte";
    import DraftReasonerProject from "./DraftReasonerProject.svelte";
    import DraftReasonerCoreActivity from "./DraftReasonerCoreActivity.svelte";
    import DraftReasonerSupportingActivity from "./DraftReasonerSupportingActivity.svelte";

    let component_address = ["draft_au"];
    let accordion_items_refs = {};
    let files_table_ref = null;

    onMount(async () => {});
</script>

<div class="w-full flex flex-grow flex-col justify-center mb-5">
    <div class="justify-center flex mt-5">
        <Card size="xl">
            <NewFilesTable
                bind:this={files_table_ref}
                component_address={[...component_address, "files"]}
                module_name="draft_au"
            ></NewFilesTable>

            <Hr classHr="my-8" />

            <P size="lg" class="my-5"><Span gradient>Draft</Span></P>

            <Tabs contentClass="bg-white">
                <TabItem title="Project" open>
                    <DraftReasonerProject />
                </TabItem>
                <TabItem title="Core Activity">
                    <DraftReasonerCoreActivity />
                </TabItem>
                <TabItem title="Supporting Activity">
                    <DraftReasonerSupportingActivity />
                </TabItem>
            </Tabs>
        </Card>
    </div>
</div>
