

export const summaries = {

    "next_steps": {
        "title": "Next Steps",
        "model": "gpt-4o-mini",
    },
    "project_description": {
        "title": "Project Description",
        "model": "gpt-4o-mini",
    },
    "baseline": {
        "title": "Baseline",
        "model": "gpt-4o-mini",
    },

    "advance": {
        "title": "Advance",
        "model": "gpt-4o-mini",
    },
    "uncertainty": {
        "title": "Uncertainty",
        "model": "gpt-4o-mini",
    },
    "resolution": {
        "title": "Resolution",
        "model": "gpt-4o-mini",
    }
};


export const evaluations = {
    "qualifying_field_evaluation": {
        "title": "Qualifying Field Evaluation",
        "model": "gpt-4o-mini",
    },
    "baseline_comprehensiveness": {
        "title": "Baseline Comprehensiveness",
        "model": "gpt-4o-mini",
    },
    "advance_comprehensiveness": {
        "title": "Advance Comprehensiveness",
        "model": "gpt-4o-mini",
    },
    "uncertainty_comprehensiveness": {
        "title": "Uncertainty Comprehensiveness",
        "model": "gpt-4o-mini",
    },
    "resolution_comprehensiveness": {
        "title": "Resolution Comprehensiveness",
        "model": "gpt-4o-mini",
    },
    "advance_check": {
        "title": "Advance Check",
        "model": "gpt-4o-mini",
    },
    "uncertainty_check_publicly_available": {
        "title": "Uncertainty Check Publicly Available",
        "model": "gpt-4o-mini",
    },
    "uncertainty_check_readily_deducible": {
        "title": "Uncertainty Check Readily Deducible",
        "model": "gpt-4o-mini",
    },
    "resolution_check": {
        "title": "Resolution Check",
        "model": "gpt-4o-mini",
    }
}