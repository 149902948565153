<script>
    import { toast } from "@zerodevx/svelte-toast";
    import { change_password, verify } from "../../service/http_calls";
    import { email_address, show_new_password, show_otp, temporary_token } from "../../service/store";

    let otp = Array(5).fill('');
  
    function handleInput(e, index) {
      const { value } = e.target;
      if (/^\d$/.test(value)) {
        otp[index] = value;
        if (index < otp.length - 1) {
          document.getElementById(`otp-${index + 1}`).focus();
        } else {
          checkCompletion();
        }
      } else {
        otp[index] = '';
      }
    }
  
    function handleBackspace(e, index) {
      if (e.key === 'Backspace' && !otp[index] && index > 0) {
        document.getElementById(`otp-${index - 1}`).focus();
      }
    }
  
    function checkCompletion() {
      if (otp.every(digit => digit !== '')) {
        onComplete();
      }
    }
  
    async function onComplete() {
      // Event or action to trigger when OTP is complete
      console.log("OTP Completed:", otp.join(''));
      // You can dispatch an event or do any other action here.
        let resp = await verify(
            $email_address,
            otp.join('')
        );
        toast.push(resp.detail.message);

        show_otp.set(false);
        show_new_password.set(true);
        temporary_token.set(resp.detail.token);

    }
  </script>


<div class="mb-5">
<p><b>Received Code:</b></p>
</div>
  <div class="flex space-x-2">
    {#each otp as digit, index}
      <input
        id={`otp-${index}`}
        type="text"
        maxlength="1"
        class="w-12 h-12 text-center border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        bind:value={otp[index]}
        on:input={(e) => handleInput(e, index)}
        on:keydown={(e) => handleBackspace(e, index)}
      />
    {/each}
  </div>
  