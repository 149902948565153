<script>
    import { Accordion, AccordionItem } from "flowbite-svelte";
</script>

<section class="mx-10 md:mx-60">
    <h3 class="text-3xl font-bold text-center mb-10">
        Frequently Asked Questions
    </h3>
    <Accordion
        multiple
        activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
        inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
    >
        <AccordionItem>
            <span slot="header">How does SmartClaim work?</span>
            SmartClaim employs the most advanced generative AI models, expertly configured
            with built-in knowledge of R&D tax relief guidelines (DSIT, GfC3, CIRD).
        </AccordionItem>
        <AccordionItem>
            <span slot="header">What's the best model?</span>
            Currently GPT-4o by OpenAI, hosted by Microsoft Azure.
        </AccordionItem>
        <AccordionItem>
            <span slot="header">Is it just like ChatGPT or Claude?</span>
            No, ChatGPT and Claude are for general-purpose use, whereas SmartClaim
            is specifically built for R&D consultants. This enables SmartClaim to
            significantly outperform general-purpose AI in R&D narratives.
        </AccordionItem>
        <AccordionItem>
            <span slot="header">Is it ethical to use AI?</span>
            Yes, provided that the input information is accurate and not fabricated,
            and that human judgement is applied in assessing project eligibility.
            SmartClaim AI relies solely on the information supplied; it does not
            introduce additional details or enhance a project's qualifications beyond
            what is genuinely justified. It functions in the same way as engaging
            a colleague to draft the narrative. Responsible AI use is about leveraging
            its strengths while ensuring human oversight. AI is a powerful tool -
            not a replacement for human expertise, but a complement that amplifies
            it. The newfound efficiency from AI not only enhances the quality of
            the work, but also frees up valuable time for consultants to engage more
            deeply with their clients - the part of the job that's truly rewarding.
        </AccordionItem>
        <AccordionItem>
            <span slot="header">Is safe and secure?</span>
            Absolutely!
            <li class="mt-2 mb-2">
                SmartClaim is built on enterprise-grade security infrastructure
                - Microsoft Azure and AWS, both SOC2 and ISO-27001 certified
            </li>
            <li>
                SmartClaim does not store user data; once a task is completed,
                the data is erased. User data is never used for AI training
            </li>
        </AccordionItem>
        <AccordionItem>
            <span slot="header">Can we try it first?</span>
            Absolutely. We recommend teams and enterprises to start with a controlled
            pilot — typically one-month with a small group of R&D consultants.
        </AccordionItem>
    </Accordion>
</section>
