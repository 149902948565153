<script>
    import { Card, P } from "flowbite-svelte";
    import NewResearchWithFiles from "./NewResearchWithFiles.svelte";
    import { research_page_selected_tab } from "./store";
</script>

<div class="w-full flex flex-grow flex-col justify-center mb-40">
    <div class="justify-center flex mt-5">
        <Card size="xl">
            <NewResearchWithFiles></NewResearchWithFiles>


            <div class="flex justify-center my-10">
                <P size="xs" class="text-gray-800"
                    >AI may make mistakes. Please review the output carefully.</P
                >
            </div>
        </Card>
    </div>
</div>
