import { writable } from "svelte/store";
import { createLocalStorageStore, global_state } from "../../service/store";

export const user_general_instructions = createLocalStorageStore('innovate_uk_reasoner_user_general_instructions', "");

export const component_state = writable(null);

global_state.subscribe(s => {
    component_state.set(s.draft);
});



export const questions = {
    "q_1": {
        "title": "Project Name",
        "dependencies": null,
        "selected_model": "gpt-4o"
    },
    "q_2": {
        "title": "301 (a) & (b)",
        "dependencies": null,
        "selected_model": "gpt-4o"
    },
    "q_3": {
        "title": "301 (c)-(g)",
        "dependencies": ["q_2"],
        "selected_model": "gpt-4o"
    },
    "q_4": {
        "title": "301 (h)",
        "dependencies": null,
        "selected_model": "gpt-4o"
    },
    "q_5": {
        "title": "302",
        "dependencies": null,
        "selected_model": "gpt-4o"
    },

};
