<script>
    import { toast } from "@zerodevx/svelte-toast";
    import {
        Accordion,
        AccordionItem,
        Button,
        Hr,
        P,
        Select,
        Spinner
    } from "flowbite-svelte";
    import {
        CheckCircleOutline,
        CheckOutline,
        ClipboardCheckOutline,
        EditOutline,
        FileCopyOutline,
        FlagOutline,
        FloppyDiskOutline,
        PenOutline,
        RefreshOutline,
        StopOutline,
        TrashBinOutline
    } from "flowbite-svelte-icons";
    import { marked } from "marked";
    import { onMount } from "svelte";
    import { writable } from "svelte/store";
    import { v4 as uuidv4 } from "uuid";
    import { make_request } from "../../service/http_calls";
    import { getNestedDict, updateNestedDict } from "../../service/sse";
    import { global_state } from "../../service/store";
    import GenericAddInstructionsModal from "./GenericAddInstructionsModal.svelte";
    import GenericContentViewModal from "./GenericContentViewModal.svelte";
    import GenericParagraphRegenerate from "./GenericParagraphRegenerate.svelte";

    export let title;
    export let component_address;
    export let module_name;

    export let dependent_components = [];
    export let must_use_files = true;
    export let extra_context = writable("");
    export let custom_icon = null;
    export let override_selected_model = null;
    export let suppress_notification = true;

    export let hide_model_selector = false;
    export let disable_paragraph_regenerate = false;
    export let include_fact_check = true;
    export let include_source_attributions = true;
    export let collapsed = true;


    $: contextValue = $extra_context;

    let show_thinking = false;
    let thinkContent = "";
    let mainContent = "";

    let modal_regenerate_paragraph_open = writable(false);
    let modal_open = writable(false);
    let modal_injected_context_open = writable(false);
    let modal_source_attribution_open = writable(false);
    let modal_fact_checks_open = writable(false);
    let component_state = writable();

    let edit_mode = false;
    let edit_content = "";
    let textarea;
    let paragraph_to_regenerate = writable("");
    let models = [
        { name: "gpt-4o-mini", value: "gpt-4o-mini" },
        { name: "gpt-4o", value: "gpt-4o" },
        { name: "o1-mini", value: "o1-mini" },
        { name: "DeepSeek-R1", value: "deepseek-ai/DeepSeek-R1" },
        { name: "DeepSeek-V3", value: "deepseek-ai/DeepSeek-V3" },
        { name: "gemini-1.5-pro", value: "gemini-1.5-pro" },
        { name: "gemini-2.0-flash-exp", value: "gemini-2.0-flash-exp" },
        // { name: "Preplexity-Sonar-Pro", value: "sonar-pro" },
        { name: "Preplexity-Sonar", value: "sonar" },
        { name: "Preplexity-Sonar-Reasoning", value: "sonar-reasoning" },
    ];
    let autoScroll = true;
    let content_div;

    let dependencies_satisfied = writable(false); // TODO: check if all dependencies are satisfied
    let dep_context = writable("");

    let source_attribution_index = writable(0);

    global_state.subscribe((state) => {
        component_state.set(getNestedDict(state, component_address));

        if (dependent_components.length > 0) {
            const all_satisfied = dependent_components.every((dep) => {
                const dep_completed_address = dep["completed_address"];
                let dep_completed = getNestedDict(state, dep_completed_address);
                return dep_completed == "true";
            });
            dependencies_satisfied.set(all_satisfied);
            if (all_satisfied) {
                const dep_content = dependent_components
                    .map((dep, index) => {
                        const dep_content_address = dep["content_address"];
                        let dep_content = getNestedDict(
                            state,
                            dep_content_address,
                        );
                        return `**Context ${index + 1}**\n\n${dep_content} \n\n---\n\n`;
                    })
                    .join("\n");
                dep_context.set(dep_content);
            }
        } else {
            dependencies_satisfied.set(true);
        }
    });

    function handleScroll() {
        // Check if the user has scrolled up
        autoScroll =
            content_div.scrollHeight - content_div.scrollTop ===
            content_div.clientHeight;
    }

    function adjustHeight() {
        if (textarea) {
            textarea.style.height = "auto"; // Reset height
            textarea.style.height = `${textarea.scrollHeight}px`; // Set to content height
        }
    }

    function beforeUnloadHandler(event) {
        event.preventDefault();
        toast.push("You have unsaved changes. Please first save your changes.");
        event.returnValue = "";
    }
    export function handleExpand() {
        collapsed = true;
    }
    export function handleCollapse() {
        collapsed = false;
    }

    onMount(() => {
        requestAnimationFrame(adjustHeight); // Use requestAnimationFrame for better timing
    });

    export const handleRegenerate = async () => {
        console.log("Regenerate called for ", component_address.at(-1));

        if (!$dependencies_satisfied) {
            global_state.update((state) => {
                updateNestedDict(
                    state,
                    [...component_address, "data", "loading"],
                    "true",
                );
                updateNestedDict(
                    state,
                    [...component_address, "data", "status"],
                    "Waiting...",
                );
                return state;
            });
            let attempts = 0;
            const maxAttempts = 300;

            try {
                await new Promise((resolve, reject) => {
                    const checkDependencies = setInterval(() => {
                        attempts++;
                        if ($dependencies_satisfied) {
                            clearInterval(checkDependencies);
                            resolve();
                        } else if (attempts >= maxAttempts) {
                            clearInterval(checkDependencies);
                            toast.push(
                                "Dependencies not satisfied after 300 seconds. Please ensure dependent components are completed first.",
                                { classes: ["error"] },
                            );
                            reject(new Error("Dependencies timeout"));
                        }
                    }, 1000);
                });
            } catch (error) {
                return; // Exit the function if dependencies aren't satisfied
            }
        }
        global_state.update((state) => {
            updateNestedDict(
                state,
                [...component_address, "data", "loading"],
                "false",
            );
            updateNestedDict(
                state,
                [...component_address, "data", "status"],
                "Processing...",
            );
            updateNestedDict(
                state,
                [...component_address, "data", "citations"],
                null,
            );
            return state;
        });

        const request_id = uuidv4();
        global_state.update((state) => {
            updateNestedDict(state, [...component_address, "data"], {
                loading: "true",
                completed: "false",
                status: "Processing...",
                request_id: request_id,
            });
            return state;
        });
        const general_assistant_instructions =
            getNestedDict($global_state, [
                ...component_address.slice(0, -2),
                "toolbar",
                "instructions",
                "data",
                "prompt",
            ]) ?? "";

        await make_request(
            {
                type: "ask_llm",
                sub_type: "regenerate",
                request_id: request_id,
                payload: {
                    system_prompt_key: [...component_address, "system_prompt"],
                    assistant_prompt_key: [
                        ...component_address,
                        "assistant_prompt",
                    ],
                    custom_user_instructions:
                        $component_state?.instructions?.data?.prompt ?? "",
                    custom_assistant_instructions:
                        general_assistant_instructions,
                    dependent_context: $dep_context,
                    selected_model: $current_selected_model,
                    module: module_name,
                    title: component_address.at(-1),
                    must_use_files: must_use_files,
                    extra_context: contextValue,
                    suppress_notification: suppress_notification,
                    include_fact_check: include_fact_check,
                    include_source_attributions: include_source_attributions,
                },
            },
            component_address,
            {
                data: [...component_address, "data", "content"],
                loading: [...component_address, "data", "loading"],
                completed: [...component_address, "data", "completed"],
                source_attributions: [
                    ...component_address,
                    "data",
                    "source_attributions",
                ],
                fact_checks: [...component_address, "data", "fact_checks"],
                citations: [...component_address, "data", "citations"],
            },
        );
    };

    export function isCompleted() {
        return $component_state?.data?.completed == "true";
    }

    export function isLoading() {
        return $component_state?.data?.loading == "true";
    }

    export function getContent() {
        return $component_state?.data?.content ?? "No content";
    }

    export function getComponentKey() {
        return component_address.join("_");
    }

    export async function stopTask() {
        const current_request_id = getNestedDict($global_state, [
            ...component_address,
            "data",
            "request_id",
        ]);
        if (current_request_id) {
            const reqId = uuidv4();
            await make_request(
                {
                    type: "ask_llm",
                    sub_type: "stop_task",
                    request_id: reqId,
                    payload: {
                        request_id: current_request_id,
                    },
                },
                component_address,
                {
                    completed: [...component_address, "data", "completed"],
                    loading: [...component_address, "data", "loading"],
                },
            );
        }
    }

    export function handleClear(with_instructions = true) {
        global_state.update((state) => {
            updateNestedDict(state, [...component_address, "data"], {
                content: "No content",
                loading: "false",
                completed: "false",
                citations: null,
            });

            updateNestedDict(
                state,
                [...component_address, "data", "source_attributions"],
                null,
            );
            updateNestedDict(
                state,
                [...component_address, "data", "fact_checks"],
                null,
            );
            const current_source_attributions = getNestedDict(state, [
                ...component_address,
                "data",
                "source_attributions",
            ]);
  
            return state;
        });

        if (with_instructions) {
            global_state.update((state) => {
                updateNestedDict(
                    state,
                    [...component_address, "instructions", "data"],
                    {
                        prompt: "",
                    },
                );
                return state;
            });
        }
    }

    function showSourceAttribution(index) {
        source_attribution_index.set(index);
        modal_source_attribution_open.set(true);
    }

    // $: formattedContent = $component_state?.data?.content ?? "No content";
    $: {
        const content = $component_state?.data?.content ?? "No content";
        const openTag = "<think>";
        const closeTag = "</think>";
        const openIndex = content.indexOf(openTag);

        if (openIndex === -1) {
            // No <think> tag yet.
            thinkContent = "";
            mainContent = content;
        } else {
            const closeIndex = content.indexOf(closeTag, openIndex);
            if (closeIndex === -1) {
                // <think> opened but not closed: all text from <think> onward goes to collapsible.
                mainContent = content.slice(0, openIndex);
                thinkContent = content.slice(openIndex + openTag.length);
            } else {
                // Complete <think> block found.
                thinkContent = content
                    .slice(openIndex + openTag.length, closeIndex)
                    .trim();
                mainContent = (
                    content.slice(0, openIndex) +
                    content.slice(closeIndex + closeTag.length)
                ).trim();
            }
        }
    }

    let current_selected_model = writable(
        override_selected_model ??
            $component_state?.data?.selected_model ??
            "gpt-4o-mini",
    );
    $: {
        global_state.update((state) => {
            updateNestedDict(
                state,
                [...component_address, "data", "selected_model"],
                $current_selected_model,
            );
            return state;
        });
    }

    export function changeSelectedModel(model) {
        console.log("changeSelectedModel", model);
        global_state.update((state) => {
            updateNestedDict(
                state,
                [...component_address, "data", "selected_model"],
                model,
            );
            return state;
        });
        current_selected_model.set(model);
    }
</script>

<AccordionItem open={collapsed}>
    <span slot="header" class="flex items-center justify-between w-full">
        <span class="flex items-center">
            {#if $component_state?.data?.completed == "true" && !custom_icon}
                <CheckOutline color="grey" class="mr-2"></CheckOutline>
            {/if}
            {#if custom_icon}
                {#if custom_icon == "warning"}
                    <FlagOutline color="red" class="mr-2"></FlagOutline>
                {:else if custom_icon == "attention"}
                    <CheckCircleOutline  color="orange" class="mr-2"
                    ></CheckCircleOutline >
                {:else if custom_icon == "ok"}
                    <CheckCircleOutline  color="green" class="mr-2"
                    ></CheckCircleOutline >
                {:else if $component_state?.data?.completed == "true"}
                    <CheckOutline color="green"></CheckOutline>
                {/if}
            {/if}
            <span>{title}</span>
        </span>
    </span>

    {#if edit_mode}
        <textarea
            bind:this={textarea}
            bind:value={edit_content}
            class="w-full"
            style="height: auto; min-height: 100px; resize: none;"
        ></textarea>
    {:else}
        <div
            role="button"
            tabindex="0"
            class="group"
            class:apply-styles={!disable_paragraph_regenerate}
            on:click={(event) => {
                event.preventDefault();
                if (
                    ["p", "ul", "li", "strong"].includes(
                        event.target.tagName.toLowerCase(),
                    )
                ) {
                    if (event.target.tagName.toLowerCase() === "strong") {

                        const val = event.target.innerText;
                        if (/^\[\d+\]$/.test(val)) {
                            console.log(
                                "event.target",
                                event.target.tagName,
                                "value:",
                                event.target.innerText,
                            );
                            showSourceAttribution(parseInt(val.slice(1, -1)));
                            return;
                        }
                    }

                    // Check if clicked element is inside thought process section
                    let isInThoughtProcess = false;
                    let element = event.target;
                    while (element && !isInThoughtProcess) {
                        if (
                            element.classList?.contains(
                                "thought-process-section",
                            )
                        ) {
                            isInThoughtProcess = true;
                        }
                        element = element.parentElement;
                    }

                    if (!isInThoughtProcess) {
                        const paragraphContent = event.target.innerText;
                        paragraph_to_regenerate.set(
                            paragraphContent.replace(/\s*\[\d+\]$/, ""),
                        );
                        console.log(
                            "paragraph_to_regenerate",
                            paragraphContent,
                        );

                        // clear regenerated paragraph
                        global_state.update((state) => {
                            updateNestedDict(
                                state,
                                [
                                    ...component_address,
                                    "regenerate_paragraph",
                                    "data",
                                    "regenerated_paragraph",
                                ],
                                "",
                            );
                            return state;
                        });
                        console.log("paragraphContent", paragraphContent);

                        if (!disable_paragraph_regenerate) {
                            modal_regenerate_paragraph_open.set(true);
                        }
                    }
                } else {
                    if (
                        !event.target.innerText
                            .toLowerCase()
                            .includes("thought process") &&
                        event.target.tagName.toLowerCase() !== "div" 
                    ) {
                        showSourceAttribution(parseInt(event.target.innerText));
                    }
                }
            }}
            on:keydown={(event) => console.log(event)}
        >
            {#if thinkContent !== ""}
                <div
                    class="overflow-y-auto prose max-w-full text-justify mb-2 thought-process-section"
                >
                    <Accordion class="p-1">
                        <AccordionItem open={show_thinking}>
                            <span slot="header" class="text-sm font-bold"
                                >Thought Process</span
                            >
                            <div class="text-xs p-1">
                                {@html marked(thinkContent)}
                            </div>
                        </AccordionItem>
                    </Accordion>
                </div>
            {/if}

            <div
                bind:this={content_div}
                on:scroll={handleScroll}
                class="overflow-y-auto prose max-w-full text-justify"
            >
                {@html marked(mainContent)}
                {#if $component_state?.data?.citations}
                    <Hr class="my-5" />
                    <div class="mt-5">
                        <p class="font-bold">Citations</p>
                        {#each $component_state?.data?.citations.citations as citation, index}
                            <div class="break-words">
                                <p
                                    class="flex items-center font-bold mr-4 overflow-hidden"
                                >
                                    <span class="flex-shrink-0 mr-1"
                                        >{index + 1}.</span
                                    >
                                    <a
                                        href={citation}
                                        target="_blank"
                                        class="overflow-hidden text-ellipsis whitespace-nowrap"
                                        >{citation}</a
                                    >
                                </p>
                            </div>
                        {/each}
                    </div>
                {/if}
            </div>
        </div>
    {/if}

    <div class="flex justify-between mt-5">
        <div class="flex justify-start">
            <Button
                title="Copy content to clipboard"
                on:click={() => {
                    let cleanedText = $component_state?.data?.content.replace(
                        /\[\d+\]/g,
                        "",
                    );
                    navigator.clipboard.writeText(cleanedText);
                    toast.push("Copied to clipboard!");
                }}
                class="mr-3 hover:bg-purple-200 rounded-full p-2 bg-transparent text-gray-500"
            >
                <FileCopyOutline></FileCopyOutline>
            </Button>
            <Button
                class="mr-3 hover:bg-purple-200 rounded-full p-2 bg-transparent"
                title="Clear content and instructions"
                on:click={() => {
                    handleClear(false);
                }}
            >
                <TrashBinOutline color="grey"></TrashBinOutline>
            </Button>
            <Button
                class="mr-3 hover:bg-purple-200 rounded-full p-2 bg-transparent"
                title="Edit content"
                on:click={(e) => {
                    if (edit_mode == false) {
                        edit_mode = true;
                        edit_content = $component_state?.data?.content;
                        requestAnimationFrame(adjustHeight);
                    } else {
                        const hasUnsavedChanges =
                            edit_content !== $component_state?.data?.content;
                        if (hasUnsavedChanges) {
                            window.addEventListener(
                                "beforeunload",
                                beforeUnloadHandler,
                            );
                            const confirm_exit = confirm(
                                "You have unsaved changes. Are you sure you want to exit edit mode?",
                            );
                            if (!confirm_exit) {
                                edit_mode = true; // Keep toggle checked
                                requestAnimationFrame(adjustHeight);
                                e.preventDefault();
                                return;
                            } else {
                                edit_mode = false;
                                window.removeEventListener(
                                    "beforeunload",
                                    beforeUnloadHandler,
                                );
                            }
                        } else {
                            edit_mode = false;
                        }
                    }
                }}
            >
                <EditOutline color="grey"></EditOutline>
            </Button>
            {#if edit_mode}
                <Button
                    class="mr-3 hover:bg-purple-200 rounded-full p-2 bg-transparent"
                    title="Save content"
                    on:click={() => {
                        edit_mode = false;
                        window.removeEventListener(
                            "beforeunload",
                            beforeUnloadHandler,
                        );
                        global_state.update((state) => {
                            updateNestedDict(
                                state,
                                [...component_address, "data"],
                                {
                                    content: edit_content,
                                },
                            );
                            return state;
                        });
                    }}
                >
                    <FloppyDiskOutline color="green"></FloppyDiskOutline>
                </Button>
            {/if}
        </div>

        <div class="flex justify-end">
            {#if !hide_model_selector}
                <Select
                    items={models}
                    bind:value={$current_selected_model}
                    class="mr-2"
                />
            {/if}

            {#if $component_state?.data?.fact_checks}
                <div class="relative inline-block mr-3">
                    <Button
                        size="xs"
                        color="alternative"
                        class="hover:bg-purple-200"
                        title="Fact Checks"
                        on:click={() => modal_fact_checks_open.set(true)}
                    >
                        <ClipboardCheckOutline
                            color={$component_state?.data?.fact_checks
                                ?.toLowerCase()
                                .includes("no hallucination")
                                ? "green"
                                : "red"}
                        ></ClipboardCheckOutline>
                    </Button>
                    <!-- {#if $component_state?.data?.fact_checks
                        ?.toLowerCase()
                        .includes("hallucination risk")}
                        <span
                            class="absolute top-0 right-0 transform translate-x-1/4 -translate-y-1/4 bg-red-500 text-white rounded-full w-4 h-4 flex items-center justify-center text-xs"
                        >
                            !
                        </span>
                    {/if} -->
                </div>
            {/if}

            <!-- {#if $component_state?.data?.completed == "true" && $dependencies_satisfied && dependent_components.length > 0 }
                <Button
                    size="xs"
                    color="alternative"
                    class="hover:bg-purple-200 mr-3"
                    title="Injected Context"
                    on:click={() => modal_injected_context_open.set(true)}
                >
                    <ArchiveOutline></ArchiveOutline>
                </Button>
            {/if} -->

            <div class="relative inline-block mr-3">
                <Button
                    size="xs"
                    color="alternative"
                    class="hover:bg-purple-200"
                    title="Edit instructions"
                    on:click={() => modal_open.set(true)}
                >
                    <PenOutline />
                </Button>
                {#if $component_state?.instructions?.data?.prompt}
                    <span
                        class="absolute top-0 right-0 transform translate-x-1/4 -translate-y-1/4 bg-purple-500 text-white rounded-full w-4 h-4 flex items-center justify-center text-xs"
                    >
                    </span>
                {/if}
            </div>

            {#if $component_state?.data?.loading == "true"}
                <Button
                    size="xs"
                    on:click={() => {
                        stopTask();
                        handleClear(false);
                    }}
                    color="alternative"
                    class="hover:bg-purple-200  mr-3"
                    title="Stop Processing"
                >
                    <StopOutline color="red"></StopOutline>
                </Button>
                <Spinner class="ml-2 mt-1"></Spinner>
                <P size="xs" class="ml-1 mt-3"
                    >{$component_state?.data?.status}</P
                >
            {:else}
                <Button
                    size="xs"
                    color="alternative"
                    class="hover:bg-purple-200"
                    on:click={handleRegenerate}
                    title={"Regenerate"}
                >
                    <RefreshOutline></RefreshOutline>
                </Button>
            {/if}
            {#if $component_state?.generating?.data?.status !== "done" && typeof $component_state?.generating?.data?.status !== "undefined"}
                <div class="ml-5 flex flex-row mt-1">
                    <small
                        >Status: <b
                            >{$component_state?.generating?.data?.status}</b
                        ></small
                    >
                    <Spinner size={5} class="ml-3"></Spinner>
                </div>
            {/if}
        </div>
    </div>
</AccordionItem>

<GenericAddInstructionsModal
    component_address={[...component_address, "instructions"]}
    bind:modal_open={$modal_open}
></GenericAddInstructionsModal>

<GenericParagraphRegenerate
    title="Regenerate Paragraph"
    component_address={[...component_address, "regenerate_paragraph"]}
    bind:modal_open={$modal_regenerate_paragraph_open}
    paragraph_to_regenerate={$paragraph_to_regenerate}
></GenericParagraphRegenerate>

<GenericContentViewModal
    title="Injected Context"
    content={$dep_context}
    bind:modal_open={$modal_injected_context_open}
></GenericContentViewModal>

<GenericContentViewModal
    title="Source Attribution"
    content={"... " +
        (($component_state?.data?.source_attributions ?? {})[
            $source_attribution_index
        ]?.original_text ?? "") +
        " ..."}
    bind:modal_open={$modal_source_attribution_open}
></GenericContentViewModal>

<GenericContentViewModal
    title="Fact Checks"
    content={$component_state?.data?.fact_checks ?? "No content"}
    bind:modal_open={$modal_fact_checks_open}
></GenericContentViewModal>

<svelte:head>
    {#if !disable_paragraph_regenerate}
        <style>
            div[role="button"] ol {
                list-style-type: decimal;
                margin-left: 2rem;
                margin-top: 1rem;
                margin-bottom: 1rem;
            }
            /* Paste ALL your other styles here WITHOUT :global */

            div[role="button"] ol {
                list-style-type: decimal;
                margin-left: 2rem;
                margin-top: 1rem;
                margin-bottom: 1rem;
            }

            div[role="button"] ul {
                list-style-type: disc;
                margin-left: 2rem;
                margin-top: 1rem;
                margin-bottom: 1rem;
            }

            div[role="button"] li {
                margin-bottom: 0.5rem;
            }

            div[role="button"] ul ul,
            div[role="button"] ol ol {
                margin-left: 1.5rem;
                margin-top: 0.5rem;
            }

            div[role="button"] p {
                margin-top: 1.5rem;
                margin-bottom: 1.5rem;
                line-height: 1.75;
            }

            div[role="button"] p:first-child {
                margin-top: 0;
            }

            div[role="button"] p:last-child {
                margin-bottom: 0;
            }

            div[role="button"]:not(.thought-process-section) ul:hover,
            div[role="button"]:not(.thought-process-section) li:hover,
            div[role="button"]:not(.thought-process-section) strong:hover,
            div[role="button"]:not(.thought-process-section) p:hover {
                text-decoration: underline purple dashed;
                text-decoration-thickness: 1px;
                cursor: pointer;
            }

            .thought-process-section *:hover {
                text-decoration: none !important;
                cursor: default !important;
            }
        </style>
    {/if}
</svelte:head>
