<script>
    import { Card, P, Hr, Span } from "flowbite-svelte";
    import { writable } from "svelte/store";
    import { global_state } from "../../service/store";
    import { getNestedDict } from "../../service/sse";
    import NewFilesTable from "../components/Files/NewFilesTable.svelte";
    import TopToolbar from "../components/TopToolbar.svelte";
    import CustomAccordion from "../components/CustomAccordion.svelte";
    import GenericAccordionItem from "../components/GenericAccordionItem.svelte";
    import { qualify_items } from "./store";
    import BottomToolbar from "../components/BottomToolbar.svelte";

    let component_address = ["qualify"];
    let component_state = writable(null);
    global_state.subscribe((state) => {
        component_state.set(getNestedDict(state, component_address));
    });

    let files_table_ref = null;
    let accordion_items_refs = {};
    function get_custom_icon(item_content) {
        if (item_content == null) {
            return null;
        }
        if (item_content.toLowerCase().includes("no improvement")) {
            return "ok";
        }
        if (item_content.toLowerCase().includes("optional improvement")) {
            return "attention";
        }
        if (item_content.toLowerCase().includes("improvement needed")) {
            return "warning";
        }
        if (item_content.toLowerCase().includes("improvement required")) {
            return "warning";
        }
        if (item_content.toLowerCase().includes("improvement suggested")) {
            return "warning";
        }
        return null;
    }
</script>

<div class="w-full flex flex-grow flex-col justify-center mb-40">
    <div class="justify-center flex mt-5">
        <Card size="xl">
            <NewFilesTable
                bind:this={files_table_ref}
                component_address={[...component_address, "files"]}
                module_name="qualify"
            ></NewFilesTable>

            <Hr classHr="my-8" />

            <div class="mb-10">
                <TopToolbar
                    component_address={[...component_address, "toolbar"]}
                    {accordion_items_refs}
                    {files_table_ref}
                ></TopToolbar>

                {#each Object.entries(qualify_items) as [parent_item_key, parent_item_val]}
                    <P size="lg" class="my-5"
                        ><Span gradient>{parent_item_val.title}</Span></P
                    >
                    <CustomAccordion>
                        {#each Object.entries(parent_item_val.items) as [item_key, item_val]}
                            <GenericAccordionItem
                                suppress_notification={true}
                                hide_model_selector={true}
                                disable_paragraph_regenerate={true}
                                include_fact_check={false}
                                include_source_attributions={false}
                                override_selected_model={item_val.selected_model}
                                module_name="qualify"
                                bind:this={accordion_items_refs[
                                    `${parent_item_key}-${item_key}`
                                ]}
                                title={item_val.title}
                                component_address={[
                                    ...component_address,
                                    parent_item_key,
                                    item_key,
                                ]}
                                must_use_files={item_val.use_files}
                                dependent_components={item_val.dependencies?.map(
                                    (dep) => {
                                        return {
                                            content_address: [
                                                ...component_address,
                                                ...dep,
                                                "data",
                                                "content",
                                            ],
                                            completed_address: [
                                                ...component_address,
                                                ...dep,
                                                "data",
                                                "completed",
                                            ],
                                        };
                                    },
                                )}
                                custom_icon={get_custom_icon(
                                    $component_state?.[parent_item_key]?.[
                                        item_key
                                    ]?.data?.content,
                                )}
                            ></GenericAccordionItem>
                        {/each}
                    </CustomAccordion>
                {/each}
            </div>
            <BottomToolbar
                template_type="qualify"
                download_file_name="Qualify"
                {accordion_items_refs}
                {files_table_ref}
                use_files={true}
                component_address={[...component_address, "bottom_toolbar"]}
            ></BottomToolbar>
            <div class="flex justify-center my-10">
                <P size="xs" class="text-gray-800"
                    >AI may make mistakes. Please review the output carefully.</P
                >
            </div>
        </Card>
    </div>
</div>
