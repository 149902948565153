<script>
    import { Modal } from "flowbite-svelte";
    import MarkdownIt from 'markdown-it';
    import sanitizer from 'markdown-it-sanitizer';

    const md = new MarkdownIt({
        html: false, // Disable HTML for better security
        linkify: true,
        typographer: true,
    }).use(sanitizer);

    export let modal_open;
    export let title;
    export let content;
</script>

<Modal title={title} bind:open={modal_open} autoclose size="lg">
    <div class="prose dark:prose-invert max-w-none w-full p-4 min-h-[200px] max-h-[80vh] overflow-y-auto flex text-justify">
        <div class="w-full">
            {@html md.render(content || "No content to show!")}
        </div>
    </div>
</Modal>

<style>
    :global(.prose p) {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        line-height: 1.75;
    }

    :global(.prose ol) {
        list-style-type: decimal;
        margin-left: 2rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    :global(.prose ul) {
        list-style-type: disc;
        margin-left: 2rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    :global(.prose li) {
        margin-bottom: 0.5rem;
    }

    :global(.prose ul ul),
    :global(.prose ol ol) {
        margin-left: 1.5rem;
        margin-top: 0.5rem;
    }

    :global(.prose p:first-child) {
        margin-top: 0;
    }

    :global(.prose p:last-child) {
        margin-bottom: 0;
    }
</style>