import { createLocalStorageStore } from "../../service/store";
import { derived } from "svelte/store";
export const review_baseline = createLocalStorageStore("review_baseline", "");
export const review_advance = createLocalStorageStore("review_advance", "");
export const review_uncertainty = createLocalStorageStore("review_uncertainty", "");
export const review_resolution = createLocalStorageStore("review_resolution", "");


const createDerivedContext = (stores) => derived(stores, (values) => values.join('\n\n'));

export const review_items = {
    "overall": {
        "title": "Overall",
        "items":{
            "coherence":{
                "title": "Coherence",
                "selected_model": "gpt-4o",
                "dependencies": null,
                "extra_context":createDerivedContext([
                    review_baseline,
                    review_advance,
                    review_uncertainty,
                    review_resolution
                ])

            },
            "risk_factors":{
                "title": "Risk Factors",
                "selected_model": "gpt-4o",
                "dependencies": null,
                "extra_context": createDerivedContext([
                    review_baseline,
                    review_advance,
                    review_uncertainty,
                    review_resolution
                ])
            },
                "uncertainty_check":{
                "title": "Uncertainty Check",
                "selected_model": "gpt-4o",
                "dependencies": null,
                "extra_context": createDerivedContext([
                    review_baseline,
                    review_advance,
                    review_uncertainty,
                    review_resolution
                ])
            },
            "competent_professionals":{
                "title": "Competent Professionals",
                "selected_model": "gpt-4o",
                "dependencies": null,
                "extra_context": createDerivedContext([
                    review_baseline,
                    review_advance,
                    review_uncertainty,
                    review_resolution
                ])
            }
        }
        
    },
    "baseline_research": {
        "title": "Baseline Research",
        "items":{
            "baseline_statements":{
                "title": "Baseline Statements",
                "selected_model": "gpt-4o",
                "dependencies": null,
                "extra_context": createDerivedContext([
                    review_baseline,
                    review_advance,
                    review_uncertainty,
                    review_resolution
                ])
            },
            "internet_search":{
                "title": "Internet Research",
                "selected_model": "sonar",
                "dependencies": [
                    "baseline_statements"
                ],
                "extra_context": null
            },
            "feedback":{
                "title": "Feedback",
                "selected_model": "gpt-4o",
                "extra_context": createDerivedContext([
                    review_baseline,
                    review_advance,
                    review_uncertainty,
                    review_resolution
                ]),
                "dependencies": [
                    "baseline_statements",
                    "internet_search"
                ]
            }
        }
    },
    "baseline_statements": {
        "title": "Baseline",
        "items":{
            "comprehensiveness":{
                "title": "Comprehensiveness",
                "selected_model": "gpt-4o",
                "dependencies": null,
                "extra_context": createDerivedContext([
                    review_baseline,
                    ])
            },
            "focus":{
                "title": "Focus",
                "selected_model": "gpt-4o",
                "dependencies": null,
                "extra_context": createDerivedContext([
                    review_baseline,
                ])
            },
            "phrasing":{
                "title": "Phrasing",
                "selected_model": "gpt-4o",
                "dependencies": null,   
                "extra_context": createDerivedContext([
                    review_baseline,
                ])
            },
            "grammar":{
                "title": "Grammar",
                "selected_model": "gpt-4o",
                "dependencies": null,
                "extra_context": createDerivedContext([
                    review_baseline,
                ])
            }
        }
    },
    "advance": {
        "title": "Advance",
        "items":{
            "comprehensiveness":{
                "title": "Comprehensiveness",
                "selected_model": "gpt-4o",
                "dependencies": null,
                "extra_context": createDerivedContext([
                    review_advance,
                ])
            },
            "focus":{
                "title": "Focus",
                "selected_model": "gpt-4o",
                "dependencies": null,
                "extra_context": createDerivedContext([
                    review_advance,
                ])
            },
            "phrasing":{
                "title": "Phrasing",
                "selected_model": "gpt-4o",
                "dependencies": null,
                "extra_context": createDerivedContext([
                    review_advance,
                ])
            },
            "guideline_references":{
                "title": "Guideline References",
                    "selected_model": "gpt-4o",
                "dependencies": null,
                "extra_context": createDerivedContext([
                    review_advance,
                ])
            },
            "grammar":{
                "title": "Grammar",
                "selected_model": "gpt-4o",
                "dependencies": null,
                "extra_context": createDerivedContext([
                    review_advance,
                ])
            }
        },

    },
    "uncertainty": {
        "title": "Uncertainty",
        "items":{
            "comprehensiveness":{
                "title": "Comprehensiveness",
                "selected_model": "gpt-4o",
                "dependencies": null,
                "extra_context": createDerivedContext([
                    review_uncertainty,
                ])
            },
            "focus":{
                "title": "Focus",
                "selected_model": "gpt-4o",
                "dependencies": null,
                "extra_context": createDerivedContext([
                    review_uncertainty,
                ])
            },
            "phrasing":{
                "title": "Phrasing",
                "selected_model": "gpt-4o",
                "dependencies": null,
                "extra_context": createDerivedContext([
                    review_uncertainty,
                ])
            },
            "guideline_references":{
                "title": "Guideline References",
                "selected_model": "gpt-4o",
                "dependencies": null,
                "extra_context": createDerivedContext([
                    review_uncertainty,
                ])
            },
            "grammar":{
                "title": "Grammar",
                "selected_model": "gpt-4o",
                "dependencies": null,
                "extra_context": createDerivedContext([
                    review_uncertainty,
                ])
            }
        }
    },
    "resolution": {
        "title": "Resolution",
        "items":{
            "comprehensiveness":{
                "title": "Comprehensiveness",
                "selected_model": "gpt-4o",
                "dependencies": null,
                "extra_context": createDerivedContext([
                    review_resolution,
                ])
            },
            "focus":{
                "title": "Focus",
                "selected_model": "gpt-4o",
                "dependencies": null,
                "extra_context": createDerivedContext([
                    review_resolution,
                ])
            },
            "phrasing":{
                "title": "Phrasing",
                "selected_model": "gpt-4o",
                "dependencies": null,
                "extra_context": createDerivedContext([
                    review_resolution,
                ])
            },
            "guideline_references":{
                "title": "Guideline References",
                    "selected_model": "gpt-4o",
                "dependencies": null,
                "extra_context": createDerivedContext([
                    review_resolution,
                ])
            },
            "grammar":{
                "title": "Grammar",
                "selected_model": "gpt-4o",
                "dependencies": null,
                "extra_context": createDerivedContext([
                    review_resolution,
                ])
            }
        }
    }
}
