<script>
    import SecureIcon from "./assets/secure.svg";
    import EthicalIcon from "./assets/ethical.svg";
    import { CheckOutline } from "flowbite-svelte-icons";
</script>

<section class="mx-10 md:mx-60">
    <h3 class="text-3xl font-bold text-center mb-10">Our Core Principles</h3>
    <div class="grid md:grid-cols-2 gap-x-20 gap-y-5">
        <div class="bg-white border rounded-3xl">
            <div class="flex justify-center mt-10">
                <div
                    class="flex bg-blue-50 w-24 h-24 justify-center rounded-full"
                >
                    <img src={EthicalIcon} alt="" class="w-12" />
                </div>
            </div>
            <h3 class="text-2xl font-bold text-center mt-5 text-gray-700">
                Ethical & Responsible Use of AI
            </h3>
            <div class="mx-10 mb-10 mt-5">
                <ul>
                    <li class="flex mb-2">
                        <CheckOutline color="green" class="mt-1 mr-1" />
                        AI does not add additional information
                    </li>
                    <li class="flex mb-2">
                        <CheckOutline color="green" class="mt-1 mr-1" />
                        AI citations provided
                    </li>
                    <li class="flex mb-2">
                        <CheckOutline color="green" class="mt-1 mr-1" />
                        Hallucination checked
                    </li>
                    <li class="flex">
                        <CheckOutline color="green" class="mt-1 mr-1" />
                        Human oversight emphasised during onboarding
                    </li>
                </ul>
            </div>
        </div>
        <div class="bg-white border rounded-3xl">
            <div class="flex justify-center mt-10">
                <div
                    class="flex bg-blue-50 w-24 h-24 justify-center rounded-full"
                >
                    <img src={SecureIcon} alt="" class="w-10" />
                </div>
            </div>
            <h3 class="text-2xl font-bold text-center mt-5 text-gray-700">
                Enterprise-Grade Data Security
            </h3>
            <div class="mx-10 mb-10 mt-5">
                <ul>
                    <li class="flex mb-2">
                        <CheckOutline color="green" class="mt-1 mr-1" />No
                        storage: Data erased after use
                    </li>
                    <li class="flex mb-2">
                        <CheckOutline color="green" class="mt-1 mr-1" />
                        SOC-2 & ISO-27001 compliant infrastructure (Microsoft Azure
                        & AWS)
                    </li>
                    <li class="flex mb-2">
                        <CheckOutline color="green" class="mt-1 mr-1" />
                        Encryption at rest & in transit
                    </li>
                    <li class="flex">
                        <CheckOutline color="green" class="mt-1 mr-1" />
                        Data never used for AI training
                    </li>
                </ul>
                <div class="text-center mt-10">
                    <div class="flex justify-center">
                        <iframe
                            class="w-32"
                            src="https://registry.blockmarktech.com/certificates/f5e87e6c-ace7-4ac9-a8bf-8c94bdaeb334/widget/?tooltip_position=bottom&theme=transparent"
                            style="border:none;height:128px;"
                        >
                        </iframe>
                    </div>
                    <span> Cyber Essentials Certified </span>
                </div>
            </div>
        </div>
    </div>
</section>
