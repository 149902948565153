<script>
    import { Modal, Button, Label, Textarea, Spinner } from "flowbite-svelte";
    import { marked } from "marked";
    import { writable } from "svelte/store";
    import { createLocalStorageStore, global_state, regenerated_paragraph } from "../../service/store";
    import { getNestedDict, updateNestedDict } from "../../service/sse";
    import { make_request } from "../../service/http_calls";
    import { v4 as uuidv4 } from "uuid";
    import { onMount } from "svelte";
    import { TrashBinOutline } from "flowbite-svelte-icons";

    export let component_address;
    export let title = "Regenerate Paragraph";
    export let modal_open = false;
    export let paragraph_to_regenerate = "";

    let component_state = writable();
    global_state.subscribe((state) => {
        const updated_state = getNestedDict(state, component_address);
        if (updated_state) {
            component_state.set(updated_state);
        } else {
            component_state.set({
                data: {
                    loading: "false",
                    regenerated_paragraph: "",
                },
            });
        }
    });

    let user_instructions_to_regenerate_paragraph = createLocalStorageStore("user_instructions_to_regenerate_paragraph", "");

    async function handleRegenerateParagraph() {
        const request_id = uuidv4();
        component_state.update((state) => {
            state.data.loading = "true";
            return state;
        });
        await make_request(
            {
                type: "ask_llm",
                sub_type: "regenerate",
                request_id: request_id,
                payload: {
                    system_prompt_key: [
                        "paragraph_regeneration",
                        "system_prompt",
                    ],
                    assistant_prompt_key: [
                        "paragraph_regeneration",
                        "assistant_prompt",
                    ],
                    custom_user_instructions:
                        $user_instructions_to_regenerate_paragraph +
                        "\n\nParagraph to regenerate:\n" +
                        paragraph_to_regenerate,
                    selected_model: "gpt-4o",
                    module: component_address.at(-4),
                    temperature: 0.1,
                    must_use_files: false,
                    include_source_attributions: false,
                    include_fact_check: false,
                },
            },
            component_address,
            {
                data: [...component_address, "data", "regenerated_paragraph"],
                completed: [...component_address, "data", "completed"],
                loading: [...component_address, "data", "loading"],
            },
        );
    }
</script>

<Modal {title} bind:open={modal_open}>
    <div class="max-w-full prose">
        {@html marked(paragraph_to_regenerate)}
    </div>

    <Label>Instructions</Label>
    <Textarea bind:value={$user_instructions_to_regenerate_paragraph}
    ></Textarea>

    {#if $component_state?.data?.regenerated_paragraph != ""}
        <Label>Regenerated Paragraph</Label>
        <div class="prose max-w-full">
            {@html marked($component_state?.data?.regenerated_paragraph ?? "")}
        </div>
    {/if}

    <svelte:fragment slot="footer">
        {#if $component_state?.data?.loading == "true"}
            <div class="flex justify-center">
                <Spinner></Spinner>
            </div>
        {:else}
            {#if $component_state?.data?.regenerated_paragraph != ""}
                <Button
                    style="background-color: #3853ED; color: white;"
                    on:click={() => {
                        
                        global_state.update((state) => {
                            const existing_content = getNestedDict(state, [
                                ...component_address.slice(0, -1),
                                "data",
                                "content",
                            ]);
                            const replaced_content = existing_content.replace(
                                paragraph_to_regenerate,
                                $component_state?.data?.regenerated_paragraph,
                            );
                            updateNestedDict(
                                state,
                                [
                                    ...component_address.slice(0, -1),
                                    "data",
                                    "content",
                                ],
                                replaced_content,
                            );
                            return state;
                        });
                        modal_open = false;
                    }}>Update Paragraph</Button
                >
            {/if}


            <Button
                color="purple"
                on:click={async () => {
                    await handleRegenerateParagraph();
                }}>Regenerate</Button
            >
            <Button
            class="mr-3 hover:bg-purple-200 rounded-full p-2 bg-transparent"
            title="Clear content and instructions"
            on:click={() => {
                console.log("clear");
                component_state.update((state) => {
                    state.data.loading = "false";
                    state.data.regenerated_paragraph = "";
                    return state;
                });
            }}
        >
            <TrashBinOutline color="grey"></TrashBinOutline>
        </Button>

        {/if}
    </svelte:fragment>
</Modal>
