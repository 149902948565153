<script>
    import NewFilesTable from "../components/Files/NewFilesTable.svelte";
    import { Hr } from "flowbite-svelte";
    import { P, Span } from "flowbite-svelte";
    import { Accordion } from "flowbite-svelte";
    import TopToolbar from "../components/TopToolbar.svelte";
    import GenericAccordionItem from "../components/GenericAccordionItem.svelte";
    import { research_with_files_items } from "./store";
    import BottomToolbar from "../components/BottomToolbar.svelte";
    let component_address = ["research", "with_files"];

    let accordion_items_refs = {};
    let files_table_ref = null;
</script>
<NewFilesTable
    component_address={[...component_address, "files"]}
    module_name="research"
    bind:this={files_table_ref}
></NewFilesTable>

<Hr classHr="my-8" />

<div class="mb-10">
    <TopToolbar 
        component_address={[...component_address, "toolbar"]}
        accordion_items_refs={accordion_items_refs}
        files_table_ref={files_table_ref}

    ></TopToolbar>

    <P size="lg" class="my-5"><Span gradient>Baseline Research</Span></P>
    <Accordion
        multiple
        activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
        inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
    >
        {#each Object.entries(research_with_files_items) as [research_item_key, research_item_val]}
            <GenericAccordionItem
                disable_paragraph_regenerate={true}
                include_fact_check={false}
                include_source_attributions={false}
                must_use_files={research_item_val.use_files}
                module_name="research"
                hide_model_selector={true}
                override_selected_model={research_item_val.selected_model}
                bind:this={accordion_items_refs[research_item_key]}
                title={research_item_val.title}
                component_address={[
                    ...component_address,
                    research_item_key,
                ]}
                dependent_components={research_item_val.dependent_components}
            ></GenericAccordionItem>
        {/each}
    </Accordion>
</div>

<BottomToolbar
    download_file_name="Research"
    template_type="research_upload"
    accordion_items_refs={accordion_items_refs}
    files_table_ref={files_table_ref}
    use_files={true}
    component_address={[...component_address, "bottom_toolbar"]}
></BottomToolbar>