import { writable } from "svelte/store";
import { createLocalStorageStore, global_state } from "../../service/store";

export const user_project_instructions = createLocalStorageStore('draft_reasoner_user_project_instructions', "");
export const user_core_activity_instructions = createLocalStorageStore('draft_reasoner_user_core_activity_instructions', "");
export const user_supporting_activity_instructions = createLocalStorageStore('draft_reasoner_user_supporting_activity_instructions', "");

export const component_state = writable(null);

global_state.subscribe(s => {
    component_state.set(s.draft);
});



export const questions_project = {
    "q_1": {
        "title": "Project Nname",
        "dependencies": null,
        "selected_model": "gpt-4o"
    },
    "q_2": {
        "title": "Project objective",
        "dependencies": null,
        "selected_model": "gpt-4o"
    },
};

export const questions_core_activity = {
    "q_1": {
        "title": "Activity name",
        "dependencies": null,
        "selected_model": "gpt-4o"
    },
    "q_2": {
        "title": "Activity description",
        "dependencies": null,
        "selected_model": "gpt-4o"
    },
    "q_3": {
        "title": "New knowledge",
        "dependencies": null,
        "selected_model": "gpt-4o"
    },
    "q_4": {
        "title": "Hypothesis",
        "dependencies": null,
        "selected_model": "gpt-4o"
    },
    "q_5": {
        "title": "Research",
        "dependencies": null,
        "selected_model": "gpt-4o"
    },
    "q_6": {
        "title": "Experiment",
        "dependencies": null,
        "selected_model": "gpt-4o"
    },
    "q_7": {
        "title": "Evaluation",
        "dependencies": ["q_5", "q_6"],
        "selected_model": "gpt-4o"
    },
    "q_8": {
        "title": "Conclusion",
        "dependencies": ["q_5", "q_6", "q_7"],
        "selected_model": "gpt-4o"
    },
};

export const questions_supporting_activity = {
    "q_1": {
        "title": "Activity name",
        "dependencies": null,
        "selected_model": "gpt-4o"
    },
    "q_2": {
        "title": "Activity description",
        "dependencies": null,
        "selected_model": "gpt-4o"
    },
    "q_3": {
        "title": "Supporting core activity",
        "dependencies": null,
        "selected_model": "gpt-4o"
    },
};
