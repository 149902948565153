<script>
    import { Modal, Textarea, Button } from "flowbite-svelte";
    import { global_state } from "../../service/store";
    import { getNestedDict, updateNestedDict } from "../../service/sse";
  
    export let component_address;
    export let modal_open = false;
  
    let localPrompt = "";
    let loaded = false;
  
    // Load the prompt only once when modal opens.
    $: if (modal_open && !loaded) {
      const comp = getNestedDict($global_state, component_address) ?? { data: { prompt: "" } };
      localPrompt = comp.data.prompt;
      loaded = true;
    }
    // Reset the flag when modal closes.
    $: if (!modal_open && loaded) {
      loaded = false;
    }
  
    function saveChanges() {
      global_state.update(state => {
        updateNestedDict(state, component_address, { data: { prompt: localPrompt } });
        return state;
      });
      modal_open = false;
    }
  </script>
  
  <Modal bind:open={modal_open} title="Add Instructions">
    <Textarea rows="5" bind:value={localPrompt} />
    <Button on:click={saveChanges} color="purple">Save</Button>
  </Modal>
  