<script>
    import { toast } from "@zerodevx/svelte-toast";
    import {
        Button,
        Input,
        Label,
        Modal,
        P,
        Spinner,
        Toggle,
    } from "flowbite-svelte";
    import { FileCopyOutline } from "flowbite-svelte-icons";
    import { onMount } from "svelte";
    import {
        create_client_credentials,
        getUser,
        logout,
    } from "../../service/http_calls";
    import {
        authenticated_user,
        current_user,
        streaming_type,
    } from "../../service/store";
    import Usage from "./Usage.svelte";
    export let credit = 0; // Example credit value, you can replace it with dynamic data
    let client_id = "asdasdas213123";
    let client_secret = "klasjljl32j4lkjldsanm";

    async function handleLogout() {
        localStorage.clear();
        sessionStorage.clear();
        const resp = await logout();
        console.log("resp", resp);
        toast.push("Logged out!");
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = "/login";
    }
    onMount(async () => {
        credit = (await getUser()).credit;
    });

    let loading_vs = false;
    let modal_vs_files_open = false;
    let vs_files = {};
    async function handleViewVectoreStores() {
        loading_vs = true;
        loading_vs = false;
        modal_vs_files_open = true;
    }

    let deleting_vs = false;
    let modal_credentials_open = false;

    let organization_admin = false;
    let organization_users = [];
    onMount(async () => {
        // let admin_user = await get_organization_admin();
        // organization_admin = admin_user?.id === $authenticated_user.id;
        // if (organization_admin) {
        //     organization_users = await get_organization_users();
        // }
    });

    let loading_generate_api_key = false;
    async function handleGenerateAPIKey() {
        loading_generate_api_key = true;
        let res = await create_client_credentials();
        console.log("res", res);
        client_id = res.client_id;
        client_secret = res.client_secret;
        modal_credentials_open = true;
        loading_generate_api_key = false;
    }
    $: use_sse = $streaming_type === "sse";
</script>

<div
    class="flex items-center justify-center min-h-screen bg-gradient-to-r from-blue-50 to-blue-100 pb-32"
>
    <div
        class="max-w-4xl w-full p-8 bg-white shadow-xl rounded-lg transform transition duration-500 hover:scale-105"
    >
        <P Heading class="text-center text-2xl font-bold text-gray-800 mb-6"
            >My Account</P
        >

        <!-- User Info Table -->
        <div class="overflow-hidden rounded-lg">
            <table class="min-w-full table-auto bg-gray-100 rounded-lg">
                <tbody class="text-gray-600 text-sm font-medium">
                    <tr class="border-b border-gray-200">
                        <td class="px-6 py-4 font-semibold text-gray-700"
                            >Email:</td
                        >
                        <td class="px-6 py-4">{$authenticated_user.email}</td>
                    </tr>
                    <tr class="border-b border-gray-200">
                        <td class="px-6 py-4 font-semibold text-gray-700"
                            >Name:</td
                        >
                        <td class="px-6 py-4"
                            >{$authenticated_user.full_name}</td
                        >
                    </tr>
                    <tr>
                        <td class="px-6 py-4 font-semibold text-gray-700"
                            >API Credentials:</td
                        >
                        <td class="px-6 py-4">
                            {#if loading_generate_api_key}
                                <div class="flex justify-center">
                                    <Spinner></Spinner>
                                </div>
                            {:else}
                                <Button
                                    size="xs"
                                    color="alternative"
                                    on:click={() => {
                                        handleGenerateAPIKey();
                                    }}>Generate API Key</Button
                                >
                            {/if}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- <Usage /> -->

        <!-- Organization Users Table -->
        {#if organization_admin}
            <P class="text-gray-600 text-md font-semibold mt-10"
                >Organization Users</P
            >
            <div class="overflow-hidden rounded-lg mt-6">
                <table class="min-w-full table-auto bg-gray-100 rounded-lg">
                    <thead>
                        <tr class="bg-gray-200">
                            <th class="px-6 py-4 font-semibold text-gray-700"
                                >Email</th
                            >
                            <th class="px-6 py-4 font-semibold text-gray-700"
                                >Name</th
                            >
                        </tr>
                    </thead>
                    <tbody class="text-gray-600 text-sm font-medium">
                        {#each organization_users as user}
                            <tr class="border-b border-gray-200">
                                <td class="px-6 py-4">{user.email}</td>
                                <td class="px-6 py-4">{user.full_name}</td>
                            </tr>
                        {/each}
                    </tbody>
                </table>
            </div>
        {/if}

        <!-- Logout Button -->
        <div class="flex flex-col justify-center mt-8">
            {#if $current_user?.user_type === "admin"}
                {#if loading_vs}
                    <div class="flex justify-center mb-10">
                        <Spinner></Spinner>
                    </div>
                {:else}
                    <Button
                        color="alternative"
                        class="mb-5 w-full"
                        on:click={() => {
                            handleViewVectoreStores();
                        }}>View Vector Stores</Button
                    >
                {/if}
            {/if}
            <Button
                color="alternative"
                class="mb-5 w-full"
                on:click={() => {
                    window.location.href = "/subscriptions";
                }}>View My Plan</Button
            >
            <Button color="red" class="w-full" on:click={handleLogout}
                >Logout</Button
            >
        </div>
    </div>
</div>

<Modal title="Vector Store Files" bind:open={modal_vs_files_open}>
    {#each Object.entries(vs_files) as [vs_id, fs]}
        <p>Vector Store ID: <b>{vs_id}</b></p>
        <ul>
            {#each fs as f}
                <li>{f.id} - <b>{f.filename}</b></li>
            {/each}
        </ul>
    {/each}
</Modal>

<Modal title="API Credentials" bind:open={modal_credentials_open}>
    <div class="mb-6">
        <p class="text-red-600 font-medium mb-4">
            Important: These credentials will only be shown once. Please save
            them in a secure location.
        </p>

        <div class="mb-4">
            <Label for="client_id" class="mb-2">Client ID</Label>
            <div class="flex items-center">
                <Input
                    id="client_id"
                    value={client_id}
                    readonly
                    class="flex-1 mr-2"
                />
                <Button
                    color="alternative"
                    size="sm"
                    on:click={() => {
                        navigator.clipboard.writeText(client_id);
                        toast.push("Client ID copied to clipboard");
                    }}
                >
                    <FileCopyOutline class="w-5 h-5" />
                </Button>
            </div>
        </div>

        <div>
            <Label for="client_secret" class="mb-2">Client Secret</Label>
            <div class="flex items-center">
                <Input
                    id="client_secret"
                    value={client_secret}
                    readonly
                    class="flex-1 mr-2"
                />
                <Button
                    color="alternative"
                    size="sm"
                    on:click={() => {
                        navigator.clipboard.writeText(client_secret);
                        toast.push("Client Secret copied to clipboard");
                    }}
                >
                    <FileCopyOutline class="w-5 h-5" />
                </Button>
            </div>
        </div>

        <div class="mt-4">
            <Label for="user_id" class="mb-2">User ID</Label>
            <div class="flex items-center">
                <Input
                    id="user_id"
                    value={$authenticated_user.id}
                    readonly
                    class="flex-1 mr-2"
                />
                <Button
                    color="alternative"
                    size="sm"
                    on:click={() => {
                        navigator.clipboard.writeText($authenticated_user.id);
                        toast.push("User ID copied to clipboard");
                    }}
                >
                    <FileCopyOutline class="w-5 h-5" />
                </Button>
            </div>
        </div>
    </div>
</Modal>
