<script lang="ts">
  import { toast } from "@zerodevx/svelte-toast";
  import { onMount } from "svelte";
  import { v4 as uuidv4 } from "uuid";
  import { make_request } from "../../../service/http_calls";
  import {
    deleteNestedDict,
    getNestedDict,
    updateNestedDict,
  } from "../../../service/sse";
  import { authenticated_user, global_state } from "../../../service/store";

  export let component_address;
  export let module_name;

  const files_component_address = [...component_address.slice(0, -1), "files"];
  let selectedFiles: File[] = [];

  function handleUpload(uploadUrl, file, request_id) {
    const original_file_name = file.name;
    console.log("handleUpload", uploadUrl, request_id);
    const xhr = new XMLHttpRequest();
    xhr.open("PUT", uploadUrl, true);
    xhr.setRequestHeader("Content-Type", "application/octet-stream");
    xhr.setRequestHeader("x-amz-meta-smartclaim-request-id", request_id); // Adding metadata
    xhr.setRequestHeader(
      "x-amz-meta-smartclaim-user-id",
      $authenticated_user.id,
    ); // Adding metadata

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percent = (event.loaded / event.total) * 100;
        console.log("percent", percent);
        global_state.update((state) => {
          updateNestedDict(
            state,
            [...files_component_address, request_id, "upload", "data"],
            {
              upload_percentage: Math.round(percent),
              status: "Uploading",
              upload_url: null,
            },
          );
          return state;
        });

        if (Math.round(percent) == 100) {
          console.log("%cUPLOAD DONE!", "color: #000000; font-weight: bold");
        }
      }
    };

    xhr.onload = async () => {
      if (xhr.status === 200) {
        console.log("Upload successful");
        global_state.update((state) => {
          updateNestedDict(
            state,
            [...files_component_address, request_id, "upload", "data"],
            {
              status: "Please wait...",
              upload_url: null,
              upload_percentage: null,
            },
          );
          return state;
        });

        await make_request(
          {
            type: "files",
            sub_type: "ingest",
            request_id: request_id,
            payload: {
              module: module_name,
              clean_file_name: getNestedDict($global_state, [
                ...files_component_address,
                request_id,
                "upload",
                "data",
                "clean_file_name",
              ]),
              original_file_name: original_file_name,
            },
          },
          files_component_address,
          {
            status: [
              ...files_component_address,
              request_id,
              "upload",
              "data",
              "status",
            ],
            data: [...files_component_address, request_id, "data"],
            embeddings_done: [
              ...files_component_address,
              request_id,
              "data",
              "has_embedding",
            ],
          },
        );
      } else {
        console.error("Upload failed", xhr.status, xhr.responseText);
      }
    };

    xhr.onerror = () => {
      console.error("Upload error");
    };

    xhr.send(file);
  }

  onMount(async () => {
    global_state.subscribe((state) => {
      let all_files = getNestedDict(state, files_component_address);

      for (let file_id in all_files) {
        if (file_id == "data") {
          continue;
        }
        let file = all_files[file_id];
        let upload_url = file.upload?.data?.upload_url;
        if (upload_url) {
          let file_handle = file.upload?.data?.file_handle;
          console.log("must upload file", file_id);
          global_state.update((state) => {
            updateNestedDict(
              state,
              [...files_component_address, file_id, "upload", "data"],
              {
                upload_url: null,
                status: "Uploading",
              },
            );
            return state;
          });
          handleUpload(upload_url, file_handle, file.upload?.data?.request_id);
        }
      }
    });
  });

  // Function to upload files one by one
  async function uploadFiles() {
    if (selectedFiles.length === 0) return;
    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      let request_id = uuidv4();

      try {
        global_state.update((state) => {
          updateNestedDict(state, [...files_component_address, request_id], {
            data: {
              original_file_name: file.name,
              content: "",
              request_id: request_id,
            },
            upload: {
              data: {
                upload_percentage: 0,
                request_id: request_id,
                file_handle: file,
                status: "Uploading",
              },
            },
          });
          return state;
        });
        const response = await make_request(
          {
            type: "files",
            sub_type: "upload_url",
            request_id: request_id,
            payload: {
              module: module_name,
              filename: file.name,
            },
          },
          files_component_address,
          {
            status: [
              ...files_component_address,
              request_id,
              "upload",
              "data",
              "status",
            ],
            upload_url: [
              ...files_component_address,
              request_id,
              "upload",
              "data",
              "upload_url",
            ],
            clean_file_name: [
              ...files_component_address,
              request_id,
              "upload",
              "data",
              "clean_file_name",
            ],
            data: [...files_component_address, request_id, "data"],
          },
        );
        console.log("response", response, "status", response.status);
      } catch (error: any) {
        const error_json = JSON.parse(error);
        console.log("error_json", error_json);
        if (error_json.detail.toLowerCase().includes("already uploaded")) {
          // remove the file from the global state
          global_state.update((state) => {
            deleteNestedDict(state, [...files_component_address, request_id]);
            return state;
          });
          console.log("file already uploaded, removing from global state");
        }
      }
    }
  }

  // Handle file selection
  const handleFileChange = async (event: Event) => {
    const target = event.target as HTMLInputElement;
    if (target.files) {
      selectedFiles = Array.from(target.files);
      // Reset upload states when new files are selected
      await uploadFiles();
    } else {
      selectedFiles = [];
    }
  };

  function handleInputChange(e: Event) {
    const target = e.target as HTMLInputElement;
    const files = target.files;
    if (files) {
      const validFiles = Array.from(files).filter((file) =>
        [".pdf", ".docx", ".mp4", ".mp3", ".png", ".jpg", ".jpeg"].some((ext) =>
          file.name.toLowerCase().endsWith(ext),
        ),
      );
      if (validFiles.length !== files.length) {
        toast.push(
          "Some files were rejected. Only PDF, Word, MP3 and MP4 files are allowed.",
        );
      }
      const event = new Event("change");
      Object.defineProperty(event, "target", {
        value: { files: validFiles },
      });
      handleFileChange(event);
    }
  }
</script>

<div class="rounded-md shadow-md">
  <div class="mb-4 p-5">
    <label class="block text-gray-700 text-sm font-bold mb-2" for="file">
      Select Files
    </label>
    <input
      type="file"
      id="file"
      accept=".pdf,.docx,.mp4,.mp3,.png,.jpg,.jpeg"
      multiple
      on:change={handleInputChange}
      class="block w-full text-sm text-gray-500
             file:mr-4 file:py-2 file:px-4
             file:rounded-full file:border-0
             file:text-sm file:font-semibold
             file:bg-blue-50 file:text-blue-700
             hover:file:bg-blue-100"
    />
    <p class="text-sm text-gray-500 mt-2">
      Supported file types: Word, PDF, MP3, MP4. Note: Images and tables
      embedded in Word documents are not processed (we're working on it).
    </p>
  </div>
</div>
