export const qualify_items = {
    "baseline_research": {
        "title": "Baseline Research",
        "items": {
            "baseline_statements": {
                "title": "Baseline Statements",
                "selected_model": "gpt-4o",
                "use_files": true,  
                "dependencies": []
            },
            "internet_search": {
                "title": "Internet Search",
                "selected_model": "sonar",
                "use_files": false,
                "dependencies": [
                    ["baseline_research", "baseline_statements"]
                ]
            },
            "feedback": {
                "title": "Feedback",
                "selected_model": "gpt-4o",
                "use_files": true,
                "dependencies": [
                    ["baseline_research", "baseline_statements"],
                    ["baseline_research", "internet_search"]
                ],
            }
        }
    },
    "risk_factors": {
        "title": "Risk Factors",
        "items": {
            "risk_factors": {
                "title": "Risk Factors",
                "selected_model": "gpt-4o",
                "use_files": true,
                "dependencies": []
                            },
            "uncertainty_check":{
                "title": "Uncertainty Check",
                "selected_model": "gpt-4o",
                "use_files": true,
                "dependencies": []
            }
        }
    },
    "narrative_content_coverage": {
        "title": "Narrative Content Coverage",
        "items": {
            "baseline":{
                "title": "Baseline",
                "selected_model": "gpt-4o",
                "use_files": true,
                "dependencies": []
            },
            "advance":{
                "title": "Advance",
                "selected_model": "gpt-4o",
                "use_files": true,
                "dependencies": []
            },
            "uncertainty":{
                "title": "Uncertainty",
                "selected_model": "gpt-4o",
                "use_files": true,
                "dependencies": []
            },
            "resolution":{
                "title": "Resolution",
                "selected_model": "gpt-4o",
                "use_files": true,
                "dependencies": []
            }
        }
    },
    "questions_for_client": {
        "title": "Questions for Client",
        "items": {
            "research": {
                "title": "Research",
                "selected_model": "gpt-4o",
                "use_files": true,
                "dependencies": [
                    ["baseline_research", "baseline_statements"],
                    ["baseline_research", "internet_search"],
                    ["baseline_research", "feedback"],
                ],
            },
            "risk_factors": {
                "title": "Risk Factors",
                "selected_model": "gpt-4o",
                "use_files": true,
                "dependencies": [
                    ["risk_factors", "risk_factors"],
                    ["risk_factors", "uncertainty_check"],
                ],
            },
            "narrative_content_coverage": {
                "title": "Narrative Content Coverage",
                "selected_model": "gpt-4o",
                "use_files": true,
                "dependencies": [
                    ["narrative_content_coverage", "baseline"],
                    ["narrative_content_coverage", "advance"],
                    ["narrative_content_coverage", "uncertainty"],
                    ["narrative_content_coverage", "resolution"],
                ],
            }
        }
    }
}
