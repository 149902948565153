<script>
  import ProfilePictureShilin from "./assets/shilin.png";
  import ProfilePictureAmin from "./assets/amin.jpeg";
  import { EnvelopeOutline } from "flowbite-svelte-icons";
</script>

<div class="mx-10 md:mx-80">
  <div class="text-center">
    <h2 class="text-4xl font-bold">About Us</h2>
    <div class="md:grid grid-cols-2 mt-10 md:text-left gap-x-12 text-center">
      <div>
        <div class="flex justify-center">
          <img
            src={ProfilePictureShilin}
            alt="Shilin"
            class="w-48 rounded-full border-1 border-gray-300 shadow-lg"
          />
        </div>
        <h3 class="text-xl font-bold mt-5">Shilin Chen</h3>
        <h4 class="text-lg text-blue-500">Co-founder & CEO</h4>
        <p class="text-gray-500 mt-4">
          Shilin's journey with AI began during her time as a management
          consultant at McKinsey & Co, where she worked with HMRC on AI product
          and strategy, including using AI to review R&D tax relief claims. This
          experience showed her how AI could transform this field, inspiring her
          to bring the same cutting-edge technology to R&D consultants.
        </p>
        <p class="text-gray-500 mt-4">
          Shilin has a background in Philosophy from the University of
          Cambridge. Outside of work, Shilin enjoys reading, cooking,
          yoga, and unwinding with video games.
        </p>
        <div class="mt-5 flex justify-center md:justify-start">
          <a href="mailto:shilin@smartclaim.uk" class="text-blue-500 flex"
            ><EnvelopeOutline
              class="mt-0.5 mr-1 text-blue-500"
              size="md"
            /><span>shilin@smartclaim.uk</span></a
          >
        </div>
      </div>
      <div class="mt-28 md:mt-0">
        <div class="flex justify-center">
          <img
            src={ProfilePictureAmin}
            alt="Shilin"
            class="w-48 rounded-full border-1 border-gray-300 shadow-lg"
          />
        </div>
        <h3 class="text-xl font-bold mt-5">Amin Dorostanian</h3>
        <h4 class="text-lg text-blue-500">Co-founder & CTO</h4>
        <p class="text-gray-500 mt-4">
          Amin has spent over 15 years at the forefront of AI and software
          engineering, building innovative solutions for some of the world's
          leading organizations, including BMW, ABN AMRO Bank, and major
          cryptocurrency exchanges. As a hands-on technologist, Amin combines
          deep technical expertise with a pragmatic approach. He has a passion for continuous learning, and his fasincation for Artificial General Intelligence (AGI) research and philosophical queries is well-known among his close associates.
        </p>
        <p class="text-gray-500 mt-4">
          In his free time, Amin cherishes moments with his family, especially his newborn daughter, Skyler.
        </p>
        <div class="mt-5 flex justify-center md:justify-start">
          <a href="mailto:amin@smartclaim.uk" class="text-blue-500 flex"
            ><EnvelopeOutline
              class="mt-0.5 mr-1 text-blue-500"
              size="md"
            /><span>amin@smartclaim.uk</span></a
          >
        </div>
      </div>
    </div>
  </div>
</div>

<style>
</style>
