<script>
    import FlashIcon from "./assets/flash.svg";
    import StarIcon from "./assets/star.svg";
    import BrainIcon from "./assets/brain.svg";
</script>

<div class="grid md:grid-cols-3 text-center gap-x-10 gap-y-10 px-10 md:px-80">
    <div class="grid gap-y-4">
        <div class="flex justify-center">
            <img src={FlashIcon} alt="" class="w-10 my-3" />
        </div>
        <div>
            <h3 class="text-lg mb-2">Simpler</h3>
            <p class="text-slate-600">
                Intuitive workflows designed for R&D consultants
            </p>
        </div>
    </div>
    <div class="grid gap-y-4">
        <div class="flex justify-center">
            <img src={StarIcon} alt="" class="w-10" />
        </div>
        <div>
            <h3 class="text-lg mb-2">Smoother</h3>
            <p class="text-slate-600">Seamless & fast, plug-and-play</p>
        </div>
    </div>
    <div class="grid gap-y-4">
        <div class="flex justify-center">
            <img src={BrainIcon} alt="" class="w-10 my-3" />
        </div>
        <div>
            <h3 class="text-lg mb-2">Smarter</h3>
            <p class="text-slate-600">
                Powered by cutting-edge AI, trained on DSIT & GfC3
            </p>
        </div>
    </div>
</div>

<style>
</style>
