
import { createLocalStorageStore } from "../../service/store";
import {  derived } from "svelte/store";

export const research_page_selected_tab = createLocalStorageStore("research_page_selected_tab", "upload");

export const ask_internet_text = createLocalStorageStore("ask_internet_text", "");
export const ask_general_text = createLocalStorageStore("ask_general_text", "");

const createDerivedContext = (stores) => derived(stores, (values) => values.join('\n\n'));

export const research_with_files_items = {
    "baseline_statements": {
        "title": "Baseline Statements",
        "selected_model": "gpt-4o",
        "dependencies": null,
        "use_files": true,
        "extra_context": null,
        "dependent_components":[]
    },
    "internet_search": {
        "title": "Internet Search",
        "selected_model": "sonar",
        "use_files": false,
        "dependent_components": [
            {
                "content_address": ["research", "with_files", "baseline_statements", "data", "content"],
                "completed_address": ["research", "with_files", "baseline_statements", "data", "completed"]
            }
        ],
        "extra_context": null
    }
}

export const research_ask_your_own_items = {
    "ask_internet": {
        "title": "Ask Internet",
        "selected_model": "sonar",
        "extra_context": createDerivedContext([
            ask_internet_text
        ])
    },
    "ask_general": {
        "title": "Ask General",
        "selected_model": "gpt-4o",
        "extra_context": createDerivedContext([
            ask_general_text
        ])
    }
}
