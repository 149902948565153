
import { createLocalStorageStore } from "../../service/store";

export const defend_page_selected_tab = createLocalStorageStore("defend_page_selected_tab", "freeform");

export const defend_freeform_question = createLocalStorageStore("defend_freeform_question", "");

export const defend_wmbc_items = {
    "advance": {
        "title": "Advance",
        "description": `
<P class="my-5 text-gray-700 dark:text-gray-300"
    >Please explain further the advance in science and technology that was
    sought in the project by answering the following questions:
</P>

<ul
    class="list-disc list-inside mb-5 space-y-2 text-gray-700 dark:text-gray-300"
>
    <li>
        Detail the start and end dates for the R&D project that you have claimed
        for within the period.
    </li>
    <li>
        Explain what the gap in technological knowledge or capability was, which
        necessitated the start of the R&D. This must be based on what was
        available and known in the field of science and technology, not the
        state of your company's knowledge or expertise on the subject.
    </li>
    <li>
        Explain what must be accomplished for the project to be considered the
        end of the R&D.
    </li>
    <li>
        Explain what scientific or technological advance is being sought or
        achieved during the development of the project. This must not be merely
        enhancing your ability or company's ability to achieve a commercial
        outcome, but rather an objective that seeks to advance the wider field
        of science and technology.
    </li>
    <li>
        Explain how the R&D project is new in the field of science and
        technology, or appreciably improves on an existing field of technology
        relative to what is available in the public domain. Appreciable
        improvement should be evaluated against the baseline of technology
        available within the wider field of science and technology.
    </li>
    <li>
        Explain why this was not readily deducible by a competent professional.
        Please provide the competent professional's statement for their
        reasoning regarding this assessment.
    </li>
</ul>

        `,
        "q_1_1": {
            "title": "Detail the start and end dates for each Research & Development project that you have claimed for within the period.",
            "outline": {
                "model": "gpt-4o",
            },
            "draft": {
                "model": "gpt-4o", 
            }
        },
        "q_1_2": {
            "title": "Explain what the gap in technological knowledge or capability was, which necessitated the start of the R&D. This must be based on what was available and known in the field of science and technology, not the state of your company's knowledge or expertise on the subject",
            "outline": {
                "model": "gpt-4o",
            },
            "draft": {
                "model": "gpt-4o",
            }
        },
        "q_1_3": {
            "title": "Explain what must be accomplished for the project to be considered the end of the R&D.",
            "outline": {
                "model": "gpt-4o",
            },
            "draft": {
                "model": "gpt-4o",
            }
        },
        "q_1_4": {
            "title": "Explain what scientific or technological advance is being sought or achieved during the development of the project. This must not be merely enhancing your ability or company's ability to achieve a commercial outcome, but rather an objective that seeks to advance the wider field of science and technology.",
            "outline": {
                "model": "gpt-4o",
            },
            "draft": {
                "model": "gpt-4o",
            }
        },
        "q_1_5": {
            "title": "Explain how each R&D project is new in the field of science and technology, or appreciably improves on an existing field of technology relative to what is available in the public domain. Appreciable improvement should be evaluated against the baseline of technology available within the wider field of science and technology.",
            "outline": {
                "model": "gpt-4o",
            },
            "draft": {
                "model": "gpt-4o",
            }
        },
        "q_1_6": {
            "title": "Explain why this was not readily deducible by a competent professional. Please provide the competent professional's statement for their reasoning regarding this assessment.",
            "outline": {
                "model": "gpt-4o",
            },
            "draft": {
                "model": "gpt-4o",
            }
        }
    },
    "baseline": {
        "title": "Baseline",
                "description": `
<P class="my-5 text-gray-700 dark:text-gray-300"
    >Please provide a valid baseline that was used for the project by answering
    the following questions:</P
>

<ul
    class="list-disc list-inside space-y-2 mb-5 text-gray-700 dark:text-gray-300"
>
    <li>
        Provide a baseline in science or technology. This must not be a baseline
        of your own abilities or knowledge as a company but what was present in
        the wider field of science and technology.
    </li>
    <li>Provide us with the research conducted to determine this baseline.</li>
    <li>
        Provide the comparative analysis of the baseline technologies available
        in the wider field and the advance sought.
    </li>
    <li>
        Provide any facts and figures that were used while making this analysis.
    </li>
</ul>

        `,
        "q_2_1": {
            "title": "Provide a baseline in science or technology. This must not be a baseline of your own abilities or knowledge as a company but what was present in the wider field of science and technology.",
            "outline": {
                "model": "gpt-4o",
            },
            "draft": {
                "model": "gpt-4o",
            }
        },
        "q_2_2": {
            "title": "Provide us with the research conducted to determine this baseline.",
            "outline": {
                "model": "gpt-4o",
            },
            "draft": {
                "model": "gpt-4o",
            }
        },
        "q_2_3": {
            "title": "Provide the comparative analysis of the baseline technologies available in the wider field and the advance sought.",
            "outline": {
                "model": "gpt-4o",
            },
            "draft": {
                "model": "gpt-4o",
            }
        },
        "q_2_4": {
            "title": "Provide any facts and figures that were used while making this analysis.",
            "outline": {
                "model": "gpt-4o",
            },
            "draft": {
                "model": "gpt-4o",
            }
        }
    },
    "section_3": {
        "title": "Uncertainty",
        "description": `

    <P class="my-5 text-gray-700 dark:text-gray-300"
        >Please explain what the scientific or technological uncertainties were by answering the following questions:</P
    >

    <ul
        class="list-disc list-inside space-y-2 mb-5 text-gray-700 dark:text-gray-300"
    >
        <li>Provide an explanation of the research conducted to establish these were uncertain in the field of technology. Please note just because your company does not know if something could be done, it does not make it an uncertainty in the wider field of science and technology.</li>
        <li>What were the current technological limitations that didn’t allow you to resolve these uncertainties in each project? Technological limitation must be assessed with the knowledge and technology available in the wider field and not against what the company can and cannot accomplish.</li>
        <li>Explain the current practices used in the wider field and why it fails to resolve the uncertainties faced.</li>
    </ul>

        `,
        "q_3_1": {
            "title": "Please explain what the scientific or technological uncertainties were.",
            "outline": {
                "model": "gpt-4o",
            },
            "draft": {
                "model": "gpt-4o",
            }
        },
        "q_3_2": {
            "title": "Provide an explanation of the research conducted to establish these were uncertain in the field of technology. Please note just because your company does not know if something could be done, it does not make it an uncertainty in the wider field of science and technology.",
            "outline": {
                "model": "gpt-4o",
            },
            "draft": {
                "model": "gpt-4o",
            }
        },
        "q_3_3": {
            "title": "What were the current technological limitations that didn’t allow you to resolve these uncertainties in each project? Technological limitation must be assessed with the knowledge and technology available in the wider field and not against what the company can and cannot accomplish.",
            "outline": {
                "model": "gpt-4o",
            },
            "draft": {
                "model": "gpt-4o",
            }
        },
        "q_3_4": {
            "title": "Explain the current practices used in the wider field and why it fails to resolve the uncertainties faced.",
            "outline": {
                "model": "gpt-4o",
            },
            "draft": {
                "model": "gpt-4o",
            }
        }
    },
    "section_4": {
        "title": "Resolution",
            "description": `
                <P class="my-5 text-gray-700 dark:text-gray-300"
                    >Please detail how and when the uncertainties were overcome by answering the following questions. Please consider paragraph 14 of BEIS guidelines when answering these questions:</P
                >

            <ul
                    class="list-disc list-inside space-y-2 mb-5 text-gray-700 dark:text-gray-300"
                >
                    <li>Explain if the methods employed to solve the uncertainties were not already known and established in the public domain.</li>
                    <li>Explain how resolving the uncertainties mentioned was not business as usual in the field of computer science and application development?</li>
                    <li>Provide a detailed timeline. This must include all stages from planning to deployment of the R&D outcome.</li>
                    <li>Provide details of what individuals were involved in at each stage and any related documents you hold that were produced throughout each stage.

</li>
                    <li>Explain how and when the uncertainties were identified.</li>
                    <li>Explain how and when the uncertainties were overcome at all stages from planning to deployment.</li>
                </ul>
        `,
        "q_4_1": {
            "title": "Explain if the methods employed to solve the uncertainties were not already known and established in the public domain.",
            "outline": {
                "model": "gpt-4o",
            },
            "draft": {
                "model": "gpt-4o",
            }
        },
        "q_4_2": {
            "title": "Explain how resolving the uncertainties mentioned was not business as usual in the field of computer science and application development?",
            "outline": {
                "model": "gpt-4o",
            },
            "draft": {
                "model": "gpt-4o",
            }
        },
        "q_4_3": {
            "title": "Provide a detailed timeline. This must include all stages from planning to deployment of the R&D outcome.",
            "outline": {
                "model": "gpt-4o",
            },
            "draft": {
                "model": "gpt-4o",
            }
        },
        "q_4_4": {
            "title": "Provide details of what individuals were involved in at each stage and any related documents you hold that were produced throughout each stage.",
            "outline": {
                "model": "gpt-4o",
            },
            "draft": {
                "model": "gpt-4o",
            }
        },
        "q_4_5": {
            "title": "Explain how and when the uncertainties were identified.",
            "outline": {
                "model": "gpt-4o",
            },
            "draft": {
                "model": "gpt-4o",
            }
        },
        "q_4_6": {
            "title": "Explain how and when the uncertainties were overcome at all stages from planning to deployment.",
            "outline": {
                "model": "gpt-4o",
            },
            "draft": {
                "model": "gpt-4o",
            }
        }
    },
    "section_5": {
        "title": "Competent Professionals",
        "description": `

    <P class="my-5 text-gray-700 dark:text-gray-300"
        >Please provide further details about the competent professional mentioned in your report by answering the following questions:</P
    >

<ul
        class="list-disc list-inside space-y-2 mb-5 text-gray-700 dark:text-gray-300"
    >
        <li>What specific experience does the competent professional have in the field of application and web development?</li>
        <li>Please provide information on the projects and activities that the competent professional has done in the past.</li>
        <li>Please provide any record of accomplishment in similar projects.</li>
        <li>Please provide information of the specific experience the competent professional has in this field or area?

</li>
    </ul>

        `,
        "q_5_1": {
            "title": "Competent Professionals",
            "outline": {
                "model": "gpt-4o",
            },
            "draft": {
                "model": "gpt-4o",
            }
        }
    }
}
