<script>
    import { fade } from "svelte/transition";
    import { LinkedinSolid } from "flowbite-svelte-icons";

    export let image = null;
    export let linkedin = "";
    export let name;
    export let position = "";
    export let company = "";
    export let company_image = null;
</script>

<div in:fade class="bg-white border rounded-2xl p-5">
    <div class="md:grid md:grid-cols-4">
        <div class="md:mt-5">
            <div class="flex justify-center mb-5">
                <img
                    src={image}
                    alt=""
                    class="rounded-full border-1 border-gray-300 shadow-lg w-40"
                />
            </div>
            <div class="col-span-1">
                <div class="flex justify-center">
                    <div class="flex text-xl font-bold">
                        {name} <span class="opacity-30 ml-1">•</span>
                        <a href={linkedin} class="text-blue-500 mt-1 ml-1">
                            <LinkedinSolid />
                        </a>
                    </div>
                </div>
                <span class="text-gray-500">
                    {position}
                </span>
            </div>
            {#if company_image}
                <div class="flex justify-center mt-5 mb-5">
                    <img src={company_image} alt="" class="w-16" />
                </div>
            {/if}
        </div>
        <div class="flex col-span-3 md:mt-20">
            <div class="quote content-start">”</div>
            <p class="text-start my-2 w-full">
                <slot />
            </p>
        </div>
    </div>
</div>

<style>
    .quote {
        font-size: xx-large;
        font-weight: bold;
        padding: 0 !important;
        margin-right: 10px;
        margin-left: 10px;
    }
</style>
