<script>
    import novelLogo from "./assets/novel.svg";
</script>

<div class="mx-10 md:mx-80 flex justify-center">
    <div class="">
        <h2 class="text-2xl font-bold mb-2">News</h2>
        <h3 class="text-4xl font-semibold text-blue-500">
            Novel & SmartClaim Partnership
        </h3>
        <p class="mt-2">
            We've partnered with Novel to bring AI technical narrative drafting
            directly into the Novel platform.
        </p>
        <img
            src={novelLogo}
            alt="Partnership"
            class="border rounded-xl mt-10"
        />
    </div>
</div>
