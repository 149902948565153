<script>
  import {
    Card,
    Button,
    Select,
    Textarea,
    Spinner,
    Accordion,
    AccordionItem,
  } from "flowbite-svelte";
  import { marked } from "marked";
  import { blur } from "svelte/transition";
  import { tick } from "svelte";
  import { toast } from "@zerodevx/svelte-toast";
  import NewFilesTable from "../components/Files/NewFilesTable.svelte";
  import { StopOutline, TrashBinOutline } from "flowbite-svelte-icons";
  import { writable } from "svelte/store";
  import { global_state } from "../../service/store";
  import {
    deleteNestedDict,
    getNestedDict,
    updateNestedDict,
  } from "../../service/sse";
  import { make_request } from "../../service/http_calls";
  import { v4 as uuidv4 } from "uuid";

  let models = [
    { name: "gpt-4o-mini", value: "gpt-4o-mini" },
    { name: "gpt-4o", value: "gpt-4o" },
    { name: "o1-mini", value: "o1-mini" },
    { name: "DeepSeek-R1", value: "deepseek-ai/DeepSeek-R1" },
    { name: "DeepSeek-V3", value: "deepseek-ai/DeepSeek-V3" },
    { name: "gemini-1.5-pro", value: "gemini-1.5-pro" },
    { name: "gemini-2.0-flash-exp", value: "gemini-2.0-flash-exp" },
    // { name: "Preplexity-Sonar-Pro", value: "sonar-pro" },
    { name: "Preplexity-Sonar", value: "sonar" },
    { name: "Preplexity-Sonar-Reasoning", value: "sonar-reasoning" },
  ];

  const welcome_message = {
    content: "Hi! How can I assist you today?",
    role: "assistant",
  };
  const component_address = ["chat"];
  let current_selected_model = writable(models[0].value);
  let message = writable("");
  let component_state = writable({ data: { messages: [welcome_message] } });
  let container;

  // Handle global state updates
  global_state.subscribe((state) => {
    const messages = getNestedDict(state, [
      ...component_address,
      "data",
      "messages",
    ]) || [welcome_message];
    const newState = {
      data: {
        messages: Array.isArray(messages) ? messages : Object.values(messages),
        attachment: getNestedDict(state, [
          ...component_address,
          "data",
          "attachment",
        ]),
        response:
          getNestedDict(state, [...component_address, "data", "response"]) ||
          "",
        loading:
          getNestedDict(state, [...component_address, "data", "loading"]) ||
          "false",
      },
    };
    component_state.set(newState);
    const new_message = getNestedDict(state, [
      ...component_address,
      "data",
      "new_message",
    ]);

    if (new_message) {
      updateNestedDict(
        state,
        [...component_address, "data", "messages"],
        [
          ...(Array.isArray(messages) ? messages : Object.values(messages)),
          {
            content: new_message,
            role: "assistant",
          },
        ],
      );
      component_state.update((state) => {
        state.data.messages = Array.isArray(state.data.messages)
          ? state.data.messages
          : Object.values(state.data.messages);
        state.data.messages.push({
          content: new_message,
          role: "assistant",
        });
        console.log("Updated messages", state.data.messages);
        return state;
      });
      deleteNestedDict(state, [...component_address, "data", "new_message"]);
    }
  });

  // Auto-scroll functionality
  function scrollToBottom() {
    if (container) {
      container.scrollTo({
        top: container.scrollHeight,
        behavior: "smooth",
      });
    }
  }

  $: if ($component_state?.data?.messages || $component_state?.data?.response) {
    tick().then(scrollToBottom);
  }

  // Clear chat history
  const handle_clear_history = async () => {
    console.log("clear history");
    global_state.update((state) => {
      deleteNestedDict(state, [...component_address, "data", "messages"]);
      updateNestedDict(state, [...component_address, "data", "response"], "");
      updateNestedDict(
        state,
        [...component_address, "data", "loading"],
        "false",
      );
      return state;
    });
    toast.push("Cleared history!");
  };

  // Send message handler
  const send_message_helper = async () => {
    if ($message.trim() === "" && !$component_state?.data?.attachment) return;

    // Add user message to state
    global_state.update((state) => {
      const current_messages =
        getNestedDict(state, [...component_address, "data", "messages"]) || [];
      const messages_array = Array.isArray(current_messages)
        ? current_messages
        : Object.values(current_messages);
      updateNestedDict(
        state,
        [...component_address, "data", "messages"],
        [...messages_array, { content: $message, role: "user" }],
      );
      updateNestedDict(state, [...component_address, "data", "response"], "");
      updateNestedDict(
        state,
        [...component_address, "data", "loading"],
        "true",
      );
      return state;
    });

    message.set("");

    const request_id = uuidv4();
    global_state.update((state) => {
      updateNestedDict(
        state,
        [...component_address, "data", "request_id"],
        request_id,
      );
      return state;
    });

    // Send request to backend
    await make_request(
      {
        type: "ask_llm",
        sub_type: "chat",
        request_id: request_id,
        payload: {
          messages: $component_state.data.messages,
          model: $current_selected_model,
        },
      },
      component_address,
      {
        completed: [...component_address, "data", "completed"],
        data: [...component_address, "data", "response"],
        new_message: [...component_address, "data", "new_message"],
        loading: [...component_address, "data", "loading"],
      },
    );
  };

  // Keyboard handler
  const handleKeyPress = async (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      await send_message_helper();
    }
  };

  // Stop task handler
  export async function stopTask() {
    const current_request_id = getNestedDict($global_state, [
      ...component_address,
      "data",
      "request_id",
    ]);
    if (current_request_id) {
      const reqId = uuidv4();
      await make_request(
        {
          type: "ask_llm",
          sub_type: "stop_task",
          request_id: reqId,
          payload: {
            request_id: current_request_id,
          },
        },
        component_address,
        {
          completed: [...component_address, "data", "completed"],
          loading: [...component_address, "data", "loading"],
        },
      );
    }
  }

  $: loading = $component_state?.data?.loading === "true";
  $: submit_disabled = $message.trim() === "";

  function processContent(content) {
    const parts = [];
    const thinkParts = content.match(/<think>([\s\S]*?)<\/think>/g);

    if (thinkParts) {
      thinkParts.forEach((part) => {
        const cleanPart = part.replace(/<\/?think>/g, ""); // Remove <think> tags
        // Further processing can be added here based on rules
        parts.push({ type: "think", content: cleanPart });
      });
    }

    // Add remaining non-think content
    const nonThinkContent = content
      .replace(/<think>[\s\S]*?<\/think>/g, "")
      .replace(/```markdown([\s\S]*)```/, "$1");
    if (nonThinkContent.trim()) {
      parts.push({ type: "normal", content: nonThinkContent });
    }

    return parts;
  }
</script>

<div class="justify-center flex mt-5 mb-20">
  <Card size="xl">
    <div class="mb-4 flex">
      <Select
        bind:value={$current_selected_model}
        items={models}
        class="w-min"
      />
    </div>
    <div
      class="bg-gray-50 rounded-md p-4 space-y-2 message-container"
      bind:this={container}
    >
      {#each $component_state.data.messages as message}
        <div
          transition:blur
          class="bubble {message.role === 'assistant'
            ? 'ai-message'
            : 'user-message'}"
        >
          {#each processContent(message.content) as part}
            {#if part.type === "normal"}
              <div class="markdown-content">
                {@html marked(part.content)}
              </div>
            {:else if part.type === "think"}
              <Accordion class="p-1 mt-2 mb-2">
                <AccordionItem open>
                  <span slot="header" class="text-sm font-bold"
                    >Thought Process</span
                  >
                  <div class="text-xs p-1">
                    {@html marked(part.content)}
                  </div>
                </AccordionItem>
              </Accordion>
            {/if}
          {/each}
        </div>
      {/each}

      <!-- {#each $component_state.data.messages as message}
        <div
          transition:blur
          class="bubble {message.role === 'assistant'
            ? 'ai-message'
            : 'user-message'}"
        >
          <div class="markdown-content">{message.content}</div>
        </div>
      {/each} -->

      {#if $component_state.data.loading === "true" && $component_state.data.response}
        <div class="bubble ai-message">
          <div class="markdown-content">
            {@html marked($component_state.data.response)}
          </div>
        </div>
      {/if}
    </div>

    <div class="mt-4 mr-3">
      <Textarea
        bind:value={$message}
        on:keydown={handleKeyPress}
        placeholder="Type a message..."
      ></Textarea>
      <div class="w-full justify-end flex gap-x-2 mt-3">
        <Button
          size="sm"
          color="alternative"
          class="hover:bg-purple-200 mr-2"
          on:click={handle_clear_history}
        >
          <TrashBinOutline />
        </Button>
        {#if loading}
          <Button
            size="xs"
            on:click={stopTask}
            color="alternative"
            class="hover:bg-purple-200 mr-3"
          >
            <StopOutline color="red" />
          </Button>
          <div class="flex items-center">
            <Spinner size={6} />
          </div>
        {:else}
          <Button
            color="purple"
            disabled={submit_disabled}
            on:click={send_message_helper}
          >
            Submit
          </Button>
        {/if}
      </div>
    </div>
    <NewFilesTable module_name="chat" component_address={["chat", "files"]} />
  </Card>
</div>

<style>
  .bubble {
    max-width: 80%;
    min-width: 20%;
    padding: 15px 20px;
    margin-bottom: 10px;
    border-radius: 30px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }

  /* Specific markdown element containment */
  .markdown-content {
    width: 100%;
    overflow: hidden;
  }

  .markdown-content > * {
    max-width: 100%;
    overflow-wrap: break-word;
  }

  /* Code blocks and preformatted text */
  .markdown-content pre {
    background: #f3f4f6;
    padding: 1rem;
    border-radius: 8px;
    margin: 0.5rem 0;
    white-space: pre-wrap;
    word-wrap: break-word;
    max-width: 100%;
    overflow-x: auto;
  }

  .markdown-content code {
    background: #f3f4f6;
    padding: 0.2em 0.4em;
    border-radius: 4px;
    word-break: break-word;
  }

  /* Tables */
  .markdown-content table {
    width: 100% !important;
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    border-collapse: collapse;
    margin: 1rem 0;
  }

  .markdown-content td,
  .markdown-content th {
    padding: 0.5rem;
    border: 1px solid #e5e7eb;
    word-break: break-word;
    white-space: normal;
  }

  /* Lists */
  .markdown-content ul,
  .markdown-content ol {
    padding-left: 1.5rem;
    margin: 0.5rem 0;
  }

  /* Images */
  .markdown-content img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0.5rem 0;
    border-radius: 8px;
  }

  /* Headers */
  .markdown-content h1,
  .markdown-content h2,
  .markdown-content h3 {
    margin: 0.8rem 0 0.4rem;
    line-height: 1.2;
  }

  /* Quotes */
  .markdown-content blockquote {
    border-left: 4px solid #e5e7eb;
    margin: 0.5rem 0;
    padding-left: 1rem;
    color: #4b5563;
  }

  .user-message {
    align-self: flex-end;
    background-color: #daebfe;
    color: #585e6d;
    border-bottom-right-radius: 0;
  }

  .ai-message {
    align-self: flex-start;
    background-color: #ffffff;
    border-bottom-left-radius: 0;
  }

  .message-container {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 50vh;
  }
</style>
