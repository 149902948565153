<script>
    import {
        Footer,
        FooterCopyright,
        FooterLink,
        FooterLinkGroup,
        P,
    } from "flowbite-svelte";
</script>

<Footer class="bg-slate-50 fixed bottom-0 left-0 right-0  mt-5">
    <div class="mt-3 justify-center flex">
        <span class="text-sm text-gray-500 mb-3">
            © 2025 <a href="/">SmartClaim</a> All Rights Reserved.
            <a href="https://smartclaim.uk/terms" class="underline"
                >Terms and Conditions</a
            >
        </span>
    </div>
</Footer>
