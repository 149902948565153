<script>
    import {
        Card,
        Hr,
        P,
        Span
    } from "flowbite-svelte";

    
    import CustomAccordion from "../components/CustomAccordion.svelte";
    import NewFilesTable from "../components/Files/NewFilesTable.svelte";
    import GenericAccordionItem from "../components/GenericAccordionItem.svelte";
    import TopToolbar from "../components/TopToolbar.svelte";
    import BottomToolbar from "../components/BottomToolbar.svelte";

    let component_address = ["notify", "project_description"];
    let accordion_items_refs = {};
    let files_table_ref = null;
</script>

<div class="w-full flex flex-grow flex-col justify-center mb-5">
    <div class="justify-center flex mt-5">
        <Card size="xl">
            <NewFilesTable
                bind:this={files_table_ref}
                module_name="notify"
                component_address={[...component_address, "files"]}
            ></NewFilesTable>
            <TopToolbar
                component_address={[...component_address, "toolbar"]}
                {accordion_items_refs}
                {files_table_ref}
            ></TopToolbar>
            <Hr classHr="my-8" />

            <P size="lg" class="my-5"
                ><Span gradient>Project Description</Span></P
            >
            <CustomAccordion>
                <GenericAccordionItem
                    suppress_notification={true}
                    hide_model_selector={true}
                    disable_paragraph_regenerate={true}
                    include_fact_check={false}
                    module_name="notify"
                    bind:this={accordion_items_refs["option_1"]}
                    title="Option 1: Freeform"
                    component_address={[...component_address, "option_1"]}
                    must_use_files={true}
                    dependent_components={[]}
                    override_selected_model={"gpt-4o"}
                ></GenericAccordionItem>

                <GenericAccordionItem
                    suppress_notification={true}
                    hide_model_selector={true}
                    disable_paragraph_regenerate={true}
                    include_fact_check={false}
                    module_name="notify"
                    bind:this={accordion_items_refs["option_2"]}
                    title="Option 2: Structured"
                    component_address={[...component_address, "option_2"]}
                    must_use_files={true}
                    dependent_components={[]}
                    override_selected_model={"gpt-4o"}
                ></GenericAccordionItem>

                <GenericAccordionItem
                    suppress_notification={true}
                    hide_model_selector={true}
                    disable_paragraph_regenerate={true}
                    include_fact_check={false}
                    module_name="notify"
                    bind:this={accordion_items_refs["option_3"]}
                    title="Option 3: Commercial"
                    component_address={[...component_address, "option_3"]}
                    must_use_files={true}
                    dependent_components={[]}
                    override_selected_model={"gpt-4o"}
                ></GenericAccordionItem>
            </CustomAccordion>

            <div class="flex justify-center my-10">
                <P size="xs" class="text-gray-800"
                    >AI may make mistakes. Please review the output carefully.</P
                >
            </div>
        </Card>
    </div>

</div>
<div >
    <BottomToolbar
        template_type="notify"
        download_file_name="Notify"
        {accordion_items_refs}
        {files_table_ref}
        use_files={true}
        component_address={[...component_address, "bottom_toolbar"]}
    ></BottomToolbar>
</div>