<script>
    import {
        Button,
        Navbar,
        NavBrand,
        NavHamburger,
        NavLi,
        NavUl,
        MegaMenu,
        Dropdown,
        DropdownItem,
    } from "flowbite-svelte";
    import {
        UserCircleSolid,
        ArrowRightOutline,
        ChevronDownOutline,
    } from "flowbite-svelte-icons";
    import { onMount } from "svelte";
    import { authenticated_user } from "../../service/store";
    import LogoIcon from "./assets/logo.png";

    let menu = [
        { name: "About us", href: "/about" },
        { name: "Blog", href: "/blog" },
        { name: "Contact us", href: "/contact" },
        { name: "Library", href: "/library" },
    ];

    let slug = "";
    onMount(async () => {
        // Extracting the slug from the URL
        const pathParts = window.location.pathname.split("/").filter(Boolean);
        if (pathParts.length > 0) {
            slug = pathParts[pathParts.length - 1];
        }
    });
</script>

<Navbar>
    <NavBrand href="/">
        <img src={LogoIcon} alt="logo" width="32px" class="mr-2 mb-1" />
        <span
            class="mb-1 self-center whitespace-nowrap text-xl font-semibold dark:text-white"
        >
            SmartClaim
        </span>
    </NavBrand>
    <NavHamburger />
    {#if $authenticated_user}
        <NavUl class="text-center" activeUrl={`/${slug}`}>
            <NavLi href="/qualify">Qualify</NavLi>
            <NavLi href="/draft">Draft</NavLi>
            <NavLi href="/review">Review</NavLi>

            <div class="">
                <button class="p-0 m-0">
                    <div class="flex">
                        More
                        <ChevronDownOutline
                            class="w-6 h-6 text-gray-400 dark:text-white"
                        />
                    </div>
                </button>
                <Dropdown>
                    <DropdownItem>
                        <NavLi href="/notify">
                            Notify <span class="text-purple-500">(beta)</span>
                        </NavLi>
                    </DropdownItem>
                    <DropdownItem>
                        <NavLi href="/script">Script <span class="text-purple-500">(beta)</span></NavLi>
                    </DropdownItem>
                    <DropdownItem>
                        <NavLi href="/research">Research</NavLi>
                    </DropdownItem>
                    <DropdownItem>
                        <NavLi href="/defend">Defend</NavLi>
                    </DropdownItem>
                    <DropdownItem>
                        <NavLi href="/chat">Chat</NavLi>
                    </DropdownItem>

                </Dropdown>
            </div>

            <div class="md:-my-5">
                <Button
                    color="none"
                    class="px-4 -mb-3"
                    on:click={() => {
                        window.location.href = "/profile";
                    }}
                >
                    <UserCircleSolid class="w-10 h-10 opacity-70"
                    ></UserCircleSolid>
                </Button>
            </div>
        </NavUl>
    {:else}
        <NavUl class="text-center">
            <NavLi href="#features">Features</NavLi>
            <NavLi href="#pricing">Pricing</NavLi>
            <NavLi href="#about-us">About Us</NavLi>
            <NavLi href="#contact">Contact</NavLi>
            <div class="md:-mt-3">
                <Button
                    color="light"
                    href="/early-access"
                    class="px-4 -mb-3"
                    on:click={(e) => {
                        e.preventDefault();
                        window.location.href = "/login";
                    }}
                >
                    Log in<ArrowRightOutline class="opacity-70 ml-2" />
                </Button>
            </div>
        </NavUl>
    {/if}
</Navbar>
