<script lang="ts">
    import { PaperPlaneOutline } from "flowbite-svelte-icons";
    import { Label, Input, Textarea, Button, Spinner } from "flowbite-svelte";
    import { send_email } from "../../service/http_calls";
    import { toast } from "@zerodevx/svelte-toast";

    let loading = false;
    let email = "";
    let subject = "";
    let message = "";
    async function handleSubmit() {
        loading = true;
        await send_email(email, subject, message);
        loading = false;
        toast.push("Thanks for contacting us! We will get back to you soon.");
    }
</script>

<div class="text-center">
    <h2 class="text-5xl font-bold">Contact Us</h2>
    <p class="text-gray-500 mt-4">
        Have questions or want to learn more? We'd love to hear from you.
    </p>
</div>
<div class="flex w-full justify-center mt-10">
    <div class="w-full mx-5 md:w-1/2 border rounded-xl p-10">
        <form class="space-y-6">
            <div>
                <Label for="email" class="block mb-2">Your email</Label>
                <Input
                    id="email"
                    name="email"
                    placeholder="name@email.com"
                    bind:value={email}
                    required
                />
            </div>
            <div>
                <Label for="subject" class="block mb-2">Subject</Label>
                <Input
                    id="subject"
                    name="subject"
                    placeholder="Let us know how we can help you"
                    bind:value={subject}
                    required
                />
            </div>
            <div>
                <Label for="message" class="block mb-2">Message</Label>
                <Textarea
                    class="h-36"
                    id="message"
                    name="message"
                    placeholder="Leave a comment..."
                    bind:value={message}
                />
            </div>
            {#if loading}
                <div class="flex justify-center">
                    <Spinner />
                </div>
            {:else}
                <Button on:click={handleSubmit} class="w-full">
                    Send<PaperPlaneOutline class="ml-2" />
                </Button>
            {/if}
        </form>
    </div>
</div>
