<script>
    import { Button, Hr, P, Span } from "flowbite-svelte";
    import {
        EyeOutline,
        FloppyDiskAltOutline,
        FloppyDiskAltSolid,
        TrashBinOutline,
    } from "flowbite-svelte-icons";
    import { writable, get } from "svelte/store";
    import { v4 as uuidv4 } from "uuid";
    import { make_request } from "../../service/http_calls";
    import { getNestedDict, updateNestedDict } from "../../service/sse";
    import {
        createLocalStorageStore,
        global_state,
        requests_component_lookup,
    } from "../../service/store";
    import GenericConfirmModal from "../components/GenericConfirmModal.svelte";
    import GenericContentViewModal from "../components/GenericContentViewModal.svelte";
    import { createEventDispatcher, onDestroy, tick } from "svelte";

    const delay_seconds = 30;
    let isRecording = false;
    let transcript = "";
    let last_transcript = createLocalStorageStore(
        "realtime_last_transcript",
        "",
    );

    let summary = "";
    let mediaStream;
    let audioContext;
    let processor;
    let modal_open = false;
    let content_view_modal_open = false;
    let last_router_call_time = 0;
    let projects = createLocalStorageStore("realtime_projects", null);

    const wsBaseUrl = import.meta.env.VITE_WS_BASE_URL;
    const dispatch = createEventDispatcher();

    function triggerCall(project_name) {
        // Notify parent to call function in B
        dispatch("callAnalysis", { project_name });
    }

    const unsubscribe = global_state.subscribe(async (state) => {
        transcript =
            getNestedDict(state, ["realtime", "transcription"]) ??
            "No transcript yet";

        const new_projects =
            getNestedDict(state, ["realtime", "router", "projects"]) ?? "";
        if (new_projects.trim() !== get(projects)?.trim()) {
            projects.set(new_projects);
            try {
                for (const project of JSON.parse(new_projects).projects) {
                    if (project.must_run_analysis) {
                        triggerCall(project.project_name);
                    }
                }
            } catch (error) {}
        }

        if (
            transcript.trim() !== get(last_transcript).trim() &&
            transcript.trim().length > 10
        ) {
            last_transcript.set(transcript);
            const current_time = Date.now();
            if (Date.now() - last_router_call_time < 10000) {
                return;
            }
            last_router_call_time = current_time;
            await get_router();
        }
    });

    onDestroy(() => {
        unsubscribe();
    });

    function resampleBuffer(buffer, originalSampleRate, targetSampleRate) {
        const channels = 1;
        const frameCount = Math.ceil(
            (buffer.length * targetSampleRate) / originalSampleRate,
        );
        const offlineCtx = new OfflineAudioContext(
            channels,
            frameCount,
            targetSampleRate,
        );
        const audioBuffer = offlineCtx.createBuffer(
            channels,
            buffer.length,
            originalSampleRate,
        );
        audioBuffer.copyToChannel(buffer, 0);
        const source = offlineCtx.createBufferSource();
        source.buffer = audioBuffer;
        source.connect(offlineCtx.destination);
        source.start();
        return offlineCtx.startRendering();
    }

    function startRecording() {
        isRecording = true;
        navigator.mediaDevices
            .getUserMedia({ audio: true })
            .then((stream) => {
                mediaStream = stream;
                audioContext = new AudioContext();
                const source = audioContext.createMediaStreamSource(stream);
                processor = audioContext.createScriptProcessor(4096, 1, 1);
                source.connect(processor);
                processor.connect(audioContext.destination);

                // Open a WebSocket connection to FastAPI
                const request_id = uuidv4();
                console.log("request_id", request_id);

                requests_component_lookup.update((state) => {
                    state[request_id] = {
                        request_origin_component_address: ["realtime"],
                        target_component_address: {
                            transcription: ["realtime", "transcription"],
                        },
                        status: "pending",
                    };
                    return state;
                });

                const socket = new WebSocket(
                    `${wsBaseUrl}/audio?request_id=${request_id}`,
                );
                socket.binaryType = "arraybuffer";

                processor.onaudioprocess = (e) => {
                    const audioData = e.inputBuffer.getChannelData(0);
                    // Resample from the current sample rate to 16000Hz
                    resampleBuffer(audioData, audioContext.sampleRate, 16000)
                        .then((resampledBuffer) => {
                            const int16Data = floatTo16BitPCM(
                                resampledBuffer.getChannelData(0),
                            );
                            if (socket.readyState === WebSocket.OPEN) {
                                socket.send(int16Data);
                            }
                        })
                        .catch(console.error);
                };
            })
            .catch(console.error);
    }

    function floatTo16BitPCM(float32Array) {
        const len = float32Array.length;
        const int16Array = new Int16Array(len);
        for (let i = 0; i < len; i++) {
            let s = Math.max(-1, Math.min(1, float32Array[i]));
            int16Array[i] = s < 0 ? s * 0x8000 : s * 0x7fff;
        }
        return int16Array.buffer;
    }

    function pauseRecording() {
        isRecording = false;

        if (mediaStream) {
            mediaStream.getTracks().forEach((track) => track.stop());
        }

        if (processor) {
            processor.disconnect();
        }

        if (audioContext) {
            audioContext.close();
        }
    }

    async function get_router() {
        const request_id = uuidv4();
        await make_request(
            {
                type: "ask_llm",
                sub_type: "regenerate",
                request_id: request_id,
                payload: {
                    system_prompt_key: ["realtime", "router", "system_prompt"],
                    assistant_prompt_key: [
                        "realtime",
                        "router",
                        "assistant_prompt",
                    ],
                    function_definition_key: ["realtime", "router", "function"],
                    selected_model: "gpt-4o",
                    module: "realtime",
                    extra_context: `Previous Transcript was : ${$last_transcript}\n\nThe new transcript is: ${transcript}\n\nThe projects previously detected were: ${$projects}`,
                    must_use_files: false,
                    include_source_attributions: false,
                    include_fact_check: false,
                },
            },
            ["realtime", "router"],
            {
                data: ["realtime", "router", "projects"],
            },
        );
    }

    

    let new_edited_transcript = transcript;
    function handleInput(e) {
        new_edited_transcript = e.target.innerHTML;
    }
</script>

<P size="lg" class="my-5 font-bold">
    <Span gradient>Meeting Transcription</Span>
</P>

<div class="flex flex-col items-center gap-4 my-8 h-full pb-32">
    <div class="flex items-center gap-4">
        <button
            class={`px-6 py-3 rounded-full ${isRecording ? "bg-red-600 hover:bg-red-700" : "bg-purple-600 hover:bg-purple-700"} text-white flex items-center gap-2 transition-colors`}
            on:click={() => {
                if (!isRecording) {
                    startRecording();
                } else {
                    pauseRecording();
                }
            }}
        >
            {#if isRecording}
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M10 9v6m4-6v6m7-3a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                </svg>
                Pause Recording
            {:else}
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
                    />
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                </svg>
                Start Recording
            {/if}
        </button>
    </div>
    {#if isRecording}
        <p class="text-sm text-gray-600">Recording in progress...</p>
    {/if}
    <div
        class="text-justify w-full overflow-y-auto h-full  p-4"
    >
        <p class="text-justify" contenteditable="true" on:input={handleInput}>
            {@html transcript}
        </p>
    </div>

    {#if transcript}
        <div class="flex items-center">
            <Button
                class="mr-3 hover:bg-purple-200 rounded-full p-2 bg-transparent"
                title="Clear content and instructions"
                on:click={() => {
                    modal_open = true;
                }}
            >
                <TrashBinOutline color="grey"></TrashBinOutline>
            </Button>
            {#if transcript !== new_edited_transcript}
                <Button
                    class="mr-3 hover:bg-purple-200 rounded-full p-2 bg-transparent"
                    title="Save transcript"
                    on:click={() => {
                        global_state.update((state) => {
                            updateNestedDict(
                                state,
                                ["realtime", "transcription"],
                                new_edited_transcript,
                            );
                            return state;
                        });
                    }}
                >
                    <FloppyDiskAltOutline color="grey"></FloppyDiskAltOutline>
                </Button>
            {/if}
        </div>
    {/if}
</div>

<GenericConfirmModal
    bind:modal_open
    has_delete_narratives={false}
    has_delete_instructions={false}
    use_files={false}
    title="Clear Transcription"
    message="Are you sure you want to clear the transcription? You will lose all your transcription data."
    on_confirm={() => {
        last_transcript.set("No transcript yet");
        global_state.update((state) => {
            updateNestedDict(
                state,
                ["realtime", "transcription"],
                "No transcript yet",
            );
            updateNestedDict(
                state,
                ["realtime", "router", "projects"],
                undefined,
            );
            updateNestedDict(
                state,
                ["realtime", "router", "projects"],
                undefined,
            );
            return state;
        });
        modal_open = false;
    }}
></GenericConfirmModal>

<GenericContentViewModal
    bind:modal_open={content_view_modal_open}
    title="Summary"
    content={summary}
></GenericContentViewModal>
