<script>
    import { Card, Tabs, TabItem, P } from "flowbite-svelte";
    import { defend_page_selected_tab } from "./store";
    import NewDefendWmbc from "./NewDefendWmbc.svelte";
    import NewDefendFreeform from "./NewDefendFreeform.svelte";

    $: isWmbcTabOpen = $defend_page_selected_tab === "wmbc";
    $: isFreeformTabOpen = $defend_page_selected_tab === "freeform";
</script>

<div class="w-full flex flex-grow flex-col justify-center mb-40">
    <div class="justify-center flex mt-5">
        <Card size="xl">
            <Tabs contentClass="bg-white">

                <TabItem
                    bind:open={isFreeformTabOpen}
                    title="Freeform"
                    on:click={() => {
                        defend_page_selected_tab.set("freeform");
                    }}
                >
                    <NewDefendFreeform></NewDefendFreeform>
                </TabItem>
                <TabItem
                bind:open={isWmbcTabOpen}
                title="WMBC"
                on:click={() => {
                    defend_page_selected_tab.set("wmbc");
                }}
            >
                    <NewDefendWmbc></NewDefendWmbc>
                </TabItem>
            </Tabs>

            <div class="flex justify-center my-10">
                <P size="xs" class="text-gray-800"
                    >AI may make mistakes. Please review the output carefully.</P
                >
            </div>
        </Card>
    </div>
</div>
