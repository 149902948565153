import { toast } from "@zerodevx/svelte-toast";
import { get, writable } from 'svelte/store';
import {
    createLocalStorageStore,
    global_state,
    requests_component_lookup
} from "./store";

let eventSource = null;
const SSE_BASE_URL = import.meta.env.VITE_BASE_URL;
export const connected = writable("false");

export function updateNestedDict(obj, keys, value) {
    if (keys == undefined) {
        console.error("keys is undefined for updateNestedDict", obj,);
        return;
    }
    let current = obj;
    for (let i = 0; i < keys.length - 1; i++) {
        if (!current[keys[i]]) {
            current[keys[i]] = {};
        }
        current = current[keys[i]];
    }
    if (typeof value === 'object' && value !== null) {
        current[keys[keys.length - 1]] = {
            ...current[keys[keys.length - 1]],
            ...value,
        };
    } else {
        current[keys[keys.length - 1]] = value;
    }
}

export function deleteNestedDict(obj, keys) {
    let current = obj;
    for (let i = 0; i < keys.length - 1; i++) {
        if (!current[keys[i]]) {
            return; // Key path doesn't exist, nothing to delete
        }
        current = current[keys[i]];
    }
    delete current[keys[keys.length - 1]];
}

export function getNestedDict(obj, keys) {
    // Base case: no more keys to process
    if (keys.length === 0) {
        return obj;
    }

    // Base case: current object is undefined
    if (obj === undefined) {
        return undefined;
    }

    // Recursive case: get first key and process remaining keys
    const [firstKey, ...remainingKeys] = keys;
    return getNestedDict(obj[firstKey], remainingKeys);
}

export function connectSSE() {
    if (eventSource) eventSource.close();

    if (!SSE_BASE_URL) {
        console.error("Missing SSE_BASE_URL");
        return;
    }

    let url = `${SSE_BASE_URL}/sse`;
    const sseUrl = new URL(url);

    eventSource = new EventSource(sseUrl, { withCredentials: true });

    eventSource.onopen = () => {
        console.log("SSE connected");
        connected.set("true");
    };

    eventSource.onmessage = async (event) => {
        const parsed = JSON.parse(event.data);
        await handleServerEvent(parsed);
    };

    eventSource.onerror = (event) => {
        console.error("SSE error:", event);
        handleSSEDisconnect();
    };
}


const handleSSEDisconnect = () => {
    connected.set("false");
    console.log("SSE connection closed");
    console.log("Reconnecting to updates...");
    setTimeout(() => {
        connectSSE();
    }, 3000);
};

const handleServerEvent = async (parsed) => {
    const request_id = parsed.request_id;
    if (!request_id) {
        if (parsed.type === "error") {
            toast.push(parsed.message, { classes: ["warn"] });
        }
        else if (parsed.type === "notification") {
            toast.push(JSON.stringify(parsed.message));
        }
        else if (parsed.type === "info") {
            console.log("%cinfo%c", "color: #2196F3; font-weight: bold", "color: #4CAF50", parsed.message);
            toast.push(parsed.message);
        }
        return;
    }

    let component_address = [];
    let target_component_address = [];
    try {
        component_address = get(requests_component_lookup)[request_id].request_origin_component_address;
        target_component_address = get(requests_component_lookup)[request_id].target_component_address;
    } catch (e) {
        console.error("request_id not found in requests_component_lookup", request_id);
        return;
    }


    const response_key = parsed.key;

    console.log(
        "%cRequest ID: %c%s\n%cComponent Address: %c%s\n%cTarget Component Address: %c%s\n%cServer Response: %c%s",
        "color: #1E88E5; font-weight: bold", "color: #43A047", parsed.request_id,
        "color: #1E88E5; font-weight: bold", "color: #43A047", JSON.stringify(component_address),
        "color: #1E88E5; font-weight: bold", "color: #43A047", JSON.stringify(target_component_address[response_key]),
        "color: #1E88E5; font-weight: bold", "color: #43A047", JSON.stringify(parsed)
    );
    if (parsed.type === "response") {
        if (parsed.sub_type === "update") {
            global_state.update(s => {
                if (target_component_address[response_key]) {
                    updateNestedDict(s, target_component_address[response_key], parsed.message);
                }
                return s;
            });

        } else if (parsed.sub_type === "delete") {
            console.log("delete from server", target_component_address[response_key]);
            global_state.update(s => {
                deleteNestedDict(s, target_component_address[response_key]);
                return s;
            });
        } else if (parsed.sub_type === "delta") {
            const existing_data = getNestedDict(get(global_state), [...target_component_address[response_key]]) ?? "";
            global_state.update(s => {
                if (target_component_address[response_key]) {
                    updateNestedDict(s, target_component_address[response_key], existing_data + parsed.message);
                }
                return s;
            });
        }
    }
    else if (parsed.type === "error") {
        toast.push(parsed.message, { classes: ["warn"] });
        if (parsed.message.includes("request id")) {
            console.log("request id  found", parsed);
            try {
                const request_id_match = parsed.message.match(/request id: `([^`]+)`/);
                if (request_id_match) {
                    await navigator.clipboard.writeText(request_id_match[1]);
                } else {
                    await navigator.clipboard.writeText(parsed.message);
                }
                toast.push("Copied to clipboard", { classes: ["success"] });
            } catch (err) {
                console.error('Failed to copy message to clipboard:', err);
                toast.push("Failed to copy message to clipboard", { classes: ["error"] });
            }
        } else {
            console.log("request id not found", parsed);
        }

    }
    else if (parsed.type === "info"){
        toast.push(parsed.message);
    }
    else {
        console.error("unknown event", parsed);
    }

};
