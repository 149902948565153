import { writable } from "svelte/store";
import { createLocalStorageStore, global_state } from "../../service/store";

export const user_general_instructions = createLocalStorageStore('draft_reasoner_user_general_instructions', "");

export const component_state = writable(null);

global_state.subscribe(s => {
    component_state.set(s.draft);
});



export const questions = {
    "q_1": {
        "title": "Project Name",
        "dependencies": null,
        "selected_model": "gpt-4o"
    },
    "q_2": {
        "title": "Main field of science or technology?",
        "dependencies": null,
        "selected_model": "gpt-4o"
    },
    "q_3": {
        "title": "Baseline level of science or technology?",
        "dependencies": null,
        "selected_model": "gpt-4o"
    },
    "q_4": {
        "title": "Advancement in knowledge it aimed to achieve?",
        "dependencies": null,
        "selected_model": "gpt-4o"
    },
    "q_5": {
        "title": "Scientific or technological uncertainties faced?",
        "dependencies": null,
        "selected_model": "gpt-4o"
    },
    "q_6": {
        "title": "How the project sought to overcome uncertainties?",
        "dependencies": ["q_5"],
        "selected_model": "gpt-4o"
    }

};
