<script>
    import { Accordion, Card, P, Span, Textarea } from "flowbite-svelte";
    import { onMount } from "svelte";
    import BottomToolbar from "../components/BottomToolbar.svelte";
    import GenericAccordionItem from "../components/GenericAccordionItem.svelte";
    import {
        review_advance,
        review_baseline,
        review_items,
        review_resolution,
        review_uncertainty,
    } from "./store";
    import { writable } from "svelte/store";
    import { global_state } from "../../service/store";
    import { getNestedDict } from "../../service/sse";
    import TopToolbar from "../components/TopToolbar.svelte";

    let component_address = ["review"];
    let accordion_items_refs = {};
    let submit_loading = writable(false);
    let component_state = writable(null);

    global_state.subscribe((state) => {
        component_state.set(getNestedDict(state, component_address));

        let all_items_statuses = Object.entries(
            getNestedDict(state, ["review"]) ?? {},
        )
            .flatMap(([parent_item_key, parent_item_val]) =>
                Object.entries(parent_item_val),
            )
            .filter(([item_key, item_val]) => {
                return item_key !== "data" && item_key !== "instructions";
            })
            .map(([item_key, item_val]) => {
                return item_val.data.loading;
            });
        if (all_items_statuses.includes("true")) {
            submit_loading.set(true);
        } else {
            submit_loading.set(false);
        }

        // .some(([item_key, item_val]) => item_val.data.loading === "true"),
    });

    onMount(async () => {});
    function get_custom_icon(item_content) {
        if (item_content == null) {
            return null;
        }
        if (item_content.toLowerCase().includes("no improvement")) {
            return "ok";
        }
        if (item_content.toLowerCase().includes("optional improvement")) {
            return "attention";
        }
        if (item_content.toLowerCase().includes("improvement needed")) {
            return "warning";
        }
        if (item_content.toLowerCase().includes("improvement required")) {
            return "warning";
        }
        if (item_content.toLowerCase().includes("improvement suggested")) {
            return "warning";
        }
        return null;
    }
</script>

<div class="w-full flex flex-grow flex-col justify-center mb-5">
    <div class="justify-center flex mt-5">
        <Card size="xl">
            <div class="mb-10">
                <P size="lg" class="my-5"
                    ><Span gradient>Baseline Statements</Span></P
                >
                <Textarea
                    bind:value={$review_baseline}
                    rows={10}
                    placeholder="Enter Technical Narrative Draft Here..."
                ></Textarea>
                <P size="lg" class="my-5"><Span gradient>Advance</Span></P>
                <Textarea bind:value={$review_advance} rows={10} placeholder=""
                ></Textarea>
                <P size="lg" class="my-5"><Span gradient>Uncertainty</Span></P>
                <Textarea
                    bind:value={$review_uncertainty}
                    rows={10}
                    placeholder=""
                ></Textarea>
                <P size="lg" class="my-5"><Span gradient>Resolution</Span></P>
                <Textarea
                    bind:value={$review_resolution}
                    rows={10}
                    placeholder=""
                ></Textarea>
                <TopToolbar
                    component_address={[...component_address, "toolbar"]}
                    {accordion_items_refs}
                    files_table_ref={null}
                    use_files={false}
                    override_submit_loading={submit_loading}
                    has_delete_narratives={true}
                ></TopToolbar>

                {#each Object.entries(review_items) as [parent_item_key, parent_item_val]}
                    <P size="lg" class="my-5"
                        ><Span gradient>{parent_item_val.title}</Span></P
                    >
                    <Accordion
                        multiple
                        activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                        inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
                    >
                        {#each Object.entries(parent_item_val.items) as [item_key, item_val]}
                            <GenericAccordionItem
                                suppress_notification={true}
                                module_name="review"
                                hide_model_selector={true}
                                include_fact_check={false}
                                include_source_attributions={false}
                                disable_paragraph_regenerate={true}
                                override_selected_model={item_val.selected_model}
                                bind:this={accordion_items_refs[
                                    `${parent_item_key}-${item_key}`
                                ]}
                                title={item_val.title}
                                component_address={[
                                    ...component_address,
                                    parent_item_key,
                                    item_key,
                                ]}
                                must_use_files={false}
                                extra_context={item_val.extra_context}
                                dependent_components={item_val.dependencies?.map(
                                    (dep) => {
                                        return {
                                            content_address: [
                                                ...component_address,
                                                parent_item_key,
                                                dep,
                                                "data",
                                                "content",
                                            ],
                                            completed_address: [
                                                ...component_address,
                                                parent_item_key,
                                                dep,
                                                "data",
                                                "completed",
                                            ],
                                        };
                                    },
                                )}
                                custom_icon={get_custom_icon(
                                    $component_state?.[parent_item_key]?.[
                                        item_key
                                    ]?.data?.content,
                                )}
                            ></GenericAccordionItem>
                        {/each}
                    </Accordion>
                {/each}
            </div>

            <BottomToolbar
                download_file_name="Review"
                template_type="review"
                accordion_items_refs={accordion_items_refs}
                files_table_ref={null}
                use_files={false}
                component_address={[...component_address, "bottom_toolbar"]}
                has_delete_narratives={true}
            ></BottomToolbar>
            <div class="flex justify-center my-10">
                <P size="xs" class="text-gray-800"
                    >AI may make mistakes. Please review the output carefully.</P
                >
            </div>
        </Card>
    </div>
</div>
