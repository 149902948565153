<script>
    import { Button, P } from "flowbite-svelte";
    import { Spinner } from "flowbite-svelte";
    import { Dropdown, DropdownItem } from "flowbite-svelte";
    import { ChevronDownOutline, TrashBinOutline } from "flowbite-svelte-icons";
    import { writable } from "svelte/store";
    import { global_state } from "../../service/store";
    import { getNestedDict, updateNestedDict } from "../../service/sse";
    import GenericConfirmModal from "./GenericConfirmModal.svelte";
    import { make_request } from "../../service/http_calls";
    import { v4 as uuidv4, v4 } from "uuid";
    import { toast } from "@zerodevx/svelte-toast";
    import {
        review_advance,
        review_baseline,
        review_resolution,
        review_uncertainty,
    } from "../Review/store";

    let confirm_modal_open = writable(false);

    export let component_address;

    export let has_extra_button = false;
    export let extra_button_text = "";
    export let extra_button_on_click = () => {};
    export let use_files = true;
    export let files_table_ref;
    export let accordion_items_refs;
    export let download_file_name;
    export let template_type;
    export let has_delete_narratives = false;
    const component_state = writable({});

    global_state.subscribe((s) => {
        component_state.set(getNestedDict(s, component_address));
        const data = getNestedDict(s, [
            ...component_address,
            "download",
            "data",
        ]);
        if (data?.base64_file && data?.file_type) {
            toast.push("Downloading...");
            const byteArray = Uint8Array.from(atob(data.base64_file), (c) =>
                c.charCodeAt(0),
            );
            const blob = new Blob([byteArray], {
                type:
                    data.file_type === "docx"
                        ? "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        : data.file_type,
            });
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            const today = new Date();
            const formattedDate = today
                .toISOString()
                .slice(0, 10)
                .replace(/-/g, "");
            a.download = `${formattedDate}_${download_file_name}.${data.file_type}`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
            updateNestedDict(s, [...component_address, "download", "data"], {
                completed: "true",
                base64_file: null,
                file_name: null,
                file_type: null,
            });
        }
    });

    async function handleDownload(doc_type) {
        console.log("handleDownload", doc_type);

        global_state.update((state) => {
            updateNestedDict(
                state,
                [...component_address, "download", "data"],
                {
                    completed: "false",
                },
            );
            return state;
        });

        const request_id = uuidv4();
        const data = Object.values(accordion_items_refs).reduce((acc, item) => {
            acc[item.getComponentKey()] = item
                .getContent()
                .replace(/<think>[\s\S]*?<\/think>/g, "")
                .replace(/\*\*\[\[\d+\]\(\d+\)\]\*\*/g, "")
                .trim();
            return acc;
        }, {});
        console.log("%cdata to be passed", "color: orange", Object.keys(data));
        await make_request(
            {
                type: "templating",
                sub_type: "download",
                request_id: request_id,
                payload: {
                    template_type: template_type,
                    file_type: doc_type,
                    data: data,
                },
            },
            component_address,
            {
                completed: [
                    ...component_address,
                    "download",
                    "data",
                    "completed",
                ],
                base64_file: [
                    ...component_address,
                    "download",
                    "data",
                    "base64_file",
                ],
                file_name: [
                    ...component_address,
                    "download",
                    "data",
                    "file_name",
                ],
                file_type: [
                    ...component_address,
                    "download",
                    "data",
                    "file_type",
                ],
            },
        );
    }

    function handleClearAll(
        delete_instructions,
        delete_files,
        delete_narratives,
    ) {
        console.log("clear all");

        if (use_files && delete_files) {
            // TODO: Delete files
            const files = getNestedDict($global_state, [
                ...component_address.slice(0, -1),
                "files",
            ]);
            console.log("to delete", files);
            for (let [key, file] of Object.entries(files ?? {})) {
                if (key === "data") continue;
                files_table_ref.handleDeleteFile(file.data);
            }
        }
        if (has_delete_narratives && delete_narratives) {
            // TODO: Delete narratives
            review_advance.set("");
            review_baseline.set("");
            review_resolution.set("");
            review_uncertainty.set("");
        }

        for (let [key, accordion_item_ref] of Object.entries(
            accordion_items_refs,
        )) {
            accordion_item_ref.handleClear(delete_instructions);
        }
        confirm_modal_open.set(false);
    }
</script>

<div class="flex justify-end">
    {#if has_extra_button}
        <div class="relative inline-block mr-2">
            <Button
                size="sm"
                color="alternative"
                class="mr-3"
                on:click={() => {
                    extra_button_on_click();
                }}
            >
                {extra_button_text}
            </Button>
        </div>
    {/if}

    <Button
        size="sm"
        color="alternative"
        class="hover:bg-purple-200 mr-2"
        title="Clear all instructions and generated content"
        on:click={() => {
            confirm_modal_open.set(true);
        }}
    >
        <TrashBinOutline></TrashBinOutline>
    </Button>

    {#if false}
        <!--  $component_state?.download?.data?.completed === "false"} -->
        <div class="flex justify-center mt-2">
            <P size="sm" class="mr-2 mt-1">Downloading...</P>
            <Spinner size={6}></Spinner>
        </div>
    {:else}
        <div class="relative inline-block mr-2">
            <Button size="sm" color="purple"
                >Download<ChevronDownOutline
                    class="w-6 h-6 ms-2 text-white dark:text-white"
                /></Button
            >
            <Dropdown>
                <DropdownItem
                    on:click={async () => {
                        await handleDownload("docx");
                    }}>Docx</DropdownItem
                >
                <DropdownItem
                    on:click={async () => {
                        await handleDownload("pdf");
                    }}>PDF</DropdownItem
                >
                <DropdownItem
                    on:click={() => {
                        // navigator.clipboard.writeText(text);
                    }}>Clipboard</DropdownItem
                >
            </Dropdown>
        </div>
    {/if}
</div>

<GenericConfirmModal
    {use_files}
    {has_delete_narratives}
    bind:modal_open={$confirm_modal_open}
    title="Clear All"
    message="Are you sure you want to clear all outputs? This action cannot be undone."
    confirm_text="Clear"
    cancel_text="Cancel"
    on_confirm={handleClearAll}
></GenericConfirmModal>
