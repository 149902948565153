<script>
  import { Tabs, TabItem } from "flowbite-svelte";
  import DraftIcon from "./assets/draft.svg";
  import QualifyIcon from "./assets/qualify.svg";
  import ReviewIcon from "./assets/review.svg";
  import NotifyIcon from "./assets/notify.svg";
  import ResearchIcon from "./assets/research.svg";
  import DefendIcon from "./assets/defend.svg";

  import DraftPicture1 from "./assets/features/Draft Picture 1.png";
  import DraftPicture2 from "./assets/features/Draft Picture 2.png";
  import QualifyPicture1 from "./assets/features/Qualify Picture 1.png";
  import QualifyPicture2 from "./assets/features/Qualify Picture 2.png";
  import ReviewPicture1 from "./assets/features/Review Picture 1.png";
  import ReviewPicture2 from "./assets/features/Review Picture 2.png";
  import NotifyPicture1 from "./assets/features/Notify Picture 1.png";
  import NotifyPicture2 from "./assets/features/Notify Picture 2.png";
  import ResearchPicture1 from "./assets/features/Research Picture 1.png";
  import ResearchPicture2 from "./assets/features/Research Picture 2.png";
  import DefendPicture1 from "./assets/features/Defend Picture 1.png";
  import DefendPicture2 from "./assets/features/Defend Picture 2.png";
</script>

<div class="mx-10 md:mx-80">
  <Tabs
    defaultClass="grid grid-cols-3 md:grid-cols-6 h-full"
    contentClass="mt-2 border rounded-lg p-5 pt-6 bg-white"
    activeClasses="border rounded-lg p-5 bg-white px-14 py-2"
    inactiveClasses="p-5 px-14 py-2 opacity-70"
    divider={false}
  >
    <TabItem open>
      <div slot="title">
        <div class="flex justify-center">
          <img src={DraftIcon} alt="" width="20px" />
        </div>
        <span class="text-gray-600">Draft</span>
      </div>
      <div class="md:grid md:grid-cols-3 h-96">
        <div class="col">
          <div class="flex mb-5">
            <img src={DraftIcon} alt="" width="24px" />
            <span class="font-bold ml-1">Draft</span>
          </div>
          <p class="text-sm text-gray-500 mr-5">
            AI drafts technical narratives for AIF within seconds, based on
            notes from technical interviews
          </p>
        </div>
        <div class="col-span-2 relative mt-4">
          <div
            class="p-2 absolute w-11/12 top-14 left-14 bg-white shadow-lg rounded-xl z-0 items-end justify-center"
          >
            <img src={DraftPicture2} alt="" />
          </div>
          <div
            class="p-2 absolute w-11/12 top-15 left-15 bg-white shadow-xl rounded-xl z-1 flex items-center justify-center"
          >
            <img src={DraftPicture1} alt="" />
          </div>
        </div>
      </div>
    </TabItem>
    <TabItem>
      <div slot="title">
        <div class="flex justify-center">
          <img src={QualifyIcon} alt="" width="20px" />
        </div>
        <span class="text-gray-600">Qualify</span>
      </div>
      <div class="md:grid md:grid-cols-3 h-96">
        <div class="col">
          <div class="flex mb-5">
            <img src={QualifyIcon} alt="" width="24px" />
            <span class="font-bold ml-1">Qualify</span>
          </div>
          <p class="text-sm text-gray-500 mr-5">
            AI identifies risks & gaps; suggest questions in customer-friendly
            language
          </p>
        </div>
        <div class="col-span-2 relative mt-4">
          <div
            class="p-2 absolute w-11/12 top-14 left-14 bg-white shadow-lg rounded-xl z-0 items-end justify-center"
          >
            <img src={QualifyPicture2} alt="" />
          </div>
          <div
            class="p-2 absolute w-11/12 top-15 left-15 bg-white shadow-xl rounded-xl z-1 flex items-center justify-center"
          >
            <img src={QualifyPicture1} alt="" />
          </div>
        </div>
      </div>
    </TabItem>
    <TabItem>
      <div slot="title">
        <div class="flex justify-center">
          <img src={ReviewIcon} alt="" width="20px" />
        </div>
        <span class="text-gray-600">Review</span>
      </div>
      <div class="md:grid md:grid-cols-3 h-96">
        <div class="col">
          <div class="flex mb-5">
            <img src={ReviewIcon} alt="" width="24px" />
            <span class="font-bold ml-1">Review</span>
          </div>
          <p class="text-sm text-gray-500 mr-5">
            AI provides comprehensive, tailored feedback on narratives, helping
            to make it even stronger
          </p>
        </div>
        <div class="col-span-2 relative mt-4">
          <div
            class="p-2 absolute w-11/12 top-14 left-14 bg-white shadow-lg rounded-xl z-0 items-end justify-center"
          >
            <img src={ReviewPicture2} alt="" />
          </div>
          <div
            class="p-2 absolute w-11/12 top-15 left-15 bg-white shadow-xl rounded-xl z-1 flex items-center justify-center"
          >
            <img src={ReviewPicture1} alt="" />
          </div>
        </div>
      </div>
    </TabItem>
    <TabItem>
      <div slot="title">
        <div class="flex justify-center">
          <img src={NotifyIcon} alt="" width="20px" />
        </div>
        <span class="text-gray-600">Notify</span>
      </div>
      <div class="md:grid md:grid-cols-3 h-96">
        <div class="col">
          <div class="flex mb-5">
            <img src={NotifyIcon} alt="" width="24px" />
            <span class="font-bold ml-1">Notify</span>
          </div>
          <p class="text-sm text-gray-500 mr-5">
            Calculate pre-notification deadline and write project descriptions with AI
          </p>
        </div>
        <div class="col-span-2 relative mt-4">
          <div
            class="p-2 absolute w-11/12 top-14 left-14 bg-white shadow-lg rounded-xl z-0 items-end justify-center"
          >
            <img src={NotifyPicture2} alt="" />
          </div>
          <div
            class="p-2 absolute w-11/12 top-15 left-15 bg-white shadow-xl rounded-xl z-1 flex items-center justify-center"
          >
            <img src={NotifyPicture1} alt="" />
          </div>
        </div>
      </div>
    </TabItem>
    <TabItem>
      <div slot="title">
        <div class="flex justify-center">
          <img src={ResearchIcon} alt="" width="20px" />
        </div>
        <span class="text-gray-600">Research</span>
      </div>
      <div class="md:grid md:grid-cols-3 h-96">
        <div class="col">
          <div class="flex mb-5">
            <img src={ResearchIcon} alt="" width="24px" />
            <span class="font-bold ml-1">Research</span>
          </div>
          <p class="text-sm text-gray-500 mr-5">
            AI assesses technical baseline by automatically searching on the
            Internet
          </p>
        </div>
        <div class="col-span-2 relative mt-4">
          <div
            class="p-2 absolute w-11/12 top-14 left-14 bg-white shadow-lg rounded-xl z-0 items-end justify-center"
          >
            <img src={ResearchPicture2} alt="" />
          </div>
          <div
            class="p-2 absolute w-11/12 top-15 left-15 bg-white shadow-xl rounded-xl z-1 flex items-center justify-center"
          >
            <img src={ResearchPicture1} alt="" />
          </div>
        </div>
      </div>
    </TabItem>
    <TabItem>
      <div slot="title">
        <div class="flex justify-center">
          <img src={DefendIcon} alt="" width="20px" />
        </div>
        <span class="text-gray-600">Defend</span>
      </div>
      <div class="md:grid md:grid-cols-3 h-96">
        <div class="col">
          <div class="flex mb-5">
            <img src={DefendIcon} alt="" width="24px" />
            <span class="font-bold ml-1">Defend</span>
          </div>
          <p class="text-sm text-gray-500 mr-5">
            AI drafts responses to HMRC enquiries
          </p>
        </div>
        <div class="col-span-2 relative mt-4">
          <div
            class="p-2 absolute w-11/12 top-14 left-14 bg-white shadow-lg rounded-xl z-0 items-end justify-center"
          >
            <img src={DefendPicture2} alt="" />
          </div>
          <div
            class="p-2 absolute w-11/12 top-15 left-15 bg-white shadow-xl rounded-xl z-1 flex items-center justify-center"
          >
            <img src={DefendPicture1} alt="" />
          </div>
        </div>
      </div>
    </TabItem>
  </Tabs>
</div>

<style>
</style>
