<script>
  import { Button, Span } from "flowbite-svelte";
  import {
    ArrowRightOutline,
    ArrowLeftToBracketOutline,
  } from "flowbite-svelte-icons";
</script>

<div class="grid text-center gap-y-7 mx-10 md:mx-0">
  <h1 class="text-5xl md:text-6xl font-bold">
    Your <Span gradient>AI Assistant</Span> for
    <br />
    UK R&D Tax Narratives
  </h1>

  <p class="text-xl text-stone-600">
    Save time & supercharge robustness via responsible use of AI
  </p>

  <div class="flex gap-x-2 justify-center gap-y-2">
    <Button
      color="light"
      on:click={(e) => {
        e.preventDefault();
        window.location.href = "/login";
      }}
    >
      <ArrowLeftToBracketOutline class="opacity-70 mr-2" /> Log in
    </Button>

    <Button
      color="blue"
      on:click={(e) => {
        e.preventDefault();
        window.location.href = "#contact";
      }}
    >
      Request Demo<ArrowRightOutline class="opacity-70 ml-2" />
    </Button>
  </div>
</div>
