import { toast } from "@zerodevx/svelte-toast";
import { get } from "svelte/store";
import { connected } from "./sse";
import { requests_component_lookup } from "./store";

const BASE_URL = import.meta.env.VITE_BASE_URL;

export async function fetchFromApi(endpoint, options = {}) {
    const response = await fetch(`${BASE_URL}${endpoint}`, options);
    if (!response.ok) {
        const result = await response.text();
        throw result;
    }
    return await response.json();
}

// This is a new endpoint to reduce the complexity of API calls
export async function fetch_api(endpoint, method, query, body) {
    const query_string = new URLSearchParams(query).toString();
    const endpoint_with_params = query_string ? `${endpoint}?${query_string}` : endpoint;

    let options = {
        method,
        headers: {
            'Content-Type': 'application/json',
        }
    }
    if (body) {
        options = { ...options, body: JSON.stringify(body) }
    }

    const response = await fetch(`${BASE_URL}${endpoint_with_params}`, options);
    if (!response.ok) {
        const result = await response.text();
        toast.push(JSON.parse(result).detail.message);
        throw result;
    }
    return await response.json();
}



export async function earlyAccess(entry) {
    return await fetchFromApi(`/early_access`, {

        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'access-control-allow-credentials': 'true',
            'vary': 'Origin'
        },
        body: JSON.stringify({
            name: entry.name,
            email: entry.email,
            company_name: entry.company_name
        })
    });
}


export async function getUser() {
    return await fetchFromApi(`/me`, {
        method: 'GET',
        credentials: 'include',
    });
}

export async function logout() {
    const resp = await fetchFromApi(`/logout`, {
        method: 'POST',
        credentials: 'include',
    });
    return resp;
}

export async function login(formData) {
    const resp = await fetchFromApi(`/login`, {
        method: 'POST',
        credentials: 'include',
        body: formData
    });
    return resp;
}

export async function register(email, password) {
    const resp = await fetchFromApi(`/register`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            email,
            password
        })
    });
    return resp;
}

export async function customerSupport(inquiry) {
    return await fetchFromApi(`/customer_support`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            ...inquiry
        })
    });
}




export async function change_password_request(email) {

    return await fetchFromApi(`/change-password-request?email=${email}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
    });
}


export async function verify(email, code) {

    return await fetchFromApi(`/verify?email=${email}&code=${code}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
    });
}


export async function change_password(email, new_password, temporary_token) {

    return await fetchFromApi(`/change-password?email=${email}&new_password=${new_password}&temporary_token=${temporary_token}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
    });
}


export async function send_email(email, subject, message) {

    return await fetchFromApi(`/contact_form`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            email,
            subject,
            message
        })
    });
}



export async function get_organization_admin() {
    return await fetchFromApi(`/organization_admin`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    });
}



export async function create_client_credentials() {
    return await fetchFromApi(`/create_client_credentials`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        }
    });
}






export async function make_request(payload, component_address, target_component_address) {
    if (get(connected) == "false") {
        console.log("Waiting for SSE connection...");
        setTimeout(() => {
            make_request(payload, component_address, target_component_address);
        }, 100);
        return;
    } else {
        console.log("Already connected");
    }
    console.log("%cMaking request %c" + JSON.stringify(payload) + "%c request_id: %c" + payload.request_id, "color: #CF0000; font-weight: bold", "color: #000000", "color: #CF0000; font-weight: bold", "color: #000000");

    requests_component_lookup.update((state) => {
        state[payload.request_id] = {
            "request_origin_component_address": component_address,
            "target_component_address": target_component_address,
            "status": "pending"
        };
        return state;
    });
    return await fetchFromApi(`/request`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'access-control-allow-credentials': 'true',
            'vary': 'Origin'
        },
        body: JSON.stringify(
            payload
        )
    });

}
