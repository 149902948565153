<script>
    import { Card, Hr, P, Span } from "flowbite-svelte";
    import { onMount } from "svelte";
    import { getNestedDict } from "../../service/sse";
    import { global_state } from "../../service/store";
    import {
        review_advance,
        review_baseline,
        review_resolution,
        review_uncertainty,
    } from "../Review/store";
    import BottomToolbar from "../components/BottomToolbar.svelte";
    import CustomAccordion from "../components/CustomAccordion.svelte";
    import NewFilesTable from "../components/Files/NewFilesTable.svelte";
    import GenericAccordionItem from "../components/GenericAccordionItem.svelte";
    import TopToolbar from "../components/TopToolbar.svelte";
    import { questions } from "./store";

    let component_address = ["draft_sa"];
    let accordion_items_refs = {};
    let files_table_ref = null;

    onMount(async () => {});
</script>

<div class="w-full flex flex-grow flex-col justify-center mb-5">
    <div class="justify-center flex mt-5">
        <Card size="xl">
            <NewFilesTable
                bind:this={files_table_ref}
                component_address={[...component_address, "files"]}
                module_name="draft_sa"
            ></NewFilesTable>

            <Hr classHr="my-8" />

            <div class="mb-10">
                <TopToolbar
                    component_address={[...component_address, "toolbar"]}
                    {accordion_items_refs}
                    {files_table_ref}
                    hide_model_selector={false}
                ></TopToolbar>

                <P size="lg" class="my-5"><Span gradient>Draft</Span></P>
                <CustomAccordion>
                    {#each Object.entries(questions) as [question_key, question_val]}
                        <GenericAccordionItem
                            module_name="draft_sa"
                            suppress_notification={true}
                            hide_model_selector={true}
                            override_selected_model={question_val.selected_model}
                            bind:this={accordion_items_refs[question_key]}
                            title={question_val.title}
                            component_address={[
                                ...component_address,
                                "questions",
                                question_key,
                            ]}
                            dependent_components={question_val.dependencies?.map(
                                (dep) => {
                                    return {
                                        content_address: [
                                            ...component_address,
                                            "questions",
                                            dep,
                                            "data",
                                            "content",
                                        ],
                                        completed_address: [
                                            ...component_address,
                                            "questions",
                                            dep,
                                            "data",
                                            "completed",
                                        ],
                                    };
                                },
                            )}
                        ></GenericAccordionItem>
                    {/each}
                </CustomAccordion>
            </div>

            <BottomToolbar
                download_file_name="Technical Narrative"
                template_type="draft"
                {accordion_items_refs}
                {files_table_ref}
                use_files={true}
                component_address={[...component_address, "bottom_toolbar"]}
            ></BottomToolbar>
            <div class="flex justify-center my-10">
                <P size="xs" class="text-gray-800"
                    >AI may make mistakes. Please review the output carefully.</P
                >
            </div>
        </Card>
    </div>
</div>
