<script>
    import { Alert, Hr, P, Spinner } from "flowbite-svelte";
    import { earlyAccess } from "../../service/http_calls";

    let name = "";
    let email = "";
    let company_name = "";
    let loading = false;
    let submitted = false;

    async function handleSubmit() {
        loading = true;
        event.preventDefault();
        console.log({ name, email });
        await earlyAccess({
            name,
            email,
            company_name,
        });
        submitted = true;
        loading = false;
    }
</script>

<form
    on:submit={handleSubmit}
    class="max-w-md mx-auto bg-white p-8 rounded-md shadow-md"
>
    <P weight="bold" class="mb-3">Interested?</P>
    <P
        >We'll reach out to you to schedule a quick intro call. Looking forward to it!</P
    >
    <Hr />
    <div class="mb-4">
        <label for="name" class="block text-sm font-medium text-gray-700"
            >Name</label
        >
        <input
            type="text"
            id="name"
            bind:value={name}
            required
            class="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        />
    </div>
    <div class="mb-4">
        <label for="email" class="block text-sm font-medium text-gray-700"
            >Email</label
        >
        <input
            type="email"
            id="email"
            bind:value={email}
            required
            class="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        />
    </div>

    <div class="mb-4">
        <label
            for="company-name"
            class="block text-sm font-medium text-gray-700">Company Name</label
        >
        <input
            id="company-name"
            bind:value={company_name}
            required
            class="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        />
    </div>
    {#if submitted == false}
        {#if loading}
            <div class="flex flex-col items-center">
                <Spinner></Spinner>
            </div>
        {:else}
            <button
                type="submit"
                class="w-full bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600"
                >Submit</button
            >
        {/if}
    {:else}
        <Alert>Info submitted, thank you!</Alert>
    {/if}
</form>
