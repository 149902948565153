<script>
    import { Hr, P, Span, Textarea } from "flowbite-svelte";
    import { defend_freeform_question, defend_wmbc_items } from "./store";
    import CustomAccordion from "../components/CustomAccordion.svelte";
    import GenericAccordionItem from "../components/GenericAccordionItem.svelte";
    import TopToolbar from "../components/TopToolbar.svelte";
    import NewFilesTable from "../components/Files/NewFilesTable.svelte";
    import { writable } from "svelte/store";
    import BottomToolbar from "../components/BottomToolbar.svelte";

    let component_address = ["defend", "freeform"];
    let accordion_items_refs = {};
    let files_table_ref = null;
    let submit_disabled = writable(false);

  

    $:{
        if (
            files_table_ref &&
            files_table_ref.allFilesReady() &&
            $defend_freeform_question.trim().length > 0
        ) {
            submit_disabled.set(false);
            console.log("submit disabled", false);
        } else {
            submit_disabled.set(true);
            console.log("submit disabled", true);
        }
    }

</script>

<div class="mb-10">
    <NewFilesTable
        bind:this={files_table_ref}
        component_address={[...component_address, "files"]}
        module_name="defend_freeform"
    ></NewFilesTable>

    <Hr classHr="my-8" />
    <P class="my-5 text-gray-700 dark:text-gray-300 font-semibold"
        >Please add enquiry question here</P
    >
    <Textarea rows="5" class="mb-3" bind:value={$defend_freeform_question}
    ></Textarea>
    <TopToolbar
        component_address={[...component_address, "toolbar"]}
        {accordion_items_refs}
        {files_table_ref}
        override_submit_disabled={$submit_disabled}
    ></TopToolbar>
    <Hr classHr="my-8" />
    <CustomAccordion>
        <GenericAccordionItem
            bind:this={accordion_items_refs["outline"]}
            hide_model_selector={true}
            disable_paragraph_regenerate={true}
            title="Outline"
            component_address={[...component_address, "outline"]}
            module_name="defend_freeform"
            extra_context={defend_freeform_question}
            override_selected_model={"gpt-4o"}
        ></GenericAccordionItem>
        <GenericAccordionItem
            bind:this={accordion_items_refs["draft"]}
            hide_model_selector={true}
            disable_paragraph_regenerate={true}
            title="Draft"
            component_address={[...component_address, "draft"]}
            module_name="defend_freeform"
            override_selected_model={"gpt-4o"}
            dependent_components={[
                {
                    content_address: [
                        ...component_address,
                        "outline",
                        "data",
                        "content",
                    ],
                    completed_address: [
                        ...component_address,
                        "outline",
                        "data",
                        "completed",
                    ],
                },
            ]}
        ></GenericAccordionItem>
    </CustomAccordion>
    <div class="mt-10">
        <BottomToolbar
            template_type="defend_freeform"
            download_file_name="Defend Freeform"
            {accordion_items_refs}
            {files_table_ref}
            use_files={true}
            component_address={[...component_address, "bottom_toolbar"]}
        ></BottomToolbar>
    </div>
</div>
